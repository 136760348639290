/* Dashboard */

/* Core */
// import { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Components */
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

/* Media */
import styles from './SubNav.module.css'

const _ = require('lodash')

function SubNav(props) {
    const rootStore = useStores()
    let history = useNavigate()

    let links = [
        {
            'index': 0,
            'text': 'Dashboard',
            'path': '/dashboard'
        },
        {
            'index': 1,
            'text': 'Settings',
            'path': '/settings'
        },
        {
            'index': 2,
            'text': 'NFTs',
            'path': '/nfts'
        }
    ]

    const appDeveloperLinks = [
        {
            'index': 3,
            'text': 'Admin',
            'path': '/admin'
        }
    ]

    if(rootStore.AuthStore.user.hasRole('appDeveloper')){
        links = [...links, ...appDeveloperLinks]
    }

    // Switch pages on tab click
    const handleChange = (e, value) => {
        const link = _.find(links, (e) => { return e.index === value })
        history({ pathname: link.path }) 
    }

    const getValue = () => {
        // Get link from props.parent
        const link = _.find(links, (e) => { return e.text === props.parent })
        return link?.index
    }
    
    return (
        <Paper className={styles.TabContainer} style={{...props.style}}>
            <Tabs
                value={getValue()}
                onChange={handleChange}
                TabIndicatorProps={{ style: {background: "rgba(255,255,255,0.6)"} }}
                indicatorColor="primary"
                textColor="primary"
                centered>
                {links.map((link) => {
                    return(
                        <Tab key={link.index} label={link.text} value={link.index} />
                    )
                })}
            </Tabs>
        </Paper>
    )

    // return (
    //     <SubNavTabs 
    //         amount={5}
    //         link={true}

    //         tab1text="Overview"

    //         tab2text="Settings"

    //         tab3text={(rootStore.AuthStore.user.hasRole('appDeveloper')) ? "NFT" : false}

    //         // Change affiliate role back to enable
    //         tab4text={(rootStore.AuthStore.user.hasRole('appDeveloper')) ? "Affiliate" : false}

    //         tab5text={(rootStore.AuthStore.user.hasRole('appDeveloper')) ? "Admin" : false}
    //     />
    // )
}

export default observer(SubNav)