/* <OverviewDash /> */

import ChromaList from "CHROMA-React/components/chroma-list"
import SubNav from "components/nav/SubNav"
import { useEffect, useState } from "react"

import { useStores } from "../../context/RootStoreContext"



/* Components */
import ProductsList from "../data/ProductsList"
import SeedcodesList from "../data/SeedcodesList"
import TransactionList from "../data/TransactionList"

/* Media */
import styles from './OverviewDash.module.css'

const _ = require('lodash')

function OverviewDash(props) {
    const rootStore = useStores()

    const chromaQLProducts = {
        service: "products",
        fields: "slug copy meta{buildType}"
    }

    return(
        <div className={styles.OverviewDashContainer}>
            <SubNav parent="Dashboard" />
            {/* Products */}
            <div className={styles.Section}>
                <h2>Products</h2>
                <div className="card" style={{borderTopLeftRadius: "0", borderTopRightRadius: "0"}}>
                    <div className="card-body" style={{padding: "0"}}>
                        <ProductsList />
                        {/*<ChromaList query={chromaQLProducts} />*/}
                    </div>
                </div>
            </div>
            
            {/* Transactions */}
            <div className={styles.Section}>
                <h2>Recent Transactions</h2>
                <div className="card" style={{borderTopLeftRadius: "0", borderTopRightRadius: "0"}}>
                    <div className="card-body" style={{padding: "0"}}>
                        <TransactionList />
                    </div>
                </div>
            </div>

            {/* Seedcodes */}
            <div className={styles.Section}>
                <h2>Seedcodes</h2>
                <div className="card" style={{borderTopLeftRadius: "0", borderTopRightRadius: "0"}}>
                    <div className="card-body" style={{padding: "0"}}>
                        <SeedcodesList />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewDash