import { chromaEndpoint, chromaHeaders } from "./_headers"

const get = async (query) => {
    const endpoint = `${chromaEndpoint}/query/get`
    const getQuery = {
        method: 'POST', headers: chromaHeaders,
        body: JSON.stringify(query)
    }

    
    const getRequest = await fetch(endpoint, getQuery)
    const response = getRequest.json()

    // console.log('> chroma.get:', getQuery, response)

    return response
}

export default get