import _ from 'lodash'

/* Media */
import styles from './SingleDefault.module.css'

const SingleDefaultView = (props) => {
    const id = _.get(props, "id")
    const service = _.get(props, "service")

    const { item } = props
    let data = []

    const imageUrl = _.get(item, "meta.files[0].path")

    for (const [key, value] of Object.entries(item)) {
        if(typeof value !== 'object') {
            // Key Value
            data.push({key: key, value: value})
        } else {
            // Object
            data.push({key: key, value: JSON.stringify(value, undefined, 2)})
        }
    }

    return (
        <div className={styles.SingleDefaultViewContainer}>
            {/* Title */}
            <h2 className={styles.service}>
                {service} / <span className={styles.id}>{id}</span>
            </h2>

            {/* Image (optional) */}
            {imageUrl && <img src={imageUrl} className={styles.image} />}
            
            {/* Content */}
            <div className={styles.content}>
                {data.length > 0 && 
                    data.map((item, index) => {
                        return(
                            <p key={index} className={styles.entry}>
                                <span>{item.key}:</span> <pre>{item.value}</pre>
                            </p>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SingleDefaultView