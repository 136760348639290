/* MetaTags */


import { Helmet } from 'react-helmet-async'

const defaultSocialImgUrl = "https://chroma-media-platform.s3-us-west-1.amazonaws.com/images/social/og-default-share-image.png"

const siteName = "CHROMA Platform"
const facebookAppId = "1042673696113819"
const mdvrDescription = "Interactive art creation platform that combines artist, musician and dancer."
const mdvrTitle = "CHROMA Platform | Microdose VR"

const mdvrTwitterHandle = "@microdosevr"

function MetaTags(props) {
    return(
        <Helmet> 
            <title>{props.title || mdvrTitle}</title>

            {/* SEO */}
            <meta name="description" content={props.desc || mdvrDescription} />
            <meta name="image" content={defaultSocialImgUrl} />

            {/* Schema.org for Google */}
            <meta itemprop="name" content={props.title || mdvrTitle} />
            <meta itemprop="description" content={props.desc || mdvrDescription} />
            <meta itemprop="image" content={defaultSocialImgUrl} />


            {/* OpenGraph - Facebook */}
            <meta property="fb:app_id" content={facebookAppId} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:type" content={props.type || "website"} />
            <meta property="og:title" content={props.title || mdvrTitle} />
            <meta property="og:description" content={props.desc || mdvrDescription} />
            {props.imgUrl ? <meta property="og:image" content={props.imgUrl} /> : <meta property="og:image" content={defaultSocialImgUrl} />}

            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={props.title || mdvrTitle} />
            <meta name="twitter:description" content={props.desc || mdvrDescription} />
            <meta name="twitter:site" content={mdvrTwitterHandle} />
            <meta name="twitter:image:src" content={defaultSocialImgUrl} />

        </Helmet>
    )
}

export default MetaTags