import { useStores } from "context/RootStoreContext";
import _ from "lodash";
import { useEffect } from "react"
// import { useLocation } from "react-router-dom"

export default function URLParams() {
    // const { pathname } = useLocation()
    const rootStore = useStores()

    useEffect(() => {
        /* Store */

        /* Get Query Params */
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        
        /* Affiliate Id */
        const affiliateId = urlParams.get("aid")
        if(affiliateId){
            // console.log(affiliateId, 'found')

            // Store on rootStore
            rootStore.setAffiliateId(affiliateId)
        }

        // let urlToken = urlParams.get("token")
        // let urlId = urlParams.get("id")
    }, [])

    return null
}