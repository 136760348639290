/* <AnalyticsDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Media */
import styles from './AdminAffiliateDash.module.css'
import TransactionListItem from "CHROMA-React/components/list-items/transaction"

function AdminAffiliateDash(props) {
    const [affiliateData, setAffiliateData] = useState([])

    /* Get Affiliate data */
	useEffect(() => {
        const getAffiliates = async () => {
            const usersQuery = {
                service: "transactions",
                query: {
                    status: "complete",
                    affiliate: {
                        "$exists": true
                    }
                },
                fields: "_id createdAt amountUsd txId affiliate status outMetaIds inId"
            }
    
            const transactions = await chroma.find(usersQuery)
            
            setAffiliateData(transactions)
        }


        getAffiliates()
    }, [chroma, setAffiliateData])

    return(
        <div className={styles.Container}>
            <h2>Affiliate Sales</h2>
            <div className="">
                {affiliateData && affiliateData.map((data, i) => {
                    return <TransactionListItem key={i} item={data} />
                })}
            </div>
        </div>
    )
}

export default AdminAffiliateDash