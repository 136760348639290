import { useEffect, useState } from 'react'
import { props } from 'data/structure/BaseModel'
import _ from 'lodash'
import { LineChart, Line, ResponsiveContainer } from 'recharts'

/* Media */
import styles from './UserAmountChart.module.css'
import moment from 'moment'
import axios from 'axios'
import SimpleLineChart from './SimpleLineChart'

const data = [
    {name: 'Page A', amount: 400},
    {name: 'Page 2', amount: 600},
    {name: 'Page 2', amount: 300},
    {name: 'Page 2', amount: 700}
]
export default function TransactionsAmountChart(props) {
    const [chartData, setChartData] = useState([])

    // GET Users Chart Data
	useEffect(() => {
		const getChartData = async () => {
            // date1 is the more recent of the dates (date2 must be in the past)
            const userDateConfig = [
                {
                    title: 'this week',
                    date1: moment().unix(), // today
                    date2: moment().subtract(60 * 24 * 7, 'minutes').unix() // last week
                },
                {
                    title: '1 week ago',
                    date1: moment().subtract(60 * 24 * 7, 'minutes').unix(), // 1 week ago
                    date2: moment().subtract(60 * 24 * 7 * 2, 'minutes').unix() // 2 weeks ago
                },
                {
                    title: '2 weeks ago',
                    date1: moment().subtract(60 * 24 * 7 * 2, 'minutes').unix(), // 2 week ago
                    date2: moment().subtract(60 * 24 * 7 * 3, 'minutes').unix() // 3 weeks ago
                },
                {
                    title: '3 weeks ago',
                    date1: moment().subtract(60 * 24 * 7 * 3, 'minutes').unix(), // 3 week ago
                    date2: moment().subtract(60 * 24 * 7 * 4, 'minutes').unix() // 4 weeks ago
                }
            ]

            let data = []
            for (let i = 0; i < userDateConfig.length; i++) {
                const dateConfig = userDateConfig[i];
                const dateDataRequest = await getDataBetweenDates(dateConfig.date2, dateConfig.date1)
                const dateData = {
                    title: dateConfig.title,
                    amount: dateDataRequest
                }
                data.unshift(dateData)
            }

            setChartData(data)
		}

        const getDataBetweenDates = async (startDate, endDate) => {
			const query = {
                service: "transactions",
				query: {updatedAt: {$gt: startDate, $lt: endDate}, status: "complete"},
				fields: "createdAt"
			}	
            
            let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"


            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }

            const chroma_query_endpoint = chroma_endpoint + '/query/find'
            const request = await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
            const amount = _.get(request, 'data.length', 0)
            
            return amount

        }

		getChartData()

	}, [setChartData])

    return (
        <SimpleLineChart height={_.get(props, "height")} style={_.get(props, "style")} data={chartData} className={`${styles.LineChart} ${_.get(props, "className")}`} />
    )
}