/* Core */
import _ from 'lodash'
import { useEffect, useState } from 'react'

import chroma from "CHROMA-React/code/chroma"

/* Media */
import styles from './transaction.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFileInvoiceDollar, faIdBadge, faReceipt, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const TransactionListItem = (props) => {
    const { item } = props
    const [product, setProduct] = useState({})
    let productId = _.get(item, "outMetaIds[0]")

    // Get product
    useEffect(() => {
        const getProduct = async () => {
            const query = {
                service: "products",
                query: {_id: productId},
                fields: "_id slug copy"
            }
            const productQuery = await chroma.find(query)
            const product = productQuery[0]

            if(product){
                setProduct(product)
            }
        }
        
        if(productId){
            getProduct()
        }
    }, [chroma, productId, setProduct])


    return (
        <div key={_.get(props, "index")} {...props} className={styles.listItemContainer}>
            <h2 className={styles.amount}>
                <sup>$</sup>
                {_.get(item, "amountUsd", "N/A")}
                {_.get(item, "affiliate") &&
                    <p className={styles.affiliateId}>
                        Affiliate: <span>{_.get(item, "affiliate.affiliateId")}</span>
                    </p>
                }
                {_.get(item, "affiliate") &&
                    <p className={styles.affiliateId}>
                        Paid: <span>{_.get(item, "affiliate.affiliatePaid") ? "true" : "false"}</span>
                    </p>
                }
            </h2>

            <p className={styles.product}>{_.get(product, "copy.en.title", "‎")}</p>
            <p className={styles.id}>
                {_.get(item, "_id", "N/A")}
            </p>

            <p className={styles.time}>
                <FontAwesomeIcon icon={faCalendar} className={styles.icon} />
                {moment(_.get(item, "createdAt") * 1000).fromNow()}
            </p>

        </div>
    )
}

export default TransactionListItem