import { chromaEndpoint, chromaHeaders } from "./_headers"

const measurementId = 'G-7G4E0B91Y8'
const apiSecret = 'Vpm7Yqd2SwC79KOCQUvEnQ'

const ANALYTICS_ENDPOINT = `https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`

const axios = require('axios')

const analyticEvent = async (eventName, params = {}) => {
    const analyticsBody = JSON.stringify({
        "client_id": "client_id",
        "events": [
            {
                "name": eventName,
                "params": params
                // "params": {
                //     "currency": "USD",
                //     "value": 7.77,
                //     "coupon": "SUMMER_FUN",
                //     "payment_type": "Credit Card",
                // }
            }
        ]
    })
    const analyticsQuery = {
        // method: 'POST',
        body: analyticsBody
    }

    const analyticsRequest = await axios.post(ANALYTICS_ENDPOINT, analyticsBody, {})

    
    // const analyticsRequest = await fetch(ANALYTICS_ENDPOINT, analyticsQuery)
    // const response = analyticsRequest.json()

    console.log('> chroma.analytics:', analyticsQuery, analyticsRequest)

    return analyticsRequest
}

export default analyticEvent