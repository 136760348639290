/* Dashboard */

/* Core */
import { useEffect } from "react"
import { observer } from "mobx-react"
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"

/* Media */
import styles from './QueryBuilderView.module.css'

import chroma from '@chromaplatform/chromajs'
import WalletStatus from "components/dashboard-2/wallet-status"
import QueryBuilder from "components/query-builder/QueryBuilder"


const _ = require('lodash')

function QueryBuilderView(props) {
    const rootStore = useStores()

    /* Get chroma data */
    useEffect(() => {
        const query = {
            service: "users",
            id: rootStore.AuthStore.userId,
            fields: "username roles{roleAssignments}"
        }

        // chroma.get(query).then(data => {
        //     // console.log("> Roles", data)
        // })
    }, [chroma, rootStore])

    

    return (
        <div>
            <Layout noContainer={true}>
                <Helmet>
                    <title>CHROMA | Query Builder</title>
                </Helmet>

                {/* Header Image */}
                <Hero 
                    height = {111}
                    primaryImageSource = "https://chroma.vision/static/media/Screenshot_A19.e002f0c9.jpg"
                    color1 = "rgba(173, 221, 158, .8)"
                    color2 = "rgba(173, 221, 158, 1)"
                    /> 
                
                <div className="container">
                    <div className={styles.Container}>
                        <div className="row">
                            {/* Right Column */}
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                               <h2 className={styles.title}>Query Builder</h2>
                               <h3 className={styles.subtitle}>Explore the chroma platform</h3>
                               <QueryBuilder selectService={true} preview={true} queryable={true} />
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default observer(QueryBuilderView)