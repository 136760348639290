/* <ProfileDetails /> */
import { useEffect, useState } from "react"
import { observer } from "mobx-react"

import { useStores } from "../../context/RootStoreContext"
import { Link, useLocation } from "react-router-dom"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import { Avatar, Image } from 'antd'
import Badge from '@material-ui/core/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faUser, faFlask } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons'


/* Media */
import styles from './ProfileDetails.module.css'
import eyePurple from '../../media/images/eye-purple.png'

const _ = require('lodash')

function ProfileDetails(props) {
    const rootStore = useStores()

    /* Local State */
    const [geo, setGeo] = useState([])

    const facebookURL = _.get(rootStore, "AuthStore.user.meta.profile.facebookURL")
    const twitchURL = _.get(rootStore, "AuthStore.user.meta.profile.twitchURL")
    const youtubeURL = _.get(rootStore, "AuthStore.user.meta.profile.youtubeURL")
    const instagramURL = _.get(rootStore, "AuthStore.user.meta.profile.instagramURL")
    const avatarURL = _.get(rootStore, "AuthStore.user.meta.profile.avatar_url")

    /* Get Seedcode data */
    useEffect(() => {
        const getUserGeo = async () => {
            const geoQuery = {
                service: "users",
                id: rootStore.AuthStore.userId,
                fields: "meta{geolocation{current}}"
            }
    
            let query = await chroma.get(geoQuery)
            setGeo(_.get(query, "meta.geolocation.current"))
        }
        

        getUserGeo()
    }, [chroma, rootStore, setGeo])

    return(
        <div className={`${styles.ProfileContainer} profileContainer col-lg-3 col-md-12 col-sm-12`}>
            
            {/* Avatar */}
            <div className={styles.Avatar}>
                <Avatar size={128} icon={<FontAwesomeIcon icon={faUser} />} src={<Image src={avatarURL || eyePurple} preview={false} />} />
            </div>

            {/* Social Icons */}
            <div className={styles.Icons}>
                {facebookURL ? <a href={facebookURL}><FontAwesomeIcon icon={faFacebook} /></a> : ""}                                    

                {youtubeURL ? <a href={youtubeURL}><FontAwesomeIcon icon={faYoutube} /></a> : ""}

                {instagramURL ? <a href={instagramURL}><FontAwesomeIcon icon={faInstagram} /></a> : ""}

                {twitchURL ? <a href={twitchURL}><FontAwesomeIcon icon={faTwitch} /></a> : ""}
            </div>

            {/* Username */}
            <h3 className={styles.Username}>{rootStore.AuthStore.user.username || "N/A"}</h3>
            
            {/* Location */}
            {_.get(geo, "city") &&
                <div className={styles.Location}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span>{_.get(geo, "city")}, {_.get(geo, "region")}</span>
                </div>
            }

            {/* Admin Badge - TODO:: Move to <RoleComponent /> */}
            {(rootStore.AuthStore.user.hasRole('appDeveloper')) ? 
                <div style={{textAlign:"center", position: "relative", top:"-8px", marginBottom: "18px"}}><span style={{backgroundColor: "#6f0cd8", fontSize:"12px", color: "#fff", borderRadius:"4px",padding:"4px 8px"}}><FontAwesomeIcon icon={faFlask} style={{marginRight: "4px", position: "relative", top: "1px"}} />developer</span> </div>
                : null}

            {/* Bio */}
            <div className={styles.Bio}>
                {_.get(rootStore, "AuthStore.user.meta.profile.bio") ?
                <div className="card">
                    <div className={`${styles.CardBody} card-body`}>
                        <p>{_.get(rootStore, "AuthStore.user.meta.profile.bio") || ""}</p>
                    </div>
                </div>
                : ""}       
            </div>
            
            {/* Data */}
            <div className={styles.Data}>
                <p>
                    Member Since: <strong>{rootStore.AuthStore.user.created}</strong>
                </p>
                {_.get(rootStore, "AuthStore.user.meta.origin.seedcode") ? <p>Seedcode: <strong>{_.get(rootStore, "AuthStore.user.meta.origin.seedcode") || ""}</strong></p> : ""}
            </div>
        </div>
    )
}

export default observer(ProfileDetails)