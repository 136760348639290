/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */


/* Media */
import styles from './AdminTransactionDash.module.css'
import QueryBuilder from "components/query-builder/QueryBuilder"

const _ = require('lodash')
const moment = require('moment')

function AdminTransactionDash(props) {
    const [transactionsThisMonth, setTransactionsThisMonth] = useState([])
    const [transactionsOneMonthAgo, setTransactionsOneMonthAgo] = useState([])
    const [transactionsTwoMonthsAgo, setTransactionsTwoMonthsAgo] = useState([])
    const [transactionsThreeMonthsAgo, setTransactionsThreeMonthsAgo] = useState([])

    /* Get Transaction data */
	useEffect(() => {
		const getTransactions = async () => {
			// This month
			const thisMonth = moment().subtract(60 * 24 * 7 * 4, 'minutes').unix()
            const lastMonth = moment().subtract(60 * 24 * 7 * 4 * 2, 'minutes').unix()
            const twoMonthsAgo = moment().subtract(60 * 24 * 7 * 4 * 3, 'minutes').unix()
			const threeMonthsAgo = moment().subtract(60 * 24 * 7 * 4 * 4, 'minutes').unix()

			const monthQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: thisMonth}},
				fields: "_id inId email costUsd amountUsd updatedAt"
			}
			chroma.find(monthQuery, true).then(data => {
				setTransactionsThisMonth(data)
			})

            // Last month
			const lastMonthQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: lastMonth, $lt: thisMonth}},
				fields: "_id inId email costUsd amountUsd updatedAt"
			}
			chroma.find(lastMonthQuery, true).then(data => {
				setTransactionsOneMonthAgo(data)
			})

            // Two Months Ago
			const twoMonthQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: twoMonthsAgo, $lt: lastMonth}},
				fields: "_id inId email costUsd amountUsd updatedAt"
			}
			chroma.find(twoMonthQuery, true).then(data => {
				setTransactionsTwoMonthsAgo(data)
			})

            // Three Months Ago
			const threeMonthQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: threeMonthsAgo, $lt: twoMonthsAgo}},
				fields: "_id inId email costUsd amountUsd updatedAt"
			}
			chroma.find(threeMonthQuery, true).then(data => {
				setTransactionsThreeMonthsAgo(data)
			})


		}

		getTransactions()
	}, [chroma])

    return(
        <div className={styles.Container}>

          {/* Transactions */}
          <div className={styles.Section}>
            <h3 className={styles.header}>📦 This Month</h3>
            {/* Transactions */}
            <div className={styles.TransactionsMonth}>
                Amount: <strong>{transactionsThisMonth.length}</strong>
            </div>
            
            <h3 className={styles.header}>📦 One Month Ago</h3>
            {/* Transactions 1 month ago */}
            <div className={styles.TransactionsMonth}>
                Amount: <strong>{transactionsOneMonthAgo.length}</strong>
            </div>

            <h3 className={styles.header}>📦 Two Months Ago</h3>
            {/* Transactions 2 month ago */}
            <div className={styles.TransactionsMonth}>
                Amount: <strong>{transactionsTwoMonthsAgo.length}</strong>
            </div>

            <h3 className={styles.header}>📦 Three Months Ago</h3>
            {/* Transactions 3 month ago */}
            <div className={styles.TransactionsMonth}>
                Amount: <strong>{transactionsThreeMonthsAgo.length}</strong>
            </div>
          </div>
        </div>
    )
}

export default AdminTransactionDash