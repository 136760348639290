/* Dashboard */

/* Core */
import { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate, useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Components */
import Hero from "../media/Hero"
import Layout from "./Layout"
import ProfileDetails from "../user/ProfileDetails"



/* Media */
import styles from './General.module.css'
import headerImage from '../../media/images/screenshots/Screenshot_B19.jpg';
import UsersInfo from "components/dashboard-2/users-info"
import WalletStatus from "components/dashboard-2/wallet-status"

const _ = require('lodash')

function GeneralLayout(props) {
    const rootStore = useStores()
    const history = useNavigate()
    const location = useLocation()


    return (
        <div>
            <Layout noContainer={true}>
                {/* Header Image */}
                <Hero 
                    height = {111}
                    primaryImageSource = {headerImage}
                    backgroundPosition = "center center"
                    color1 = "rgba(66, 9, 126, .8)"
                    color2 = "rgba(66, 9, 126, 1)"
                    />
                
                <div className="container">
                    <div className={styles.Container}>
                        <div className="row">
                            {/* Left Column */}
                            {/*<ProfileDetails />*/}
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <UsersInfo className={styles.usersInfo} />
                                <WalletStatus className={styles.walletStatus}  />
                            </div>

                            {/* Right Column */}
                            <div className="col-lg-8">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default observer(GeneralLayout)