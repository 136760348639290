import { useEffect, useState } from 'react'
import ChromaListStatic from 'CHROMA-React/components/chroma-list-static'

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Media */
import styles from './OnlineUsers.module.css'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

function OnlineUsers(props) {
    const [usersOnline, setUsersOnline] = useState()

    // GET Users Online
    useEffect(() => {
    const getUsersOnline = async () => {
		// Last 30 Minutes
        const timeout = moment().subtract(30, 'minutes').unix()
        const minutesQuery = {
			service: "user-app-data",
            query: {key: "Online", updatedAt: {$gt: timeout}},
            fields: "userId value updatedAt"
        }	
        chroma.find(minutesQuery, true).then(async data => {
			if(typeof data === 'object' && data.length > 0){
                let currentUsers = []

                for (let i = 0; i < data.length; i++) {
                    const userId = _.get(data[i], 'userId')

                    let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"

                    const query = {
                        service: "users",
                        id: userId,
                        fields: "_id username createdAt meta{profile origin{seedcode} appData{MDVR{sessions{minutesPlayed}}}}"
                    }

                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
                    }

                    const chroma_query_endpoint = chroma_endpoint + '/query/get'
                    const userRequest = await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})

                    currentUsers.push(_.get(userRequest, "data", {}))
                }

                setUsersOnline(currentUsers)
            }
        })
    }

    getUsersOnline()

	}, [setUsersOnline])

    return(
        <div className={styles.OnlineUsers}>
            <ChromaListStatic service="users" items={usersOnline} header="🎮 Online Users" />
        </div>
    )
}

export default OnlineUsers