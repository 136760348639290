/* <QueryBuilder /> */

/* Data */
import { useEffect, useState } from "react"
import { useStores } from "../../../context/RootStoreContext"

/* Components */
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

/* Media */
import styles from './ServiceSelector.module.css'

const _ = require('lodash')


function ServiceSelector(props) {
    const rootStore = useStores()
    const callback = _.get(props, "callback")
    const [service, setService] = useState(_.get(props, "service"))

    // console.log("<ServiceSelector />", service)

    const serviceChange = (event) => {
        setService(event.target.value)
        callback(event.target.value)
    }
    
    return(
        <div className={styles.ServiceSelector}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" className={styles.label}>Service</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Service"
                    value={service}
                    onChange={serviceChange}
                    className={styles.select}
                >
                    <MenuItem value="apps">Apps</MenuItem>
                    <MenuItem value="assets">Assets</MenuItem>
                    {/*<MenuItem value="builds">Builds</MenuItem>*/}
                    <MenuItem value="posts">Posts</MenuItem>
                    <MenuItem value="products">Products</MenuItem>
                    {/*<MenuItem value="transactions">Transactions</MenuItem>*/}
                    <MenuItem value="users">Users</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default ServiceSelector