/* Framework */
import { types } from 'mobx-state-tree'

/* Models */
import * as BaseModel from '../structure/BaseModel'


/* RootStore Properties */
const props = {
    address: types.optional(types.string, "/root/settings"),
    namespace: types.optional(types.string, "settings"),
    theme: types.optional(types.string, 'dark'),
}

/* RootStore Actions */
const actions = (self) => ({
    changeTheme(newTheme) {
        // Update UI
        document.getElementById('chroma-frontend').className = newTheme

        // Store theme locally
        self.theme = newTheme
    }
})

/* RootStore Model */
const SettingsStore = types
  .model('SettingsStore')
  .props(props)
  .actions(actions)
  /* BaseModel */
  .props(BaseModel.props)
  .views(BaseModel.views)
  .actions(BaseModel.actions)
  /* Store (must come at end otherwise may be overridden) */
  .props(props)
  .actions(actions)


export default SettingsStore


