/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import ReactMarkdown from 'react-markdown'

/* Media */
import styles from './ReleaseNote.module.css'

const _ = require('lodash')
const axios = require('axios')

const RELEASE_NOTES_ROOT_ENDPOINT = 'https://raw.githubusercontent.com/VisionAgency/CHROMA-Release-Notes/main'
const RELEASE_NOTES_ENDPOINT = `release-notes.json`

function ReleaseNotes(props) {
    const [releaseNotes, setReleaseNotes] = useState([])
    const releaseEndpoint = _.get(props, "release")

    const title = releaseEndpoint[0].toUpperCase() + releaseEndpoint.slice(1).toLowerCase()

    useEffect(() => {
        const getReleaseNote = async () => {
            const releaseData = await getContentFromRepoEndpoint(RELEASE_NOTES_ENDPOINT)
            const releaseEndpoints = _.get(releaseData, `${releaseEndpoint}.archive`, [])
            
            // Get release markdown for archive
            let releases = []

            for await (const release of releaseEndpoints) {
                const releaseMarkdown = await getContentFromRepoEndpoint(release)
                releases.push(releaseMarkdown)
            }
            setReleaseNotes(releases)
        }

        getReleaseNote()
    }, [])

    const getContentFromRepoEndpoint = async (relativeUrl) => {
        const result = await axios.get(`${RELEASE_NOTES_ROOT_ENDPOINT}/${relativeUrl}`)
        return _.get(result, 'data')
    }

    const getReleaseElement = (release, i) => {
        return(
            <div key={i} className={styles.release}>
                <ReactMarkdown>{release}</ReactMarkdown>
            </div>
        )
    }

    return(
        <div className={styles.Container}>
            <div className={styles.Intro}>
                <h2>📔 {title} Release Notes</h2>
                <p>
                    Stay up to date with the latest changes to our software & platform.
                </p>
            </div>
            {releaseNotes.length > 0 && releaseNotes.map((release, i) => {
                return (getReleaseElement(release, i))
            })}
        </div>
    )
}

export default ReleaseNotes