/* <QueryPreview /> */

/* Data */
import { useEffect, useState } from "react"
import { useStores } from "../../../context/RootStoreContext"

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faDatabase } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './QueryPreview.module.css'

const _ = require('lodash')


function QueryPreview(props) {
    const rootStore = useStores()
    const query = _.get(props, "query", {})

    const copy = async () => {
        await navigator.clipboard.writeText(JSON.stringify(query));
    }
    
    return(
        <div className={styles.QueryPreview}>
            <h3 className={styles.chromaQLTitle}>
                <FontAwesomeIcon icon={faDatabase} className={styles.chromaQlTitleIcon} />
                chromaQL
            </h3>
            <code className={styles.chromaQLCode}>
                <button className={`${styles.copyButton}`} onClick={copy}>
                    <FontAwesomeIcon icon={faCopy} className={styles.chromaQlTitleIcon} />
                </button>
                <pre>
                    {JSON.stringify(query, undefined, 2)}
                </pre>
            </code>
        </div>
    )
}

export default QueryPreview