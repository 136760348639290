/* Header */


/* Core */
import { useEffect, useState } from 'react'
import chroma from '@chromaplatform/chromajs'

/* Router */

/* Data */

/* Components */
import Alert from '@material-ui/lab/Alert'

/* Media */
import styles from './ChromaMessages.module.css'

const _ = require('lodash')

function ChromaMessages(props) {
    const [messages, setMessages] = useState([])

    useEffect(() => {
        const getMessages = async () => {
            const query = {
				service: "chroma-core",
				fields: "key value",
        		query: {key:"CHROMA_MESSAGES"}
			}

			const messages = await chroma.find(query)
            
            if(_.get(messages, "length") > 0 && _.get(messages[0], 'value.enabled')){
                setMessages(_.get(messages[0], 'value.messages'))
            }
        }

        getMessages()
    }, [setMessages])

    return (
        <div className={styles.ChromaMessagesContainer}>
            <div className={styles.ChromaMessages}>
                {messages.map((message, index) => (
                    <Alert className={styles.Message} key={index} severity={message.type}>{message.message}</Alert>
                ))}

                {/* <div className={styles.Message}>
                    <p>This is a chroma-wide message</p>
                </div> */}
            </div>
        </div>
    )
}

export default ChromaMessages