/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import Accordion from 'react-bootstrap/Accordion'
import ReactMarkdown from 'react-markdown'

/* Media */
import styles from './ReleaseNotesDash.module.css'
import QueryBuilder from "components/query-builder/QueryBuilder"
import ReleaseNote from "./ReleaseNote"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faListAlt } from "@fortawesome/free-solid-svg-icons"

const _ = require('lodash')
const axios = require('axios')

const RELEASE_NOTES_ROOT_ENDPOINT = 'https://raw.githubusercontent.com/VisionAgency/CHROMA-Release-Notes/main'
const RELEASE_NOTES_ENDPOINT = `release-notes.json`

function ReleaseNotesDash(props) {
    const [platformRelease, setPlatformRelease] = useState("")
    const [microdoseRelease, setMicrodoseRelease] = useState("")
    const [frontendRelease, setFrontendRelease] = useState("")
    const [desktopRelease, setDesktopRelease] = useState("")

    useEffect(() => {
        const getReleaseNotes = async () => {
            let releaseData = await getContentFromRepoEndpoint(RELEASE_NOTES_ENDPOINT)
            console.log(releaseData)

            const microdoseReleaseUrl = _.get(releaseData, "microdose.latest", "")
            const platformReleaseUrl = _.get(releaseData, "api.latest", "")
            const desktopReleaseUrl = _.get(releaseData, "desktop-hub.latest", "")
            const frontendReleaseUrl = _.get(releaseData, "chroma-frontend.latest", "")

            setPlatformRelease(platformReleaseUrl)
            setMicrodoseRelease(microdoseReleaseUrl)
            setDesktopRelease(desktopReleaseUrl)
            setFrontendRelease(frontendReleaseUrl)

            console.log(microdoseReleaseUrl, platformReleaseUrl, desktopReleaseUrl, frontendReleaseUrl)
        }

        getReleaseNotes()
    }, [])

    const getContentFromRepoEndpoint = async (relativeUrl) => {
        const result = await axios.get(`${RELEASE_NOTES_ROOT_ENDPOINT}/${relativeUrl}`)
        return _.get(result, 'data')
    }

    const getReleaseElement = (release, i) => {
        return(
            <div key={i} className={styles.release}>
                <ReactMarkdown>{release}</ReactMarkdown>
            </div>
        )
    }

    return(
        <div className={styles.Container}>
            <div className={styles.Intro}>
                <h2>📔 Latest Release Notes</h2>
                <p>
                    Stay up to date with the latest changes to our software & platform.
                </p>
            </div>

            {/* Release Notes List */}
            <div className={styles.Section}>
                <h2 className={styles.title}>Microdose VR</h2>
                {microdoseRelease !== "" &&
                    <ReleaseNote endpoint={microdoseRelease} />
                }
                <div className={styles.LinkContainer}>
                    <Link to="/releases/microdose" className={styles.ViewAllButton}>
                        <FontAwesomeIcon icon={faListAlt} className={styles.Icon} />
                        Microdose Archive
                    </Link>
                </div>
            </div>

            <div className={styles.Section}>
                <h2 className={styles.title}>CHROMA Frontend</h2>
                {frontendRelease !== "" &&
                    <ReleaseNote endpoint={frontendRelease} />
                }
                <div className={styles.LinkContainer}>
                    <Link to="/releases/frontend" className={styles.ViewAllButton}>
                        <FontAwesomeIcon icon={faListAlt} className={styles.Icon} />
                        CHROMA Frontend Archive
                    </Link>
                </div>
            </div>

            <div className={styles.Section}>
                <h2 className={styles.title}>Desktop Hub</h2>
                {desktopRelease !== "" &&
                    <ReleaseNote endpoint={desktopRelease} />
                }
                <div className={styles.LinkContainer}>
                    <Link to="/releases/desktop" className={styles.ViewAllButton}>
                        <FontAwesomeIcon icon={faListAlt} className={styles.Icon} />
                        Desktop Hub Archive
                    </Link>
                </div>
            </div>

            <div className={styles.Section}>
                <h2 className={styles.title}>Platform API</h2>
                {platformRelease !== "" &&
                    <ReleaseNote endpoint={platformRelease} />
                }
                <div className={styles.LinkContainer}>
                    <Link to="/releases/api" className={styles.ViewAllButton}>
                        <FontAwesomeIcon icon={faListAlt} className={styles.Icon} />
                        Platform API Archive
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default ReleaseNotesDash