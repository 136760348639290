/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import ReactMarkdown from 'react-markdown'

/* Media */
import styles from './ReleaseNote.module.css'

const _ = require('lodash')
const axios = require('axios')

const RELEASE_NOTES_ROOT_ENDPOINT = 'https://raw.githubusercontent.com/VisionAgency/CHROMA-Release-Notes/main'
const RELEASE_NOTES_ENDPOINT = `release-notes.json`

function ReleaseNote(props) {
    const [releaseNote, setReleaseNote] = useState("")
    const noteEndpoint = _.get(props, "endpoint")

    useEffect(() => {
        const getReleaseNote = async () => {
            const releaseData = await getContentFromRepoEndpoint(noteEndpoint)
            setReleaseNote(releaseData)
        }

        getReleaseNote()
    }, [])

    const getContentFromRepoEndpoint = async (relativeUrl) => {
        const result = await axios.get(`${RELEASE_NOTES_ROOT_ENDPOINT}/${relativeUrl}`)
        return _.get(result, 'data')
    }

    const getReleaseElement = (release) => {
        return(
            <div className={styles.release}>
                <ReactMarkdown>{release}</ReactMarkdown>
            </div>
        )
    }

    return(
        <div>
            {getReleaseElement(releaseNote)}
        </div>
    )
}

export default ReleaseNote