/* <ProductsList /> */

import { useEffect, useState } from "react"

import { useStores } from "../../context/RootStoreContext"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faFlask } from '@fortawesome/free-solid-svg-icons'

import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './ProductsList.module.css'

const axios = require('axios')

let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"
let chroma_tx_endpoint =  chroma_endpoint + "/transactions"

const _ = require('lodash')

function ProductsList(props) {
    const rootStore = useStores()

    /* Local State */
    const [products, setProducts] = useState([])

    /* Get Products data */
    useEffect(() => {
        const getUserProducts = async () => {
            const query = {
                service: "users",
                id: rootStore.AuthStore.userId,
                fields: "username meta{products}"
            }

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }

            const chroma_query_endpoint = chroma_endpoint + '/query/get'
            await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
                .then(function (response) {
                    const products = _.get(response, "data.meta.products", [])
                    getProductInfo(products)
                })
                .catch(function (error) {
                    console.log("CHROMA > Error Getting products", _.get(error, "message"))
                })
    
            // chroma.get(query).then(data => {
            //     const products = _.get(data, "meta.products")
            //     getProductInfo(products)
            //     console.log('products', products)
            // })
        }

        const getProductInfo = async (products = []) => {
            let incomingProducts = []
            for (let i = 0; i < products.length; i++) {
                const product = products[i]
                
                const query = {
                    service: "products",
                    id: product.productId,
                    fields: "slug copy meta{buildType}"
                }
        
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
                }
    
                const chroma_query_endpoint = chroma_endpoint + '/query/get'
                await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
                    .then(function (response) {
                        const product = _.get(response, "data", {})
                        incomingProducts.push(product)
                    })
                    .catch(function (error) {
                        console.log("CHROMA > Error Getting products", _.get(error, "message"))
                    })
                // const data = await chroma.get(query)
                // console.log('data', data)
            }
            setProducts(incomingProducts)
        }


        getUserProducts()
    }, [chroma, rootStore, setProducts])

    const getProductUI = (product, i) => {
        const buildType = _.get(product, "meta.buildType[0]")
        return (
            <div key={i} className={`${styles.Product} container`}>
                <div className="row">
                    <div className="col-1">
                        {buildType === "alpha" ?<FontAwesomeIcon icon={faFlask} /> : null}
                        {buildType === "beta" ?<FontAwesomeIcon icon={faTint} /> : null}
                    </div>
                    <div className="col-11">{_.get(product, "copy.en.title")}</div>
                </div>
            </div>
        )
    }
    

    // if(_.get(products, "length") < 1)
    //     return(
    //         <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
    //     )

    return(
        <div className={styles.Products}>
            {products.map((item, i) => {
                return getProductUI(item, i)
            })}
            {products.length < 1 ? <p className={styles.Empty}>You do not have any products</p> : ""}
        </div>
    )
}

export default ProductsList
