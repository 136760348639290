/* <EmailLink /> */

import Obfuscate from 'react-obfuscate';


function EmailLink(props) {
    return(
        <Obfuscate email={props.address} aria-label="Email Us">
            {props.children}
        </Obfuscate>
    )
}

export default EmailLink