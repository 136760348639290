/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */


/* Media */
import styles from './AdminUserDash.module.css'
import QueryBuilder from "components/query-builder/QueryBuilder"

const _ = require('lodash')
const moment = require('moment')

function AdminUserDash(props) {

    const chromaQLUsers = {
      service: "users",
      query: {
        "$sort": {
          createdAt: -1
        },
        "$limit": 0
      },
      fields: "_id username email createdAt meta{profile products appData{MDVR{sessions{minutesPlayed}}}}"
    }

    return(
        <div className={styles.AdminDashContainer}>

          {/* Users */}
          <div className={styles.Section}>
              <QueryBuilder
                header="🧔 Users"
                limit={9999}
                queryable={true}
                chromaQL={chromaQLUsers}
              />
          </div>
        </div>
    )
}

export default AdminUserDash