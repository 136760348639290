/* <AdminDash /> */

import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Charts - move to sub component */
import CircularProgress from '@material-ui/core/CircularProgress'

/* Components */


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faFire, faHourglassHalf, faMoneyBill, faSatelliteDish, faSearch, faSeedling, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'


/* Media */
import styles from './AdminDash.module.css'
import QueryBuilder from "components/query-builder/QueryBuilder"
import OnlineUsers from "components/admin/OnlineUsers"
import SimpleLineChart from "components/charts/SimpleLineChart"
import UserAmountChart from "components/charts/UserAmountChart"
import TransactionsAmountChart from "components/charts/TransactionsAmountChart"
import UserActiveChart from "components/charts/UserActiveChart"
import ChromaListBasic from "CHROMA-React/components/chroma-list-basic"


const _ = require('lodash')
const moment = require('moment')

function AdminDash(props) {
    /* Local State */
    const [users, setUsers] = useState({
		users: [],
		userCount: 0
	})
	const [timePlayed, setTimePlayed] = useState(0)
  	const [minutesPlayed, setMinutesPlayed] = useState(0)
	const [seedcodes, setSeedcodes] = useState([])
	const [transactions, setTransactions] = useState([])
	const [transactionsThisHour, setTransactionsThisHour] = useState()
	const [transactionsThisWeek, setTransactionsThisWeek] = useState()
	const [transactionsThisMonth, setTransactionsThisMonth] = useState()
	const [transactionsThisYear, setTransactionsThisYear] = useState()
	const [usersOnline, setUsersOnline] = useState()
	const [usersOnlineToday, setUsersOnlineToday] = useState()
	const [usersOnlineThisWeek, setUsersOnlineThisWeek] = useState()
	const [usersOnlineThisMonth, setUsersOnlineThisMonth] = useState()
	const [usersOnlineThisYear, setUsersOnlineThisYear] = useState()
	const [update, setUpdate] = useState(false)

	let refreshInterval

	/* Keep Page Fresh */
	useEffect(() => {
		// setInterval(() => {
		// 	window.scrollTo(0, 0)
		// }, 100)
		const REFRESH_TIME = 1000 * 60

		// eslint-disable-next-line react-hooks/exhaustive-deps
		refreshInterval = setInterval(() => {
			setUpdate(!update)
		
		}, REFRESH_TIME)

		return () => {
			clearInterval(refreshInterval)
		}
	}, [setUpdate, update])
	
	/* Get Users data */
	useEffect(() => {
	let totalTimePlayed = 0

		const getUsers = async () => {
			const usersQuery = {
				service: "users",
				fields: "_id username createdAt meta{origin{seedcode} appData{MDVR{sessions{minutesPlayed}}} geolocation{current{city region}}}"
			}
	
			const users = await chroma.find(usersQuery)
			let data = {
				users: users,
				userCount: users.length || 0
			}
			setUsers(data)

			totalTimePlayed = users.reduce((a, b) => a + (_.get(b, "meta.appData.MDVR.sessions.minutesPlayed") || 0), 0)
			totalTimePlayed = Math.ceil(totalTimePlayed)

			setTimePlayed(totalTimePlayed)
		}


		getUsers()
	}, [chroma, setUsers, setTimePlayed, update])

	// GET Minutes Played
	useEffect(() => {
		const getMinutesPlayedData = async () => {
			const minutesQuery = {
				service: "user-app-data",
				query: {key: "Online"},
				fields: "value"
			}
		
			chroma.find(minutesQuery, true).then(data => {
				const totalMinutes = data.reduce((a, b) => a + (b["value"]["minutes"] || 0), 0)
				setMinutesPlayed(totalMinutes)
			})
		}

		getMinutesPlayedData()

		// Triggers on teardown - Remove Events
		return () => {
			// global.removeEventListener('/user/authenticated', handleAuth)
		}
	}, [setMinutesPlayed, update])
	
	// GET Users Online
	useEffect(() => {
		const getUsersOnline = async () => {
			// Last 30 Minutes
			const timeout = moment().subtract(30, 'minutes').unix()
			const minutesQuery = {
				service: "user-app-data",
				// query: {userId: profile.id, key: "Online"},
				query: {key: "Online", updatedAt: {$gt: timeout}},
				fields: "value updatedAt"
			}	
			chroma.find(minutesQuery, true).then(data => {
				setUsersOnline(data.length)
			})

			// Last Day
			const today = moment().subtract(60 * 24, 'minutes').unix()
			const dayQuery = {
				service: "user-app-data",
				// query: {userId: profile.id, key: "Online"},
				query: {key: "Online", updatedAt: {$gt: today}},
				fields: "value updatedAt"
			}	
			chroma.find(dayQuery, true).then(data => {
				setUsersOnlineToday(data.length)
			})
			
			// Last week
			const thisWeek = moment().subtract(60 * 24 * 7, 'minutes').unix()
			const weekQuery = {
				service: "user-app-data",
				query: {key: "Online", updatedAt: {$gt: thisWeek}},
				fields: "value updatedAt"
			}
			chroma.find(weekQuery, true).then(data => {
				setUsersOnlineThisWeek(data.length)
			})

			// Last month
			const thisMonth = moment().subtract(60 * 24 * 7 * 4, 'minutes').unix()
			const monthQuery = {
				service: "user-app-data",
				query: {key: "Online", updatedAt: {$gt: thisMonth}},
				fields: "value updatedAt"
			}
			chroma.find(monthQuery, true).then(data => {
				setUsersOnlineThisMonth(data.length)
			})

			// Last year
			const thisYear = moment().subtract(60 * 24 * 7 * 4 * 12, 'minutes').unix()
			const yearQuery = {
				service: "user-app-data",
				query: {key: "Online", updatedAt: {$gt: thisYear}},
				fields: "value updatedAt"
			}
			chroma.find(yearQuery, true).then(data => {
				setUsersOnlineThisYear(data.length)
			})
		
			
		}

		getUsersOnline()

		// Triggers on teardown - Remove Events
		return () => {
			// global.removeEventListener('/user/authenticated', handleAuth)
		}
	}, [setUsersOnline, update])

	/* Get Seedcode data */
	useEffect(() => {
		const getSeedcodes = async () => {
			const seedcodesQuery = {
				service: "supply/code",
				fields: "_id code totalTokenSupply createdAt updatedAt mode tokensGenerated tokens meta"
			}

			const seedcodes = await chroma.find(seedcodesQuery)
			setSeedcodes(seedcodes)
		}

		getSeedcodes()
	}, [chroma, setSeedcodes])

	/* Get Transaction data */
	useEffect(() => {
		const getTransactions = async () => {
			const txQuery = {
				service: "transactions",
				fields: "_id amount medium inMeta{totalUSDRecieved}",
        		query: {status:"complete"}
			}

			const transactions = await chroma.find(txQuery)
			setTransactions(transactions)

			// Last hour
			const thisHour = moment().subtract(60, 'minutes').unix()
			const hourQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: thisHour}},
				fields: "updatedAt"
			}
			chroma.find(hourQuery, true).then(data => {
				setTransactionsThisHour(data.length)
			})

			// Last week
			const thisWeek = moment().subtract(60 * 24 * 7, 'minutes').unix()
			const weekQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: thisWeek}},
				fields: "updatedAt"
			}
			chroma.find(weekQuery, true).then(data => {
				setTransactionsThisWeek(data.length)
			})

			// Last month
			const thisMonth = moment().subtract(60 * 24 * 7 * 4, 'minutes').unix()
			const monthQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: thisMonth}},
				fields: "updatedAt"
			}
			chroma.find(monthQuery, true).then(data => {
				setTransactionsThisMonth(data.length)
			})

			// Last year
			const thisyear = moment().subtract(60 * 24 * 7 * 4 * 12, 'minutes').unix()
			const yearQuery = {
				service: "transactions",
				query: {status: "complete", updatedAt: {$gt: thisyear}},
				fields: "updatedAt"
			}
			chroma.find(yearQuery, true).then(data => {
				setTransactionsThisYear(data.length)
			})
		}

		getTransactions()
	}, [chroma, setTransactions, update])


	const chromaQLSeedcodes = {
		service: "supply/code",
		fields: "_id code mode totalTokenSupply tokensGenerated"
	}

	const chromaQLUsers = {
		service: "users",
		query: {
			"$sort": {
				createdAt: -1
			},
			"$limit": 0
		},
		fields: "_id username email createdAt meta{profile products appData{MDVR{sessions{minutesPlayed}}}}"
	}

	const chromaQLTransactions = {
		service: "transactions",
		query: {
			"status": "complete",
			"$sort": {
				createdAt: -1
			},
			"$limit": 0
		},
		fields: "_id createdAt amountUsd outMetaType outMetaIds"
	}

    return(
        <div className={styles.AdminDashContainer}>
			<div className="container">
				<div className={styles.Container}>
					<div className="row">



						{/* Left Column */}
						<div className="col-lg-10">
							{/* Three Columns */}
							<div className="row">
								
								{/* Users */}
								<div className={`${styles.card} col-sm`} style={{height: 275}}>
									<div className={`${styles.CardBody} ${styles.chartCardBody} ${styles.usersRowCard} card-body`}>
										{/* Total Users */}
										<div className={`row`} style={{marginBottom: 64, zIndex: 10}}>
											<div className={`col`}>
												<h3 style={{fontSize: "14px", textAlign:"left", whiteSpace:"nowrap", margin: 0, opacity: ".6"}}>
													<FontAwesomeIcon icon={faUserAstronaut} className={styles.AnalyticsTitleIcon} />
													Users
												</h3>
											</div>
											<div className={`col`} style={{textAlign:"right"}}>
												<h2 style={{fontSize: "32px", margin: 0}}>{users.userCount}</h2>
											</div>
										</div>
										<UserAmountChart className={styles.chartUsers} />
									</div>

									{/* Active Users Overview */}
									<div className={`${styles.CardBody} ${styles.chartCardBody} ${styles.usersRowCard} card-body`}>	
										<div className={`row`} style={{zIndex: 10}}>
											
											<div className={`col`} style={{textAlign:"left"}}>
												<h3 style={{fontSize: "14px", margin: "0 0 12px 0", opacity: ".6"}}>
													<FontAwesomeIcon icon={faFire} className={styles.AnalyticsTitleIcon} />
													Active 
												</h3>
											</div>

											<div className={`col`} style={{textAlign:"right"}}>
												<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", marginBottom: "4px"}}>
													{usersOnline > 0 &&
														<span className={styles.IconOnline}></span>
													}
													{usersOnline === 0 &&
														<span className={styles.IconOffline}></span>
													}
													<strong style={{color: "#fff"}}>{usersOnline}</strong>
												</h2>
											</div>

											<h2 style={{color: "rgba(255,255,255,0.3)" , textAlign:"left", whiteSpace:"nowrap",fontWeight:"400", fontSize: "14px", margin: "8px 0"}}>
												<strong style={{color: "#fff"}}>{usersOnlineToday}</strong> today / <strong style={{color: "#fff"}}>{usersOnlineThisWeek}</strong> this week
											</h2>
											<h2 style={{color: "rgba(255,255,255,0.3)", textAlign:"left", whiteSpace:"nowrap" ,fontWeight:"400", fontSize: "14px", margin: "0 0 10px 0"}}>
												<strong style={{color: "#fff"}}>{usersOnlineThisMonth}</strong> this month / <strong style={{color: "#fff"}}>{usersOnlineThisYear}</strong> this year
											</h2>

										</div>
									</div>
								</div>
					
								{/* Time Played */}
								<div className={`${styles.card} col-sm`}>
									<div className={`${styles.CardBody} ${styles.chartCardBody} ${styles.usersRowCard} card-body`} style={{textAlign:"center"}}>
										<h3 style={{fontSize: "14px", marginBottom: "16px", opacity: ".6"}}>
										<FontAwesomeIcon icon={faHourglassHalf} className={styles.AnalyticsTitleIcon} />
										Total Time Played
										</h3>

										{timePlayed > 0 &&
											<div>
												<h2 style={{fontSize: "18px", color: "rgba(255,255,255,0.6)", whiteSpace:"nowrap", fontWeight:"300", margin: "0 0 8px 0"}}>
													
												</h2>
												<h2 style={{fontSize: "18px", color: "rgba(255,255,255,0.6)", whiteSpace:"nowrap", fontWeight:"300", margin: 0, whiteSpace: "nowrap"}}>
													<strong style={{color: "#fff"}}>{Math.ceil((timePlayed + minutesPlayed) / 60 / 24).toLocaleString('en-US')}</strong> Days  
													/ <strong style={{color: "#fff"}}>{Math.ceil((timePlayed + minutesPlayed) / 60).toLocaleString('en-US')}</strong> Hours
												</h2>
												<h2 style={{fontSize: "24px", color: "rgba(255,255,255,0.6)", whiteSpace:"nowrap", fontWeight:"300", margin: "8px 0 8px 0"}}>
													<strong style={{color: "#fff"}}>{(timePlayed + minutesPlayed).toLocaleString('en-US')}</strong> Minutes
												</h2>
											</div>
										}

										{!timePlayed > 0 &&
											<CircularProgress className={styles.loadingIcon} disableShrink />
										}
									</div>

									{/* Active Users */}
									<div className={`${styles.CardBody} ${styles.chartCardBody} ${styles.usersRowCard} card-body`}>	
										{/* Online Users */}
										<div className={`row`} style={{zIndex: 10}}>
											<div className={`col`} style={{textAlign:"left"}}>
												<h3 style={{fontSize: "14px", margin: "0 0 65px 0", opacity: ".6"}}>
													<FontAwesomeIcon icon={faClock} className={styles.AnalyticsTitleIcon} />
													Last 24h
												</h3>
											</div>
										</div>
										<UserActiveChart className={styles.chartUsers} />
									</div>
								</div>
								
								{/* Total Sales */}
								<div className={`${styles.card} col-sm`}>
									<div className={`${styles.CardBody} ${styles.chartCardBody} card-body`} style={{minWidth: "0", height: "275px", padding: "0"}}>
										<div className="card-body" style={{textAlign:"center", zIndex: 10}}>
											<Link to="/admin/transactions" className="">
												<h3 style={{fontSize: "14px", marginBottom: "24px", opacity: ".6"}}>
													<FontAwesomeIcon icon={faMoneyBill} className={`${styles.AnalyticsTitleIcon} ${transactionsThisHour > 0 ? styles.RecentSale : ""}`} />
													Total Sales ({transactions.length})
												</h3>
											</Link>
					
											{transactions.length > 0 &&
												<div>
													<h2 className={`${styles.CurrencyTitleTotal} ${transactionsThisHour > 0 ? styles.RecentSaleTitle : ""}`}>
														${parseFloat(transactions.reduce((a, b) => a + _.get(b, "inMeta.totalUSDRecieved", 0), 0)).toFixed(0)}
													</h2>

													<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", margin: "4px 0"}}>
														<strong style={{color: "#fff"}}>{transactionsThisWeek}</strong> this week
													</h2>
													<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", margin: "4px 0 0 0"}}>
														<strong style={{color: "#fff"}}>{transactionsThisMonth}</strong> this month
													</h2>
													<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", marginTop: "4px"}}>
														<strong style={{color: "#fff"}}>{transactionsThisYear}</strong> this year
													</h2>
								
													{/* <h2 className={styles.CurrencyTitle}>
														{parseFloat(transactions.filter(tx => tx.medium === "ltct").reduce((a, b) => a + b.amount, 0)).toFixed(3)}
														<Icon icon={ltcIcon} className={styles.CurrencyIcon} />
													</h2> */}
												</div>
											}
					
											{!transactions.length > 0 &&
												<CircularProgress className={styles.loadingIcon} disableShrink />
											}
										</div>

										<TransactionsAmountChart height={130} className={`${styles.chartUsers}`} />
									</div>
								</div>
							</div>






							{/* Online Users */}
							{usersOnline > 0 &&
								<OnlineUsers />
							}
				
							{/* Users */}
							<div className={styles.Section}>
								<QueryBuilder
									header="🧔 Latest Users"
									limit={6}
									chromaQL={chromaQLUsers}
								/>
							</div>

							{/* Transactions */}
							<div className={styles.Section}>
								<QueryBuilder
									header="💸 Latest Transactions"
									limit={5}
									update={update}
									chromaQL={chromaQLTransactions}
								/>
								
							</div>
            
                        	{/* Seedcodes */}
							{/*<div className={styles.Section}>
								<h2>🌾 Latest Seedcodes</h2>
								<div className={styles.UserContainer}>
									<ChromaListBasic query={chromaQLSeedcodes} limit={6} />
								</div>
							</div>*/}
						</div>

						{/* Right Column */}
						<div className="col-lg-2">
							<div className={styles.QueryBuilderLink}>
								<Link to="/admin/users" style={{width: "100%"}} className="btn btn-block btn-primary">
									<FontAwesomeIcon icon={faUserAstronaut} className={styles.searchIcon} />
									Users
								</Link>
							</div>

							<div className={styles.QueryBuilderLink}>
								<Link to="/admin/affiliates" style={{width: "100%"}} className="btn btn-block btn-primary">
									<FontAwesomeIcon icon={faSatelliteDish} className={styles.searchIcon} />
									Affiliates
								</Link>
							</div>

							<div className={styles.QueryBuilderLink}>
								<Link to="/admin/transactions" style={{width: "100%"}} className="btn btn-block btn-primary">
									<FontAwesomeIcon icon={faMoneyBill} className={styles.searchIcon} />
									Transactions
								</Link>
							</div>

							<div className={styles.QueryBuilderLink}>
								<Link to="/chromaql" style={{width: "100%"}} className="btn btn-block btn-primary">
									<FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
									Query Builder
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>      
        </div>
    )
}

export default AdminDash