/* Dashboard */

import ReactGA from 'react-ga4'

/* Core */
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './Download.module.css'

import logoDark from '../media/images/logo-tall-dark.png'
import logoLight from '../media/images/logo-tall-light.png'

import Screenshot from '../media/images/screenshots/Screenshot_B18.jpg'

import analyticEvent from 'CHROMA-React/code/_analytics'

function Home(props) {
    const rootStore = useStores()

    analyticEvent("Download_Clicked", {})

    // ReactGA.event({
    //     category: 'Dashboard',
    //     action: `Download Clicked`
    // })

    const getLogo = () => {
        switch(rootStore.SettingsStore.theme){
            default:
                return logoLight

            case "light":
                return logoDark

            case "dark":
                return logoLight
        }
    }

    return (
        <Layout noContainer="true">
            <Helmet>
                <title>CHROMA | Download</title>
            </Helmet>
            <div className={styles.Home}>
                {/* Microdose VR Intro */}
                {/*<div className={styles.IntroInfo}>
                    <div className={`container ${styles.LearnContainer}`}>
                        <div className={styles.LearnInfo}>
                            <h2 className={styles.IntroTitle}>
                                <FontAwesomeIcon icon={faInfoCircle} className={styles.VrIcon} />
                                Use our hub to install & manage your Microdose builds.
                            </h2>
                            <p className={styles.LearnText}></p>
                        </div>
                    </div>
                </div>*/}

                {/* Download CHROMA */}
                <Hero 
                    title = "Download"
                    description = "Purchase required to install any Microdose builds"
                    button = "Download CHROMA Hub"
                    flip={true}
                    buttonUrl = "https://cloudcity.sfo3.digitaloceanspaces.com/microdosevr/hub/Chroma-Hub-Setup-2.0.10.exe"
                    primaryImageSource = {Screenshot}
                    color1 = "rgba(43, 6, 82, .6)"
                    color2 = "rgba(43, 6, 82, .9)"
                    />

                <div className={styles.DownloadInfo}>
                    <div className="container">
                        {/* <p style={{fontSize: "28px"}}> What is <a href="https://manual.microdosevr.com/en/hub">CHROMA</a>?</p> */}
                        <div className={styles.LearnInfo}>
                            <FontAwesomeIcon icon={faGraduationCap} className={styles.LearnIcon} />
                            <h2 className={styles.LearnTitle}>Learn More</h2>
                            <p className={styles.LearnText}>See hardware requirements, additional dependencies, and how to get started in our <a href="https://manual.microdosevr.com/#download-installation">Official Manual</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home