const queryConfig = {
    apps: {
        fields: "_id createdAt name copy",
        sortFields: [
            "_id",
            "createdAt",
            "name"
        ],
        queryFields: [
            {
                name: "name",
                type: "string"
            },
            {
                name: "_id",
                type: "string"
            }
        ]
    },
    assets: {
        fields: "_id createdAt type subtype meta copy children tokenId",
        sortFields: [
            "_id",
            "createdAt",
            "type"
        ],
        queryFields: [
            {
                name: "type",
                type: "select",
                options: ["none", "nft", "image", "collection"]
            },
            {
                name: "_id",
                type: "string"
            },
            {
                name: "tokenId",
                type: "string"
            }
        ]
    },
    builds: {
        fields: "_id createdAt name type status",
        sortFields: [
            "_id",
            "createdAt",
            "name",
            "type",
            "status"
        ],
        queryFields: [
            {
                name: "name",
                type: "string"
            },
            {
                name: "_id",
                type: "string"
            }
        ]
    },
    posts: {
        fields: "_id createdAt type slug meta children",
        sortFields: [
            "_id",
            "createdAt",
            "type"
        ],
        queryFields: [
            {
                name: "_id",
                type: "string"
            }
        ]
    },
    products: {
        fields: "_id createdAt type slug copy status meta",
        sortFields: [
            "_id",
            "createdAt",
            "type",
            "status"
        ],
        queryFields: [
            {
                name: "name",
                type: "string"
            },
            {
                name: "_id",
                type: "string"
            }
        ]
    },
    transactions: {
        fields: "_id createdAt medium outMetaType inMeta{address tx_hash log totalUSDRecieved} status amount",
        sortFields: [
            "_id",
            "createdAt",
            "medium",
            "status"
        ],
        queryFields: [
            {
                name: "_id",
                type: "string"
            },
            {
                name: "medium",
                type: "select",
                options: ["none", "usd", "eth", "btc", "ltc", "xmr", "trx", "usdt"]
            },
            {
                name: "status",
                type: "select",
                options: ["none", "pending", "complete", "failed"]
            }
        ]
    },
    users: {
        fields: "_id createdAt username meta email",
        sortFields: [
            "_id",
            "createdAt",
            "username"
        ],
        queryFields: [
            {
                name: "username",
                type: "string"
            },
            {
                name: "_id",
                type: "string"
            },
            {
                name: "active",
                type: "select",
                options: ["none", "true", "false"]
            },
        ]
    }
}

export default queryConfig