/* Core */
import { Link } from "react-router-dom"

/* Components */
import { Image } from 'antd'

/* Media */
import styles from './Hero.module.css'

function Hero(props) {
    const height = props.height || 500
    const primaryImageSource = props.primaryImageSource || false
    const altImageSource = props.altImageSource || false
    const color_1 = props.color1 || "rgba(0, 0, 0, .6)"
    const color_2 = props.color2 || "rgba(0, 0, 0, .8)"
    const button_color = props.buttonColor || null
    const flip = props.flip || false
    const removeAltImageShadow = props.removeAltImageShadow || false
    const backgroundPosition = props.backgroundPosition || "center top"

    const heroStyle = {
        height: height,
        backgroundImage: `linear-gradient(180deg, ${color_1} ,${color_2} ),url(${primaryImageSource})`,
        backgroundPosition: backgroundPosition,
        backgroundSize: 'cover'

    }

    const getButton = () => {
        if(props.buttonPath){
            // Use Link (local path)
            return (
                <Link to={props.buttonPath}>
                    <button className={`${styles.Button} ${button_color ? styles.ButtonColor: ""}`}>{props.button}</button>
                </Link>
            )
        } else if(props.buttonUrl){
            // Use anchor (external url)
            return (
                <a href={props.buttonUrl}>
                    <button className={`${styles.Button} ${button_color ? styles.ButtonColor: ""}`}>{props.button}</button>
                </a>
            )
        }

        // Otherwise return nothing
        return("")
    }

    const getTextContent = () => {
        return (
            <div className={`col-sm-12 col-lg-7 ${flip ? 'text-end' : ''}`} style={{paddingLeft: "0px !important"}}>
                <div className={styles.HeroInfo}>
                    {props.title ? <h4 className={styles.HeroTitle}>{props.title}</h4> : ""}
                    {props.description ? <p className={styles.HeroDescription}>{props.description}</p> : ""}
                    {props.button ? getButton() : ""}
                </div>
            </div>
        )
    }

    const getImageContent = () => {
        return (
            <div className="col-md-5" style={{paddingRight: "0px !important"}}>
                <div className="d-none d-lg-block">
                    {altImageSource ? <img src={altImageSource} className={removeAltImageShadow ? styles.AltImageNoShadow : styles.AltImage} /> : ""}
                </div>
            </div>
        )
    }

    // Return nothing if no primary image source is set
    if(!primaryImageSource){
        return ("")
    }
    
    
    return (
        <div className={`hero ${styles.Hero}`} style={heroStyle}>
            <div className="container">
                <div className="row p-5">
                    {flip ? (
                            getImageContent()
                        ) : (
                            getTextContent()   
                    )}

                    {flip ? (
                            getTextContent()   
                        ) : (
                            getImageContent()
                    )}
                    
                </div>
            </div>
        </div>
    )
}

export default Hero