/* Header */

/* Core */
import { Link } from "react-router-dom"

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import NavAuthenticated from "./NavAuthenticated"
import NavGuest from "./NavGuest"

import ChromaMessages from "../utilities/ChromaMessages"

/* Media */
import styles from './Navigation.module.css'



import logomark from '../../media/images/chroma-logo-dark.png';

function Navigation(props) {
    const rootStore = useStores()

    // Gets Navigation component based on authentication status
    const getNavigation = () => {
        const NavigationComponent = rootStore.AuthStore.authenticated ? <NavAuthenticated /> : <NavGuest />
        return NavigationComponent
    }

    return (
        <div className={styles.navigation}>
            <nav className="fixed-top main navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <div className="row" style={{width: "100%"}}>

                        {/* Logo  */}
                        <div style={{position: "relative"}} className="col-lg-3 col-md-4">
                        {/* <div className="col-sm"> */}
                            <div className="navbar-brand">
                                <Link to="/">
                                    {/* Logo */}
                                    <img src={logomark} alt="Logo" className={styles.logomark} />
                                </Link>
                                </div>
                            <div />
                        </div>

                        {/* Navigation */}
                        <div className="col-lg-9 position-end" style={{paddingRight: "0px"}}>
                            {/* Mobile Nav Toggle */}
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <FontAwesomeIcon icon={faBars} />
                            </button>

                            {/*  Links */}
                            <div className="collapse navbar-collapse" id="navbarSupportedContent" >

                                { getNavigation() }

                            </div>
                        </div>

                    </div>
                </div>

            </nav>
            
            {/* ChromaMessages */}
            <ChromaMessages />
        </div>
    )
}



export default Navigation


{/* User Dropdown Menu */}
{/* <UserMenu /> */}
// Legacy
{/* <ul className={styles.navigation}>
            <li>
                <Link to="/" style={{ textDecoration: 'none' }}>Home</Link>
            </li>
            <li>
                <Link to="/dashboard" style={{ textDecoration: 'none' }}>Dashboard</Link>
            </li>
            <li>
                <Link to="/post" style={{ textDecoration: 'none' }}>Post</Link>
            </li>
        </ul> */}