/* Worlds */

/* Core */
import { Helmet } from 'react-helmet-async'

/* Router */
import { Link } from "react-router-dom"

import Layout from "../components/layout/Layout"


/* Media */
import styles from './Worlds.module.css'

import world_map from '../media/images/worlds/world-map.png'



function Worlds(props) {
    return (
        <div>
            <Layout noContainer={true}>
                <Helmet>
                    <title>{`CHROMA | Microdose Worlds`}</title>
                </Helmet>
                
                <div className={styles.Worlds}>
                    <h3 className={styles.Header}>Worlds</h3>
                    <p className={styles.Description}>Find your way around the Microverse</p>

                    <div className="container">
                        <div className={styles.WorldsContainer}>
                            <img src={world_map} className={styles.Map} />
                        </div>
                    </div>

                    <div class={styles.WorldsIntro}>
                        <div class="container">
                            <h3 style={{textAlign:"center", margin: "24px 0 0 0"}}>More details coming soon...</h3>
                            {/* <FontAwesomeIcon icon={faMapMarked} className={styles.LearnIcon} /> */}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default Worlds