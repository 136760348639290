/* Core */
import _ from 'lodash'

import { useEffect, useState } from 'react'

/* Media */
import styles from './user.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCartArrowDown, faClock, faEnvelope, faIdCard } from '@fortawesome/free-solid-svg-icons'

import Grid from '@mui/material/Grid'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'

const axios = require('axios')
const moment = require('moment')

const UserListItem = (props) => {
    const { item } = props

    const [onlineMinutes, setOnlineMinutes] = useState(0)

    const username = _.get(item, "username", "N/A")
    const userId = _.get(item, "_id")
    const products = _.get(item, "meta.products", [])
    const avatarURL = _.get(item, "meta.profile.avatarURL")
    const url = `/${username}`

    useEffect(() => {
        const getTotalOnlineMinutes = async () => {
            const minutesQuery = {
                service: "user-app-data",
                query: {key: "Online", userId: userId},
                fields: "value"
            }

            let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }

            const chroma_query_endpoint = chroma_endpoint + '/query/find'
            const minutesRequest = await axios.post(chroma_query_endpoint, minutesQuery, {headers: headers, timeout: 0})
            
            let onlineMinutes = _.get(minutesRequest, "data[0].value.minutes", 0)
            onlineMinutes += Math.round(_.get(item, "meta.appData.MDVR.sessions.minutesPlayed", 0))
            
            setOnlineMinutes(onlineMinutes)
        }

        getTotalOnlineMinutes()
    })

    return (
        <div {...props} className={`chroma-list-item ${styles.listItemContainer}`}>
            {/* Avatar */}
            {avatarURL &&
                <div className={styles.avatar}>
                    <img src={avatarURL} />
                </div>
            }

            {/* Username */}
            <Link className={styles.link} to={url}>
                <h2 className={styles.name}>{username}</h2>
            </Link>
            <h3 className={styles.id}>{_.get(item, "_id")}</h3>
            
            <div className={styles.infoRow}>
                {/* Join Date */}
                <p className={styles.date}>
                    <FontAwesomeIcon icon={faCalendar} className={styles.icon} />
                    {moment(_.get(item, "createdAt", 1) * 1000).fromNow()}
                </p>

                {/* Products */}
                <p className={styles.date}>
                    <FontAwesomeIcon icon={faCartArrowDown} className={styles.icon} />
                    {products.length}
                </p>

                {/* Minutes */}
                <p className={styles.minutes}>
                    <FontAwesomeIcon icon={faClock} className={styles.icon} />
                    {onlineMinutes.toLocaleString('en-US')}
                </p>
                <br style={{clear: "both"}} />
            </div>
        </div>
    )
}

export default UserListItem