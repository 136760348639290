/* Layout */

/* Components */
import Header from "./Header"
import Footer from "./Footer"

/* Media */
import styles from './Layout.module.css'

function Layout(props) {
    if(props.noContainer){
        /* Has no container (aka no padding) on children
           - use this for pages that need proper full width imagery/content
        */
        return(
            <div className={styles.layout}>
                <Header />
                    <div className={styles.content}>{props.children}</div>
                <Footer />
            </div>
        )
    } else {
        /* Has container (aka padding) on children */
        return (
            <div className={styles.layout}>
                <Header />
                <div className="container">
                    <div className={styles.content}>{props.children}</div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Layout