/* <Screenshots /> */

/* Core */
import { useEffect, useState } from "react"

/* Router */
import { Link } from "react-router-dom"

/* Data */
import chroma from "@chromaplatform/chromajs"

/* Components */
import CircularProgress from '@material-ui/core/CircularProgress'

/* Media */
import styles from './Screenshots.module.css'

const _ = require('lodash')

function Screenshots(props) {
    const [screenshots, setScreenshots] = useState([])

    // useEffect(() => {
    //     const getScreenshotData = async () => {
    //         const screenshotQuery = {
    //             service: "assets",
    //             query: {userId: props.userId, type: "image", subtype: ["screenshot"], $limit: 18,},
    //             fields: "_id meta"
    //         }
        
    //         chroma.find(screenshotQuery, true).then(data => {
    //             if(data.length > 0){
    //                 const SCREENSHOT_ROW_AMOUNT = 3
    //                 const splitScreenshots = _.chunk(data, SCREENSHOT_ROW_AMOUNT)
    //                 setScreenshots(splitScreenshots)
    //             }
    //         })
    //     }

    //     getScreenshotData()
    //     return () => {}
    // }, [setScreenshots])

    return(
        <div>
            {screenshots.length === 0 &&
                <h3 style={{textAlign:"center", margin: "32px 0 32px 0", opacity: .4}}>
                    {/* <FontAwesomeIcon icon={faAddressCard} style={{marginRight: 12, opacity: .4}} /> */}
                    {/* User has not uploaded any screenshots yet */}
                    Screenshot uploading coming soon
                </h3>
            }
            
            {/* Screenshot Row */}
            <div className="row">
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Screenshot Row */}
            <div className="row">
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    
                </div>
            </div>
        </div>
    )
}

export default Screenshots