/* Core */
import _ from 'lodash'

import Button from 'react-bootstrap/Button';

/* Media */
import styles from './asset-nft.module.css'

const AssetNFTListItem = (props) => {
    const { item } = props

    const name = _.get(item, "meta.name")
    const link = _.get(item, "meta.link")
    const image = _.get(item, "meta.image")
    const tokenId = _.get(item, "tokenId")
    const associatedProducts = _.get(item, "meta.associatedProducts")
    const subtype = _.get(item, "subtype")

    return (
        <div {...props} className={styles.listItemContainer}>
            {image &&
                <img src={image} className={styles.thumbnail} />
            }
            <span className={`${styles.type} badge rounded-pill text-bg-light`}>NFT</span>
            
            
            <h2 className={styles.name}>{name}</h2>
            {tokenId &&
                <h3 className={styles.tokenId}>{tokenId}</h3>
            }

            <h3 className={styles.associatedCount}>
                <strong>{_.get(associatedProducts, "length", 0)}</strong> products associated
            </h3>
            
            <a href={link} target="_blank" className={styles.button}>
                <button className={`primary`}>
                    View
                </button>
            </a>
        </div>
    )
}

export default AssetNFTListItem