/* <ProductsList /> */

import { useEffect, useState } from "react"

import { useStores } from "../../context/RootStoreContext"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './SeedcodesList.module.css'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')

let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"

function SeedcodesList(props) {
    const rootStore = useStores()

    /* Local State */
    const [seedcodes, setSeedcodes] = useState([])

    /* Get Seedcode data */
    useEffect(() => {
        let supplytokens = []

        const getUserSeedcodes = async () => {
            const query = {
                service: "supply/token",
                query: { userId: rootStore.AuthStore.userId },
                fields: "_id createdAt supplycodeId"
            }
    
            chroma.find(query, true).then(data => {
                // Store tokens
                supplytokens = data

                getUserSupplyCodes()
            })
        }

        const getUserSupplyCodes = async () => {
            let supplycodes = []

            for (let i = 0; i < supplytokens.length; i++) {
                const token = supplytokens[i]
               
                // Get supplycode info
                const query = {
                    service: "supply/code",
                    id: token.supplycodeId,
                    fields: "code type totalTokenSupply meta tokensGenerated"
                }
        
                let supplycode
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
                }

                const chroma_query_endpoint = chroma_endpoint + '/query/get'
                await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
                    .then(function (response) {
                        supplycode = _.get(response, "data", {})
                       
                    })
                    .catch(function (error) {
                        console.log("CHROMA > Error Getting products", _.get(error, "message"))
                    })

                // Get Product info
                const productQuery = {
                    service: "products",
                    id: _.get(supplycode, "meta.products[0]"),
                    fields: "type appId meta copy"
                }
                // let product = await chroma.get(productQuery)
    
                await axios.post(chroma_query_endpoint, productQuery, {headers: headers, timeout: 0})
                    .then(function (response) {
                        const product = _.get(response, "data", {})
                        // Add product info to supplycode data
                        supplycode.product = product
        
                        // Add createdAt to code (multiple by 1000 to get ms, stored in DB as seconds)
                        supplycode.date = moment(supplytokens[i].createdAt * 1000 || null).format("MM/DD/YYYY")
                        
        
                        supplycodes.push(supplycode)
                    })
                    .catch(function (error) {
                        console.log("CHROMA > Error Getting products", _.get(error, "message"))
                    })

            }
  
            setSeedcodes(supplycodes)
        }

        getUserSeedcodes()
    }, [chroma, rootStore, setSeedcodes])

    const getSeedcodeUI = (seedcode, i) => {
        return (
            <div key={i} className={`${styles.Seedcode} container`}>
                <div className="row">
                    <div className={`${styles.Code} col-4`}>
                        <FontAwesomeIcon icon={faQrcode} className={styles.QrIcon} />
                        {seedcode.code}
                    </div>
                    <div className="col-5">
                        {_.get(seedcode, "product.copy.en.title")}
                    </div>
                    <div className={`${styles.Date} col-3`}>
                        {_.get(seedcode, "date")}
                    </div>
                </div>
            </div>
        )
    }

    // if(_.get(seedcodes, "length") < 1)
	// 	return(<div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>)
    
    return(
        <div className={styles.Seedcodes}>
            {seedcodes.map((item, i) => {
                return getSeedcodeUI(item, i)
            })}
            {seedcodes.length < 1 ? <p className={styles.Empty}>You do not have any seedcodes</p> : ""}
        </div>
    )
}

export default SeedcodesList
