/* <NewsletterForm /> */

/* Media */
import styles from './NewsletterForm.module.css'

const MAILCHIMP_URL = 'https://visionagency.us14.list-manage.com/subscribe/post?u=3ea551d32e608cf3454919b7e&amp;id=521b2f0239'

function NewsletterForm(props) {
    return(
        <div className={`${styles.MailchimpSubscribe}`}>
            <div class="klaviyo-form-VWHPCD"></div>
            {/* <MailchimpSubscribe url={MAILCHIMP_URL} /> */}
            {/* <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css"> */}
            
            {/*<div id="mc_embed_signup">
                <form action="https://visionagency.us14.list-manage.com/subscribe/post?u=3ea551d32e608cf3454919b7e&amp;id=521b2f0239" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll">
                        <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                        <div aria-hidden="true" style={{display: "none"}}><input type="text" name="b_3ea551d32e608cf3454919b7e_521b2f0239" tabIndex="-1" defaultValue="" /></div>
                        <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    </div>
                </form>
    </div>*/}

        </div>
    )
}

export default NewsletterForm