/* Nav User Dropdown */

/* Data */
import { useStores } from "../../context/RootStoreContext"
import { observer } from "mobx-react"

/* Router */
import { Link, useNavigate } from "react-router-dom"

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faCoins, faMoon, faPaintBrush, faPowerOff, faSun, faTint, faUser } from '@fortawesome/free-solid-svg-icons'

import { Avatar } from 'antd'


/* Media */
import styles from './NavUserDropdown.module.css'



function NavUserDropdown(props) {
    const rootStore = useStores()
    let history = useNavigate()

    /* Click Events */
    const logout = () => {
        rootStore.AuthStore.logout()
        history({ pathname: "/" }) // Send them to /
    }

    const toggleTheme = () => {
        const newTheme = (rootStore.SettingsStore.theme === "light") ? "dark" : "light"
        rootStore.SettingsStore.changeTheme(newTheme)
    }

    const getThemeIcon = () => {
      const themeIcon = (rootStore.SettingsStore.theme === "light") ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />
      return themeIcon
    }

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {rootStore.AuthStore.user.username}
                {/* <div className={styles.avatarContainer}>
                    <div className={styles.badgeContainer}>
                        <span className="badge badge-pill">3</span>
                    </div>
                    <Avatar className={styles.avatar} shape="circle" size="large" icon={<FontAwesomeIcon icon={faUser} />} />
                </div> */}
            </a>
            <ul style={{left: "-20px"}} className={`${styles.DropDown} dropdown-menu dropdown-menu-end`} aria-labelledby="navbarDropdown">
                <li>
                    <Link to="/dashboard" className="dropdown-item">
                        <FontAwesomeIcon icon={faTint} />
                        Dashboard
                    </Link> 
                </li>
                <li>
                    <Link to={`/${rootStore.AuthStore.user.username}`} className="dropdown-item">
                        <FontAwesomeIcon icon={faUser} />
                        Profile
                    </Link> 
                </li>
                <li>
                    <Link to="/nfts" className="dropdown-item">
                        <FontAwesomeIcon icon={faPaintBrush} />
                        NFTs
                    </Link> 
                </li>

                <li>
                        <Link to="/settings" className="dropdown-item">
                            <FontAwesomeIcon icon={faCog} />
                            Settings
                        </Link> 
                    </li>

                {rootStore.AuthStore.user.hasRole('appDeveloper') &&
                    <li>
                        <Link to="/admin" className="dropdown-item">
                            <FontAwesomeIcon icon={faCog} />
                            Admin
                        </Link> 
                    </li>
                }
                {/* <li>
                    <a className="dropdown-item" href="#" onClick={toggleTheme}>
                        {getThemeIcon()}
                        Theme
                    </a>    
                </li> */}
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a className="dropdown-item" href="#" onClick={logout}>
                        <FontAwesomeIcon icon={faPowerOff} />
                        Logout
                    </a>
                </li>
            </ul>
        </li>
    )
}

export default observer(NavUserDropdown)