import { useEffect, useState } from 'react'
import { props } from 'data/structure/BaseModel'
import _ from 'lodash'
import { LineChart, Line, ResponsiveContainer } from 'recharts'

/* Media */
import styles from './UserAmountChart.module.css'
import moment from 'moment'
import axios from 'axios'
import SimpleLineChart from './SimpleLineChart'


export default function UserAmountChart(props) {
    const [userChartData, setUserChartData] = useState([])

    // GET Users Chart Data
	useEffect(() => {
		const getUserChartData = async () => {
            // date1 is the more recent of the dates (date2 must be in the past)
            const userDateConfig = [
                {
                    title: 'this week',
                    date1: moment().unix(), // today
                    date2: moment().subtract(60 * 24 * 7, 'minutes').unix() // last week
                },
                {
                    title: '1 week ago',
                    date1: moment().subtract(60 * 24 * 7, 'minutes').unix(), // 1 week ago
                    date2: moment().subtract(60 * 24  * 7* 2, 'minutes').unix() // 2 weeks ago
                },
                {
                    title: '2 weeks ago',
                    date1: moment().subtract(60 * 24 * 7 * 2, 'minutes').unix(), // 2 week ago
                    date2: moment().subtract(60 * 24 * 7 * 3, 'minutes').unix() // 3 weeks ago
                },
                {
                    title: '3 weeks ago',
                    date1: moment().subtract(60 * 24 * 7 * 3, 'minutes').unix(), // 3 week ago
                    date2: moment().subtract(60 * 24 * 7 * 4, 'minutes').unix() // 4 weeks ago
                }
            ]

            let userData = []
            for (let i = 0; i < userDateConfig.length; i++) {
                const dateConfig = userDateConfig[i];
                const dateDataRequest = await getUserDataBetweenDates(dateConfig.date2, dateConfig.date1)
                const dateData = {
                    title: dateConfig.title,
                    amount: dateDataRequest
                }
                userData.unshift(dateData)
            }

            setUserChartData(userData)
		}

        const getUserDataBetweenDates = async (startDate, endDate) => {
            // Last Day
			const today = moment().subtract(60 * 24, 'minutes').unix()
			const query = {
                service: "users",
				query: {createdAt: {$gt: startDate, $lt: endDate}},
				fields: "username createdAt"
			}	
            
            let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"


            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }

            const chroma_query_endpoint = chroma_endpoint + '/query/find'
            const userRequest = await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
            const userAmount = _.get(userRequest, 'data.length', 0)
            
            return userAmount

        }

		getUserChartData()

	}, [setUserChartData])

    return (
        <SimpleLineChart data={userChartData} className={`${styles.LineChart} ${_.get(props, "className")}`} />
    )
}