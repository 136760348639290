import { useState } from 'react'

import { CardElement, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'

/* Media */
import styles from './CardMinimal.module.css'

const CardMinimal = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  // console.log('cardMin props.clientSecret', props.clientSecret)

  const [currency, setCurrency] = useState("")
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    // Disable button
    setDisabled(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardCvcElement = elements.getElement(CardCvcElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)

    // (Legacy) Use your card Element with other Stripe.js APIs
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardNumberElement,
    // })
    // if (error) {
    //   console.log('[error]', error)
    // } else {
    //   console.log('[PaymentMethod]', paymentMethod)
    // }

    /* Confirm Card Payment */

    const { error, paymentMethod } = await stripe.confirmCardPayment(`${props.clientSecret}`, {
      payment_method: {
        card: cardNumberElement,
        // billing_details: {
        //   name: 'Jenny Rosen',
        // },
      },
    })
    if (error) {
      console.log('[error]', error)
      props.error()
      setDisabled(false)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      props.success()
    }

  }

  const cardOptions = {style: {base: {fontSize: "14px"}}}

  return (
    <form onSubmit={handleSubmit}>
      <div class="row justify-content-around">
        <div className={`${styles.CardNumber} col-12 col-md-6`}>
          <CardNumberElement options={cardOptions} />
        </div>
        <div className={`${styles.CardExpiry} col-12 col-md-2`}>
          <CardExpiryElement options={cardOptions} />
        </div>
        <div className={`${styles.CardCvc} col-12 col-md-2`}>
          <CardCvcElement options={cardOptions} />
        </div>
      </div>
      {/* <CardElement options={{style: {base: {fontSize: "24px", backgroundColor: "#ccc", margin: "12px"}}}} /> */}
      <button type="submit" className={`${styles.PayButton} primary`} disabled={!stripe || disabled}>
        Pay
      </button>
    </form>
  )
}

export default CardMinimal