/* Base Model */



/* Framework */
import { applySnapshot, getParent, hasParent, types } from 'mobx-state-tree'


/* Properties */
export const props = {
    id: types.optional(types.string, ""), // TODO:: Figure out types.identifier
    namespace: types.optional(types.string, "default"),
    address: types.optional(types.string, ""),
    canUpdateDatabase: types.optional(types.boolean, false) // by default you cannot dehydrate to db
}

/* Views */
// Computed data that will be displayed in a view
export const views = self => ({
    // Returns the entire model's data as JSON
    get getData() {
        return self.toJSON()
    },

    // Calculates the current nodes position in the store heiarchy
    get implicitAddress() {
        let implicitAddress = calculateImplicitAddress(self)

        return implicitAddress
    }
})

/* Actions */
// Manipulate the models data
export const actions = self => ({
    hydrate() {
        // Fetch current address from server and hydrate this model
        // console.log('> Hydrate Data from DB address', self.implicitAddress)
    },

    dehydrate() {
        // Dehydrate this model's data and store it at its address on the server
        // console.log('> Dehydrate Data To DB address', self.implicitAddress)
    },

    reset() {
        // Resets the model to default state
        applySnapshot(self, {})
    }
})




/* Helper Functions */
const calculateImplicitAddress = (currentNode) => {
    let address
    let namespace = currentNode.namespace
    
    if(hasParent(currentNode)){
        address = `${namespace}/${getParent(currentNode).implicitAddress}`
    } else {
        address = namespace
    }

    return address
}

// const getParentNamespace = (currentNode) => {
//     if (hasParent(currentNode)) {
//         return getParent(currentNode).namespace
//     } else {
//         return false
//     }
// }