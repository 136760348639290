/* Header */

/* Components */
import { useStores } from "context/RootStoreContext"

import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faRocket, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './no-products.module.css'

import _ from 'lodash'

function NoProducts(props) {
    const rootStore = useStores()

    return (
        <div className={`${styles.container} ${props.className}`}>
            <h2 className={styles.header}>
                <FontAwesomeIcon icon={faUserAstronaut} className={styles.icon} />
                No products found.
            </h2>
            <p>
                Microdose VR is now available for purchase.
            </p>
            <Link to="/products">
                <button className={`${styles.linkWalletButton} primary`}>
                    <FontAwesomeIcon icon={faRocket} className={styles.icon} />
                    Buy Now
                </button>
            </Link>
        </div>
    )
}

export default NoProducts