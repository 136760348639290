/* Header */

/* Components */
import { useStores } from "context/RootStoreContext"
import { observer } from "mobx-react"

import { Avatar, Image } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faSeedling, faUser } from '@fortawesome/free-solid-svg-icons'

import _ from "lodash"

/* Media */
import styles from './users-info.module.css'
import eyePurple from '../../media/images/eye-purple.png'

function UsersInfo(props) {
    const rootStore = useStores()
    const username = _.get(rootStore, "AuthStore.user.username")
    const avatarURL = _.get(rootStore, "AuthStore.user.meta.profile.avatarURL")
    const userId = _.get(rootStore, "AuthStore.userId")
    const joinDate = _.get(rootStore, "AuthStore.user.created")
    const origin = _.get(rootStore, "AuthStore.user.meta.origin", []).toJSON()
    const seedcode = _.get(origin, "seedcode")
    const minutesPlayed = _.get(rootStore, "AuthStore.user.meta.appData.MDVR.sessions.minutesPlayed", 0)

    return (
        <div>
            <div className={`${styles.container} ${props.className}`}>
                {/* Avatar */}
                <div className={styles.avatarContainer}>
                    <img src={avatarURL} className={styles.avatar} />
                </div>
                
                <h3 className={styles.username}>{username}</h3>
                <p className={styles.userid}>{userId}</p>
            </div>
            <ul className={styles.Data}>
                {minutesPlayed > 0 && 
                    <li>
                        <FontAwesomeIcon icon={faClock} className={styles.joinedIcon} />  Time Played <strong>{(Math.ceil((minutesPlayed) / 60)) || 0} hours</strong>
                    </li>
                }
                <li>
                    <FontAwesomeIcon icon={faCalendar} className={styles.joinedIcon} /> Member Since <strong>{joinDate}</strong>
                </li>

                {/* Seedcode */}
                {seedcode && 
                    <li>
                        <FontAwesomeIcon icon={faSeedling} className={styles.joinedIcon} /> Seedcode <strong>{seedcode}</strong>
                    </li>
                }
            </ul>
        </div>
    )
}

export default observer(UsersInfo)