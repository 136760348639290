/* UserAssetModel */

import { types } from 'mobx-state-tree'

/* Model Properties */
const props = {
    appId: types.optional(types.string, ""),
    assetId: types.optional(types.string, ""),
    type: types.optional(types.string, ""),
    source: types.optional(types.string, ""),
    image: types.optional(types.string, ""),
    thumbnail: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    contractAddress: types.optional(types.string, ""),
    contractName: types.optional(types.string, ""),
    tokenId: types.optional(types.string, ""),
    permalink: types.optional(types.string, ""),
}

/* Model Actions */
const actions = (self) => ({})

const UserAssetModel = types
  .model('UserAssetModel')
  .props(props)
  .actions(actions)

  export default UserAssetModel