/* Dashboard */

/* Core */
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../context/RootStoreContext"
import chroma from '@chromaplatform/chromajs'

/* Components */
import Layout from "../components/layout/Layout"
import Steps from 'components/data/Steps'
import CopyableText from 'components/utilities/CopyableText'

import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'

import { useToasts } from 'react-toast-notifications'

/* Accordian */
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button } from '@material-ui/core'

/* Icons */
import { Icon } from '@iconify/react'
import btcIcon from '@iconify/icons-cryptocurrency/btc'
import ethIcon from '@iconify/icons-cryptocurrency/eth'
import xmrIcon from '@iconify/icons-cryptocurrency/xmr'
import usdtIcon from '@iconify/icons-cryptocurrency/usdt'
import ltcIcon from '@iconify/icons-cryptocurrency/ltc'
import trxIcon from '@iconify/icons-cryptocurrency/trx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faUserAstronaut, faTerminal, faTasks, faShoppingCart, faTimes, faFileInvoiceDollar, faCheck, faBomb, faFlagCheckered, faSkull, faReceipt, faLink } from '@fortawesome/free-solid-svg-icons'

import Alert from '@material-ui/lab/Alert'

/* Credit Cards */
import CreditCardForm from '../components/payment/CreditCardForm'


/* Media */
import styles from './Purchase.module.css'
import qrCodePlaceholder from '../media/images/misc/qr-code-placeholder.png'
import analyticEvent from 'CHROMA-React/code/_analytics'

const _ = require('lodash')
const axios = require('axios')

/* For polling & requests */
let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"
// let chroma_endpoint = "http://localhost:3030"
let chroma_tx_endpoint =  chroma_endpoint + "/transactions"





/* Polling */
let pollingInterval
let pollCounter = 0
const POLL_INTERVAL = 5000 // 5 Seconds
const MAX_POLL_COUNT = 720 // 1 hour (at 5s above)

const moment = require('moment')


function Purchase(props) {
    const rootStore = useStores()
    const history = useNavigate()

    const { addToast } = useToasts()

    

    /* Steps */
    const CHECKOUT_STEPS = ["1 - Select Currency", "2 - Make Transaction", "3 - Confirmation", "4 - Complete"]
    const CHECKOUT_STEPS_NO_NUMBERS = ["Select Currency", "Make Transaction", "Confirmation", "Complete"]
    const [currentStep, setCurrentStep] = useState(0)

    /* Transaction */
    const [transactionId, setTransactionId] = useState('')
    const [complete, setComplete] = useState(false)

    /* Currency */
    const [currency, setCurrency] = useState("")
    const [amount, setAmount] = useState(0)
    const [amountRecieved, setAmountRecieved] = useState(0)
    const [percentRecieved, setPercentRecieved] = useState(0)
    const [btcAmount, setBtcAmount] = useState("0.002")

    /* Wallet Address */
    const [address, setAddress] = useState('')
    const [requestGenerated, setRequestGenerated] = useState(false)

    /* Fiat */
    const [stripeClientSecret, setStripeClientSecret] = useState('')
    const [ccError, setCCError] = useState(false)

    /* Confirmation */
    const [logs, setLogs] = useState([])
    const [txStarted, setTxStarted] = useState(false)
    const [txConfirmed, setTxConfirmed] = useState(false)
    const [txFailed, setTxFailed] = useState(false) 
    const [failMessage, setFailMessage] = useState("There was a problem with the transaction")     

    /* Products */
    const [products, setProducts] = useState([])
    const storeProducts = rootStore.Cart.products

    const [enabled, setEnabled] = useState(false)

    /* Get Product(s) info */
    // const overrideProducts = _.get(props, "location.state.products")
    // if(overrideProducts){
    //     rootStore.Cart.setProducts(overrideProducts)
    // }

    useEffect(() => {
        const getChromaInfo = async () => {
            const query = {
                service: "chroma-core",
                id: "5f0c9087c1326f0bab571e62",
                fields: "value"
            }

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }
    
            // const chromaQuery = await chroma.get(query, true)
            const chroma_query_endpoint = chroma_endpoint + '/query/get'
                await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
                    .then(function (response) {
                        const data = _.get(response, "data")
                        // console.log('DATA RESPONSE core', data)
                        setEnabled(_.get(data, "value.transactions"))
                    })
                    .catch(function (error) {
                        console.log("CHROMA > Error Getting chroma-core", _.get(error, "message"))
                    })
            
            // if(chromaQuery){
            //     setEnabled(_.get(chromaQuery, "value.transactions"))
            //     console.log("chromaQuery", _.get(chromaQuery, "value.transactions"))
            // }          
            
        }

        getChromaInfo()
    }, [setEnabled])

    useEffect(() => {
        const getProductsInfo = async () => {
            // Must have product
            if(rootStore.Cart.products.length < 1){
                if(complete){
                    history(`/dashboard`)
                } else {
                    history(`/products`)
                }
            }
            
            
            let incomingProducts = []

            for (let i = 0; i < rootStore.Cart.products.length; i++) {
                const productId = rootStore.Cart.products[i]
                
                const query = {
                    service: "products",
                    id: productId,
                    fields: "_id slug type meta copy cost"
                }
        
                //  temp disable to debug token not being sent
                // const productsQuery = await chroma.get(query, true)
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
                }
                const chroma_query_endpoint = chroma_endpoint + '/query/get'
                await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
                    .then(function (response) {
                        const data = _.get(response, "data")
                        incomingProducts.push(data)
                        // console.log('DATA RESPONSE', data)
                    })
                    .catch(function (error) {
                        console.log("CHROMA > Error Getting products", _.get(error, "message"))
                    })


                
                // if(productsQuery){
                //     // incomingProducts.push(productsQuery)
                // }

            }

            // Tally total btc cost
            const btcTotal = incomingProducts.map((el) => _.get(el, "cost.btc")).reduce((a, b) => a + parseFloat(b), 0)
            setBtcAmount(btcTotal)
            
            setProducts(incomingProducts)
            
            
        }

        getProductsInfo()

        return () => {
            stopListeningForTransactionUpdates()
        }
    }, [rootStore.Cart.products.length, history, rootStore.Cart.products])
    




    const removeProduct = (productId) => {
        rootStore.Cart.removeProduct(productId)
        gotoStep(1)
    }






    /* Sequence for checkout */

    const gotoStep = (step) => {
        setCurrentStep(step - 1)

        switch(step){
            default:
                break
            case 1:
                // console.log("CHANGE CURRENCY")
                stopListeningForTransactionUpdates()
                setRequestGenerated(false)      
                break
            case 2:
                if(currency !== "cc"){
                    // Crypto, generate request
                    generateRequestCrypto()
                } else {
                    // USD do not generate request
                    generateRequestFiat()
                }
                break
            case 3:
                // console.log("POLL TRANSACTION UNTIL STATUS = COMPLETE")
                // Should be already polling
                break
            case 4:
                /* Notification */
                const notificationConfig = { appearance: 'success' }
                addToast("Transaction has completed", notificationConfig)
        }
    }

    const generateRequestFiat = async (e) => {
        // Disable more clicks
        setRequestGenerated(false)

        const tx_query = {
            medium: "usd", // change to variable
            inMetaType: "paymentGateway",
            inMetaId: "stripe",
            outMetaType: "product",
            outMetaIds: rootStore.Cart.products || [],
            // affiliateId: rootStore.affiliateId || ''
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
        }
        await axios.post(chroma_tx_endpoint, tx_query, {headers: headers, timeout: 0})
            .then(function (response) {
                const data = _.get(response, "data")
                // console.log('paymentIntent data', data)
                setStripeClientSecret(_.get(data, "clientSecret"))

                // console.log('clientSecret', _.get(data, "clientSecret"))
                // const address = _.get(data, "inMeta.address")
                // const transactionId = _.get(data, "_id")
                const amount = _.get(data, "amountUsd")
                // console.log('txID1: ', transactionId)
                // setTransactionId(transactionId)
                // setRequestGenerated(true)
                // setAddress(address)
                setAmount(parseFloat(amount))
                
                // Start listening for updates
                // setTimeout(() => {
                //     listenForTransactionUpdates(transactionId)
                // }, 400)
            })
            .catch(function (error) {
                console.log("CHROMA > Error Generating Transaction", _.get(error, "message"))

                /* Notification */
                const notificationConfig = { appearance: 'warning' }
                addToast("Please verify your account and try again", notificationConfig)

                gotoStep(1)
            })
    }


    /* Creates Wallet and Transaction on chroma api */
    const generateRequestCrypto = async (e) => {
        // Disable more clicks
        setAddress('')
        setRequestGenerated(false)
        
        const tx_query = {
            medium: currency,
            inMetaType: "paymentGateway",
            inMetaId: "coinpayments",
            outMetaType: "product",
            outMetaIds: rootStore.Cart.products || []
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
        }
        await axios.post(chroma_tx_endpoint, tx_query, {headers: headers, timeout: 0})
            .then(function (response) {
                const data = _.get(response, "data")
                const address = _.get(data, "inMeta.address")
                const transactionId = _.get(data, "_id")
                const amount = _.get(data, "amount")
                // console.log('amount: ', amount)
                setTransactionId(transactionId)
                setRequestGenerated(true)
                setAddress(address)
                setAmount(parseFloat(amount))
                
                // Start listening for updates
                setTimeout(() => {
                    listenForTransactionUpdates(transactionId)
                }, 400)
            })
            .catch(function (error) {
                console.log("CHROMA > Error Generating Transaction", _.get(error, "message"))

                /* Notification */
                const notificationConfig = { appearance: 'warning' }
                addToast("Please verify your account and try again", notificationConfig)

                gotoStep(1)
            })
    }

    /* Gets currently selected currencies Icon */
    const getCurrencyIcon = () => {
        switch(currency){
            default:
                return null
            case "btc":
                return <Icon icon={btcIcon} className={`${styles.CurrencyIconPayment}`} />
            case "btc.ln":
                return <Icon icon={btcIcon} className={`${styles.CurrencyIconPayment}`} />
            case "usdt":
                return <Icon icon={usdtIcon} className={`${styles.CurrencyIconPayment}`} />
            case "xmr":
                    return <Icon icon={xmrIcon} className={`${styles.CurrencyIconPayment}`} />
            case "eth":
                return <Icon icon={ethIcon} className={`${styles.CurrencyIconPayment}`} />
            case "trx":
                    return <Icon icon={trxIcon} className={`${styles.CurrencyIconPayment}`} />
            case "ltct":
                return <Icon icon={ltcIcon} className={`${styles.CurrencyIconPayment}`} />
        }
    }

    const getCoinFullName = () => {
        switch(currency){
            default:
                return "INVALID"
            case "btc":
                return "bitcoin"
            case "eth":
                return "ethereum"
            case "ltct":
                return "litecoin"
            case "trx":
                return "tron"
            case "btc.ln":
                return "bitcoinlightning"
            case "xmr":
                return "monero"
            case "usdt":
                return "tether"
        }
    }

    /* Get QR Code URL per currency */
    const getQrCodeImg = () => {
        // console.log("currency", currency)
        switch(currency) {
            default:
                return null
            case "trx":
                return <img src={qrCodePlaceholder} alt="QR" />
            case "ltc":
                    return <img src={qrCodePlaceholder} alt="QR" />
            case "usdt":
                return <img src={qrCodePlaceholder} alt="QR" />
            case "btc":
                const btc_url = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=bitcoin:${address}?amount=${amount}`
                return <img src={btc_url} alt="QR" />
            case "btc.ln":
                const btcln_url = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=bitcoin:${address}?amount=${amount}`
                return <img src={btcln_url} alt="QR" />
            case "eth":
                /* Convert ETH amount to Wei (1 ETH = 1,000,000,000,000,000,000 Wei) */
                // const wei_amount = amount * 10e17
                const eth_url = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=ethereum:${address}?amount=${amount}`
                return <img src={eth_url} alt="QR" />
            case "xmr":
                const xmr_url = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=monero:${address}?tx_amount=${amount}`
                return <img src={xmr_url} alt="QR" />
        }
    }

    /* Creates QR Code from address */
    const getQrCode = () => {
        if(!requestGenerated) {
            return(
                <div>
                    <div className={styles.QRCode}>
                        <img src={qrCodePlaceholder} alt="QR" />
                    </div>
                </div>
            )
        }
        return(
            <div>
                <div className={styles.QRCode}>
                    {getQrCodeImg()}
                </div>
                <p className={`${styles.AddressButton} btn btn-primary`}>
                    <CopyableText text={address} position="bottom" color="#333" background="#fff" />
                </p>
            </div>
        )
    }

    /* Listen for updates to transaction */
    const stopListeningForTransactionUpdates = () => {
        clearInterval(pollingInterval)
    }

    const listenForTransactionUpdates = async (transactionId) => {
        // Only loop if we have transactionId
        if(transactionId){
            // Trigger instantly to start
            listenUpdateMethod()

            // Start polling loop
            pollingInterval = setInterval(() => {listenUpdateMethod(transactionId)}, POLL_INTERVAL)

        }
    }

    const listenUpdateMethod = async (transactionId) => {
        // Only allow X amount of polling updates
        pollCounter++

        if(pollCounter > MAX_POLL_COUNT){
            stopListeningForTransactionUpdates()
        }

        // We need transactionId
        if(!transactionId){
            return null
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
        }
        await axios.get(`${chroma_tx_endpoint}/${transactionId}`, {
            headers: headers, 
            timeout: 0
        })
        .then(function (response) {
            
            const data = _.get(response, "data[0].data[0]") || _.get(response, "data")
            const status = _.get(data, "status")
            const logs = _.get(data, "inMeta.log")
            setLogs(logs)

            const incomingAmount = _.get(data, "amount")
            const incomingRecieved = _.get(data, "inMeta.totalRecieved")

            let percent = ((incomingRecieved/incomingAmount) * 100)
            if(percent > 100) percent = 100
            setPercentRecieved(percent)
            
            setAmount(incomingAmount)
            setAmountRecieved(incomingRecieved)

            switch(status) {
                default:
                    break
                case "confirming":
                    if(!txStarted){
                        setTxStarted(true)
                        gotoStep(3)
                    }
                    break
                case "complete":
                    // Complete, send to /tx/ page
                    setTimeout(() => {
                        // Clear Cart
                        rootStore.Cart.clearCart()
                        history(`/dashboard`)

                        // TODO :: Turn this on and test purchase
                        // history({
                        //     pathname:"/dashboard",
                        //     state: { message: "Thank you for purchasing Microdose" || "" }
                        // })
                        
                    }, 100)

                    // console.log('transaction complete')
                    stopListeningForTransactionUpdates()
                    setTxConfirmed(true)
                    gotoStep(4)

                    
                    
                    break
                case "failed":
                    stopListeningForTransactionUpdates()

                    const logs = _.get(data, "inMeta.log")
                    const fail_message = _.get(logs[logs.length-1], "inMeta.log.status_message")
                    setFailMessage(fail_message || `We could not complete your transaction`)

                    /* Notification */
                    const notificationConfig = { appearance: 'error' }
                    addToast("There was an error completing your transaction", notificationConfig)

                    setTxFailed(true)
                    gotoStep(3)
                    break
            }
        })
        .catch(function (error) {
            setTxFailed(true)
            setFailMessage("API Issue")
            console.log(error)
        })
    }

    /* Select Currency */
    const selectCurrency = (currency) => {
        // Save currency
        setCurrency(currency)
    }


    /* Credit Card Feedback */
    const ccErrorCallback = (e) => {
        console.log('CC Error!')
        setCCError(true)
    }

    const ccSuccessCallback = (e) => {
        console.log("CC Success!")
        analyticEvent("Purchase_Complete", {})
        setCCError(false)
        gotoStep(4)

        setTimeout(() => {
            // Clear Cart
            setComplete(true)
            rootStore.Cart.clearCart()
            
            history.push(`/dashboard`) // TODO :: Make sure this is working
        }, 1000)
    }

    return (
        <Layout noContainer="true">
            <Helmet>
                <title>CHROMA | Checkout</title>
            </Helmet>
            <div className={styles.PurchaseContainer}>
                <div className={`${styles.ProductInfo} container`} >

                    {/* Title */}
                    <h2 className={styles.ProductHeadline}>
                        Check Out
                    </h2>

                    {/* Steps */}
                    <div className={styles.Steps}>
                        <Steps step={currentStep} steps={CHECKOUT_STEPS_NO_NUMBERS} />
                    </div>

                    {!enabled &&
                        <div className={styles.AlphaAlert}>
                            <FontAwesomeIcon className={styles.WarningIcon} icon={faSkull} />
                            Purchasing products is <em>temporarily</em> disabled.
                        </div>
                    }
                    
                    {/* Content */}
                    <div className="row">
                        {/* Left Column Steps */}

                        <div className="col-lg-9 col-md-12">
                            {/* Products */}
                            <div className={styles.ProductsContainer} dataproducts={storeProducts.length}>
                                <h3 className={styles.ProductsHeadline}>
                                    <FontAwesomeIcon className={styles.ProductIcon} icon={faShoppingCart} />
                                    ({rootStore.Cart.products.length}) Product{(rootStore.Cart.products.length > 1) ? "s " : " "}
                                    
                                    {/* Total Price */}
                                    {/* TEMP HIDDEN */}
                                    {/* <span className={styles.TotalPrice} style={(amount > 0 ? {display: 'none'} : "")}>
                                            Total:
                                            <span>{amount} btc</span>                                    
                                    </span> */}
                                
                                </h3>
                                {products.map((product, i) => {
                                    return (
                                        <div className={`${styles.Product} ${txConfirmed ? styles.ProductComplete: ""}`} key={i}>
                                            {/* Show Remove X when not complete */}
                                            {!txConfirmed && 
                                                <button onClick={() => {removeProduct(product._id)}} className={styles.RemoveButton}><FontAwesomeIcon className={styles.RemoveProductIcon} icon={faTimes} /></button>
                                            }

                                            {/* Show Checkmark if complete */}
                                            {txConfirmed && 
                                                <FontAwesomeIcon className={styles.CompleteProductIcon} icon={faCheck} />
                                            }
                                            
                                            <span className={styles.ProductAmount}>${_.get(product, "cost.usd")}</span>
                                            <h4 className={styles.ProductTitle}>{_.get(product, "copy.en.title")}</h4>
                                            <p className={styles.ProductData}>{_.get(product, "copy.en.description")}</p>
                                        </div>
                                    )
                                })}
                            </div>

                            {/* Steps Content */}
                            {enabled &&
                                <div className={styles.StepsContainer}>

                                    <h3 className={styles.ProductsHeadline}>
                                        <FontAwesomeIcon className={styles.ProductIcon} icon={faFileInvoiceDollar} />
                                        Payment
                                    </h3>
                                    
                                    {/* Step 1 - Currency */}
                                    <Accordion className={styles.StepRoot} expanded={currentStep === 0}>
                                        {/* Summary */}
                                        <AccordionSummary
                                            className={styles.StepHeader}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <h3 className={styles.StepTitle}>{CHECKOUT_STEPS[0]}</h3>
                                        </AccordionSummary>
                                        {/* Details */}
                                        <AccordionDetails className={styles.StepDetails}>
                                            {/*!rootStore.AuthStore.user.verified &&
                                                <div className={styles.VerificationAlert}>
                                                    <FontAwesomeIcon className={styles.WarningIcon} icon={faSkull} />
                                                    You must verify your CHROMA account email and re-login before you can make a transaction.
                                                </div>
                                            */}

                                            {/* {!rootStore.AuthStore.user.hasProduct('5f19cca42f959c16ce17592d') &&
                                                <div className={styles.VerificationAlert}>
                                                    <FontAwesomeIcon className={styles.WarningIcon} icon={faSkull} />
                                                    Purchasing products is temporarily restricted to our Alpha test group members.
                                                </div>
                                            } */}
                                            
                                            <p>
                                                Which currency would you like to pay with?
                                                <br />
                                                <span style={{fontSize: '16px', color: 'rgba(0,0,0,0.4)'}}>(Currently only credit cards accepted)</span>
                                            </p>

                                            {/* Select Currency */}

                                            {/* Row 1 */}
                                            <div className={`${styles.CurrencySelectContainer} row`}>

                                                {/* Credit Card */}
                                                <div className={`${styles.CurrencyIconContainer} ${(currency === "cc") ? styles.CurrencyIconSelected : ""} col-md-4 col-sm-12`}>
                                                    <div onClick={() => {selectCurrency('cc')}} className={styles.CurrencyIconButton}>
                                                        <FontAwesomeIcon className={styles.CurrencyIcon} icon={faCreditCard} />
                                                        <p>Credit Card</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Next Button */}
                                            <Button 
                                                onClick={() => {gotoStep(2)}} 
                                                className={`${styles.Button} btn btn-primary `}
                                                variant="contained"
                                                disabled={!currency}
                                                color="primary">
                                                Next
                                            </Button>
                                        

                                            <br style={{clear: "both"}} />
                                        </AccordionDetails>
                                    </Accordion>

                                    {/* Step 2 - Transaction */}
                                    <Accordion className={styles.StepRoot} expanded={currentStep === 1}>
                                        {/* Summary */}
                                        <AccordionSummary
                                            className={styles.StepHeader}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <h3 className={styles.StepTitle}>{CHECKOUT_STEPS[1]}</h3>
                                        </AccordionSummary>
                                        {/* Details */}
                                        <AccordionDetails className={styles.StepDetails}>
                                            {/* Hide details until address is created */}

                                            {currency === "cc" &&
                                                <p style={{textAlign: "center", marginBottom: "43px"}}>Please enter your credit card information. You will be charged <strong>${amount}</strong></p>
                                            }

                                            {ccError &&
                                            <div>
                                                <Alert key={0} severity="error">There was a problem with your transaction</Alert>
                                                <br />
                                            </div>
                                                
                                            }
                                            {currency === "cc" &&
                                                <div>
                                                    <CreditCardForm clientSecret={stripeClientSecret} error={ccErrorCallback} success={ccSuccessCallback} />
                                                    <button 
                                                        onClick={() => {gotoStep(1)}}
                                                        className={`${styles.ButtonCurrency}`}
                                                        >
                                                            Change Currency
                                                    </button>
                                                    {/* <Button 
                                                        onClick={() => {gotoStep(1)}} 
                                                        className={`${styles.ButtonCurrency} btn-secondary`}
                                                        variant="contained"
                                                        color="primary">
                                                        Change Currency
                                                    </Button> */}
                                                </div>
                                            }
                                            {address && currency !== "cc" &&
                                                <div style={{textAlign:"center"}}>
                                                    
                                                    <p style={{margin: "64px 0 0 0"}}>Please send <span style={{fontWeight: 800, padding: "4px 8px", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "4px"}}><CopyableText text={amount} /> {currency.toUpperCase()}</span> to the following address</p>
                                                    <p className={styles.TxSpeedInfo}>Transaction speed may vary based on network traffic.</p>
                                                    {currency === "btc.ln" &&
                                                        <div className={`alert ${styles.BtcLightningWarning}`} role="alert">
                                                            <p>Please make sure you are using a Lightning Network supported BTC wallet</p>
                                                        </div>
                                                    }
                                                    <p className={styles.Address}>
                                                        {currency !== "xmr" &&
                                                            getCurrencyIcon()
                                                        }
                                                        <CopyableText text={address} />
                                                        {currency !== "xmr" && 
                                                            <span style={{marginLeft: "12px", position: "relative", top: "2px"}}><CircularProgress color="secondary" size={20} disableShrink /></span>
                                                        }
                                                    </p>

                                                    <br style={{clear: "both"}} />


                                                    {/* Next Button */}
                                                    {/* <Button 
                                                        onClick={() => {gotoStep(3)}} 
                                                        className={`${styles.Button} btn btn-primary`}
                                                        variant="contained"
                                                        color="primary">
                                                        SIMULATE TRANSACTION
                                                    </Button> */}

                                                    {/* Change Currency Button */}
                                                    <Button 
                                                        onClick={() => {gotoStep(1)}} 
                                                        className={`${styles.Button} btn btn-primary`}
                                                        variant="contained"
                                                        color="primary">
                                                        Change Currency
                                                    </Button>

                                                    <br style={{clear: "both"}} />
                                                </div>
                                            }

                                            {/* Show loading animation until its ready */}
                                            {!address && currency !== "cc" &&
                                                <div className={styles.Loading}>
                                                    <p className={styles.LoadingInfo}>Generating {currency} address for transaction</p>
                                                    <div style={{padding: "32px 64px 64px 64px", textAlign: "center"}}><CircularProgress disableShrink /></div>
                                                </div>
                                            }
                                        </AccordionDetails>
                                    </Accordion>

                                    {/* Step 3 - Confirming */}
                                    <Accordion className={styles.StepRoot} expanded={currentStep === 2}>
                                        {/* Summary */}
                                        <AccordionSummary
                                            className={styles.StepHeader}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <h3 className={styles.StepTitle}>{CHECKOUT_STEPS[2]}</h3>
                                        </AccordionSummary>
                                        {/* Details */}
                                        <AccordionDetails className={styles.StepDetails}>
                                            {txFailed &&
                                                <div className={styles.Failed}>
                                                    <FontAwesomeIcon className={styles.FailedIcon} icon={faBomb} />
                                                    <p className={styles.FailedTitle}>Failed</p>
                                                    <p className={styles.FailedId}>{transactionId}</p>
                                                    <p className={styles.FailedInfo}>{failMessage}</p>
                                                </div>
                                                
                                            }
                                            {/* Show loading animation until its ready */}
                                            {!txFailed && !txConfirmed &&
                                            <div>
                                                <div className={styles.Loading}>
                                                    {/* <FontAwesomeIcon className={styles.ConfirmingIcon} icon={faProjectDiagram} /> */}
                                                    <div style={{padding: "0 64px 32px 64px", textAlign: "center"}}><CircularProgress disableShrink /></div>
                                                    <p className={styles.LoadingInfo}>Awaiting blockchain confirmations</p>
                                                </div>
                                            </div>}

                                            
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                    {/* Step 4 - Complete */}
                                    <Accordion className={styles.StepRoot} expanded={currentStep === 3}>
                                        {/* Summary */}
                                        <AccordionSummary
                                            className={styles.StepHeader}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <h3 className={styles.StepTitle}>{CHECKOUT_STEPS[3]}</h3>
                                        </AccordionSummary>
                                        {/* Details */}
                                        <AccordionDetails className={styles.StepDetails}>
                                            {/* Show loading animation until its ready */}
                                            <div className={styles.Complete}>
                                                <FontAwesomeIcon className={styles.CompleteIcon} icon={faUserAstronaut} />
                                                <p className={styles.CompleteInfo}>Transaction Complete!</p>
                                                <p>Your <span className={styles.GreenText}>purchases have been added</span> to your account.</p>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                    <p style={{textAlign:"center", marginTop: "32px", color: "rgba(255,255,255,0.3)"}}>
                                        <FontAwesomeIcon  icon={faFlagCheckered} style={{marginRight: 8, opacity: .5}} />
                                        All sales are final
                                    </p>
                                </div>
                            }

                            
                        </div>

                        {/* Right Column - QR */}
                        <div className={`${styles.RightColumn} col-lg-3 col-md-12`}>

                            {/* QR Code */}
                            <div className={`${styles.PurchaseCard} card`}>
                                <div className={`${styles.PurchaseCode} card-body`}>
                                    {getQrCode()}
                                    
                                </div>
                            </div>

                            {/* Payment Info */}
                            {/*currency !== "cc" &&
                                <div>
                                    <h3 className={styles.ProgressHeader}>
                                        <FontAwesomeIcon className={styles.HeaderIcon} icon={faTasks} />
                                        Progress
                                    </h3>
                                    <div className={`${styles.ProgressCard} card`}>
                                        <div className={`${styles.PurchaseCode} card-body`}>
                                            <div>
                                                {(percentRecieved === 0) ? <LinearProgress className={styles.AmountProgress} /> : <LinearProgress className={(percentRecieved >= 100) ? styles.AmountProgressComplete : styles.AmountProgress} variant="determinate" value={percentRecieved} />}
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{fontSize: "12px", textAlign: "center", marginBottom: "24px"}}>
                                        Approx {parseFloat(amountRecieved).toFixed(4)} of {parseFloat(amount).toFixed(4)} <strong>{currency.toUpperCase()}</strong> - <strong>{percentRecieved.toFixed(0)}%</strong>
                                    </p>
                                </div>
                        */}

                            {/* Info */}
                            
                            {/*transactionId && currency !== "cc" &&
                                <div>
                                    <h3 className={styles.ProgressHeader}>
                                        <FontAwesomeIcon className={styles.HeaderIcon} icon={faReceipt} />
                                        Transaction
                                    </h3>
                                    <div className={`${styles.TxInfo}`}>
                                        <p>Refer to this tx permalink to check its status in the future:</p>
                                        <Link className={styles.TxButton} style={{overflow:"hidden"}} target="_blank"
                                            to={`/tx/${transactionId}`}>
                                                <FontAwesomeIcon className={styles.TxIcon} icon={faLink} />
                                                {transactionId}
                                        </Link>
                                    </div>
                                </div>
                            */}
                            
                            {/* Logs */}
                            {/*logs.length > 0 &&
                                <div>
                                    <h3 className={styles.ProgressHeader}>
                                        <FontAwesomeIcon className={styles.HeaderIcon} icon={faTerminal} />
                                        Logs
                                    </h3>
                                    <div className={`${styles.LogCard} card`}>
                                        <div className={`${styles.Logs} card-body`}>
                                            {logs.map((item, i) => {
                                                return (
                                                    <div className={styles.Log}>
                                                        <p className={styles.LogId}>{item.deposit_id}</p>
                                                        <p className={styles.LogId}>{moment(item.time * 1000).format("MM/DD/YYYY h:mm:ss a")}</p>
                                                        
                                                        
                                                        <h4 className={styles.LogTitle}>Amount</h4>
                                                        <span className={styles.LogAmount}>{item.amount}</span>

                                                        <h4 className={styles.LogTitle}>Message</h4>
                                                        <p className={styles.LogMessage}>{item.status_message}</p>
                                                    </div>
                                                )
                                            })}
                                            {logs.length < 1 ? <p className={styles.LogEmpty}> No log entries yet</p> : ""}
                                        </div>
                                    </div>
                                </div>
                            */}

                        </div>
                    </div>
                    
                </div>
            </div>
        </Layout>
    )
}

export default observer(Purchase)