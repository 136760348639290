/* Dashboard */

/* Core */
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'

/* Abilities */
import { subject } from '@casl/ability'
import { AbilityContext, Can } from '../abilities/Can'

/* Data */
import { useStores } from "../context/RootStoreContext"
import chroma from '@chromaplatform/chromajs'

/* Components */
import Layout from "../components/layout/Layout"

import CircularProgress from '@material-ui/core/CircularProgress'

/* Icons */
import { Icon } from '@iconify/react'
import btcIcon from '@iconify/icons-cryptocurrency/btc'
import ethIcon from '@iconify/icons-cryptocurrency/eth'
import xmrIcon from '@iconify/icons-cryptocurrency/xmr'
import ltcIcon from '@iconify/icons-cryptocurrency/ltc'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTerminal, faTasks, faShoppingCart, faCheck, faCoins } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './Transaction.module.css'


const _ = require('lodash')
const moment = require('moment')


function Transaction(props) {
    const rootStore = useStores()
    const history = useNavigate()
    const ability = useContext(AbilityContext)

    /* Transaction */
    const incomingTxId = _.get(props, "match.params.transactionId")
    const [transaction, setTransaction] = useState()
    
    
    /* Transactions */
    useEffect(() => {

        const getTransactionInfo = async () => {
            // console.log(incomingTxId, "tx")
            // Must have product
            if(!incomingTxId){
                history(`/404`)
            }
  
            
            const txQuery = {
                service: "transactions",
                id: incomingTxId,
                fields: "_id inId medium outMetaIds createdAt status inMeta outMeta amount"
            }
    
            let transaction = await chroma.get(txQuery)

            /* Verify TX Access */
            switch(_.get(transaction, "data.name")){
                case "NotAuthenticated":
                    history(`/404`)
                    break
                case "Forbidden":
                    history(`/404`)
                    break
            }

            transaction.products = []

            for (let i = 0; i < _.get(transaction, "outMetaIds.length"); i++) {
                const productId = transaction.outMetaIds[i]
                
                /* Get Products */
                const productsQuery = {
                    service: "products",
                    id: productId,
                    fields: "_id slug type meta copy cost"
                }
        
                const product = await chroma.get(productsQuery)

                // Add to transaction
                transaction.products.push(product)
            }

            /* Set Subject for CASL */
            subject('transactions', transaction)
            // console.log('after', transaction)

            setTransaction(transaction)
        }

        getTransactionInfo()
    }, [rootStore.Cart.products.length, history, incomingTxId])
    

    /* Get Currency Icon */
    const getCurrencyIcon = () => {
        switch(_.get(transaction, "medium")){
            default:
                return null
            case "btc":
                return <Icon icon={btcIcon} className={`${styles.CurrencyIcon} ${styles.CurrencyIconBtc}`} />
            case "eth":
                return <Icon icon={ethIcon} className={`${styles.CurrencyIcon} ${styles.CurrencyIconEth}`} />
            case "ltct":
                return <Icon icon={ltcIcon} className={`${styles.CurrencyIcon} ${styles.CurrencyIconLtc}`} />
            case "xmr":
                    return <Icon icon={xmrIcon} className={`${styles.CurrencyIcon} ${styles.CurrencyIconXmr}`} />
        }
    }

    /* Get TX Link */
    const getTxLink = (txId, medium) => {
        switch(medium){
            default:
                return ``
            case "ltct":
                return `https://sochain.com/tx/LTCTEST/${txId}`
            case "btc":
                return `https://www.blockchain.com/btc/tx/${txId}`
            case "eth":
                return `https://etherscan.io/tx/${txId}`
        }
    }


    return (
        <Layout noContainer="true">
            <Helmet>
                <title>CHROMA | Transaction</title>
            </Helmet>
            <div className={styles.TransactionContainer}>
                <div className={`${styles.TransactionInfo} container`} >

                    {/* Title */}
                    <h2 className={styles.Headline}>
                        Transaction
                    </h2>

                    {/* Wallet Info */}
                    {/* <Can do="get" this={transaction}> */}
                    <div className={styles.TxInfo}>
                        <p>{_.get(transaction, "medium")} Wallet: <span className={styles.AddressSpan}>{_.get(transaction, "inMeta.address")}</span></p>
                        <p>{moment(_.get(transaction, "createdAt") * 1000).format("MM/DD/YYYY h:mm:ss a")}</p>
                    </div>
                    {/* </Can> */}

               
                    
                    {/* Content */}
                    <div className="row">
                        {/* Left Column Steps */}

                        <div className="col-lg-9 col-md-12">
                            {/* Products */}
                            <div className={styles.ProductsContainer}>
                                <h3 className={styles.ProductsHeadline}>
                                    <FontAwesomeIcon className={styles.ProductIcon} icon={faShoppingCart} />
                                    ({_.get(transaction, "products.length", 0)}) Product{(_.get(transaction, "products.length", 0) > 1) ? "s " : " "}
                                    
                                    {/* Total Price */}
                                    <span className={styles.TotalPrice}>
                                        Total:
                                        {_.get(transaction, "inMeta.totalRecieved") === 0 && 
                                            <span style={{margin: "0 0 0 8px", position: "relative", top: "2px"}}><CircularProgress color="secondary" size={20} disableShrink /></span>
                                        }

                                        {_.get(transaction, "inMeta.totalRecieved") !== 0 && 
                                            <span>{parseFloat(_.get(transaction, "inMeta.totalRecieved"))} {_.get(transaction, "medium")}</span>
                                        }
                                        {getCurrencyIcon()}
                                    </span>
                                </h3>
                                {_.get(transaction, "products") && _.get(transaction, "products").map((product, i) => {
                                    return (
                                        <div className={`${styles.Product} ${_.get(transaction, "status") === "complete" ? styles.ProductComplete: ""}`} key={i}>
                                            {/* Show Checkmark if complete */}
                                            {_.get(transaction, "status") === "complete" && 
                                                <FontAwesomeIcon className={styles.CompleteProductIcon} icon={faCheck} />
                                            }
                                            
                                            <span className={styles.ProductAmount}>{_.get(product, "cost.btc")} btc</span>
                                            <h4 className={styles.ProductTitle}>{_.get(product, "copy.en.title")}</h4>
                                            <p className={styles.ProductData}>{_.get(product, "copy.en.description")}</p>
                                        </div>
                                    )
                                })}
                            </div>

                            {/* Gateway Info */}
                            {_.get(transaction, "inMeta.gatewayTxId") && 
                                <div className={styles.TxInfo}>
                                    <p>
                                        <FontAwesomeIcon className={styles.TxIcon} icon={faCoins} />tx: <a className={styles.TxLink} href={getTxLink(_.get(transaction, "inMeta.gatewayTxId"), _.get(transaction, "medium"))} target="_blank" rel="noreferrer">{_.get(transaction, "inMeta.gatewayTxId")}</a>
                                    </p>
                                </div>
                            }
                            
                        </div>

                        {/* Right Column - QR */}
                        <div className={`${styles.RightColumn} col-lg-3 col-md-12`}>
                            
                            {/* Status */}
                            <div>
                                <h3 className={styles.ProgressHeader}>
                                    <FontAwesomeIcon className={styles.HeaderIcon} icon={faTasks} />
                                    Status
                                </h3>
                                <div className={`${styles.LogCard} ${styles.StatusComplete} card`} style={{marginBottom:"8px"}}>
                                    <div className={`${styles.Logs} card-body`}>
                                        <p className={styles.LogEmpty}>
                                            {_.get(transaction, "status")}
                                        </p>
                                    </div>
                                </div>

                                {_.get(transaction, "inMeta.totalRecieved") > 0 && 
                                    <p style={{fontSize: "12px", textAlign: "center", marginBottom: "24px"}}>
                                        Approx {parseFloat(_.get(transaction, "inMeta.totalRecieved")).toFixed(4)} of {parseFloat(_.get(transaction, "amount")).toFixed(4)} <strong>{_.get(transaction, "medium").toUpperCase()}</strong> - <strong>{((parseFloat(_.get(transaction, "inMeta.totalRecieved"))/parseFloat(_.get(transaction, "amount"))) * 100).toFixed(0)}%</strong>
                                    </p>
                                }
                            </div>

                            {/* Logs */}
                            {_.get(transaction, "inMeta.log") && _.get(transaction, "inMeta.log").length > 0 &&
                                <div>
                                    <h3 className={styles.ProgressHeader}>
                                        <FontAwesomeIcon className={styles.HeaderIcon} icon={faTerminal} />
                                        Logs
                                    </h3>
                                    <div className={`${styles.LogCard} card`}>
                                        <div className={`${styles.Logs} card-body`}>
                                            {_.get(transaction, "inMeta.log").map((item, i) => {
                                                return (
                                                    <div className={styles.Log}>
                                                        <p className={styles.LogId}>{item.deposit_id}</p>
                                                        <p className={styles.LogId}>{moment(item.time * 1000).format("MM/DD/YYYY h:mm:ss a")}</p>
                                                        
                                                        
                                                        <h4 className={styles.LogTitle}>Amount</h4>
                                                        <span className={styles.LogAmount}>{item.amount}</span>

                                                        <h4 className={styles.LogTitle}>Message</h4>
                                                        <p className={styles.LogMessage}>{item.status_message}</p>
                                                    </div>
                                                )
                                            })}
                                            {_.get(transaction, "inMeta.log").length < 1 ? <p className={styles.LogEmpty}> No log entries</p> : ""}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    
                </div>
            </div>
        </Layout>
    )
}

export default observer(Transaction)