/* UserMDVRSessionsModel */

import { types } from 'mobx-state-tree'

/* Model Properties */
const UserMDVRSessionsProps = {
    minutesPlayed: types.optional(types.number, 0),
    legacyMinutesPlayed: types.optional(types.number, 0)
}

/* Model Actions */
const UserMDVRSessionsActions = (self) => ({
    setLegacyMinutesPlayed(minutesPlayed) {
        self.legacyMinutesPlayed = minutesPlayed
    },
    setMinutesPlayed(minutesPlayed) {
        self.minutesPlayed = minutesPlayed + self.legacyMinutesPlayed
    }
})

const UserMDVRSessions = types
  .model('UserMDVRSessions')
  .props(UserMDVRSessionsProps)
  .actions(UserMDVRSessionsActions)

export default UserMDVRSessions