/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */

/* Media */
import styles from './CreatorsDash.module.css'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStores } from "context/RootStoreContext"

const _ = require('lodash')
const axios = require('axios')

function CreatorsDash(props) {
    const rootStore = useStores()

    const [platformRelease, setPlatformRelease] = useState("")
    const [microdoseRelease, setMicrodoseRelease] = useState("")
    const [frontendRelease, setFrontendRelease] = useState("")
    const [desktopRelease, setDesktopRelease] = useState("")

    /* Get Transaction data */
	useEffect(() => {
		const getPlaylists = async () => {
			const playlistQuery = {
				service: "assets",
				fields: "_id meta type subtype",
        		query: {
                    type: "playlist",
                    userId: rootStore.AuthStore.userId
                }
			}

			const playlists = await chroma.find(playlistQuery)

            console.log('playlists', playlists)
		}

		getPlaylists()
	}, [chroma])

    return(
        <div className={styles.Container}>
            <div className={styles.Intro}>
                <h2>Creators Dashboard</h2>
                <p>
                    Stay up to date with the latest changes to our software & platform.
                </p>
            </div>

        </div>
    )
}

export default CreatorsDash