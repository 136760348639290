import { useEffect, useState } from 'react'
import { props } from 'data/structure/BaseModel'
import _ from 'lodash'
import { LineChart, Line, ResponsiveContainer } from 'recharts'

/* Media */
import styles from './UserAmountChart.module.css'
import moment from 'moment'
import SimpleLineChart from './SimpleLineChart'

/* Data */
import chroma from '@chromaplatform/chromajs'


export default function UserActiveChart(props) {
    const [userChartData, setUserChartData] = useState([])

    // GET Users Chart Data
	useEffect(() => {
		const getUserChartData = async () => {
            const txQuery = {
				service: "history",
				fields: "value",
        		query: {key: "users/active"}
			}

			const activeUserHistory = await chroma.find(txQuery)
            const history = _.get(activeUserHistory[0], "value", [])
            
            let userData = []
            for (let i = 0; i < history.length; i++) {
                if(i < 24){
                    const entry = history[i];
                    const dateData = {
                        title: moment(_.get(entry, "date") * 1000).fromNow(),
                        amount: entry.online
                    }
                    userData.unshift(dateData)
                }
            }

            setUserChartData(userData)
		}

		getUserChartData()

	}, [setUserChartData])

    return (
        <SimpleLineChart data={userChartData} className={`${styles.LineChart} ${_.get(props, "className")}`} />
    )
}