/* <QueryPreview /> */

/* Data */
import { useEffect, useState } from "react"
import { useStores } from "../../../context/RootStoreContext"

/* Components */
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* Media */
import styles from './QuerySelector.module.css'

import queryConfig from "../config/query-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLongArrowAltDown, faSort, faSortAlphaDown, faSortAlphaUp } from "@fortawesome/free-solid-svg-icons";

const _ = require('lodash')


function QuerySelector(props) {
    const rootStore = useStores()
    const callback = _.get(props, "callback")
    const service = _.get(props, "service")
    const [limit, setLimit] = useState(_.get(props, "limit"))
    const [query, setQuery] = useState(_.get(props, "query", {}))
    const [sorting, setSorting] = useState("down") // down up
    const [sortField, setSortField] = useState("")


    const selectChange = (event) => {
        const name = _.get(event, "target.name")
        let value = _.get(event, "target.value")

        // Check for none options
        if(value === "none"){
            value = ""
        }

        // Convert Values from String
        switch(value){
            default:
                break
            case "true":
                value = true
                break
            case "false":
                value = false
                break
        }

        updateQuery(name, value)
    }

    const sortChange = (event) => {
        const name = _.get(event, "target.name")
        let value = _.get(event, "target.value")

        // Check for none options
        if(value === "none"){
            value = false
        }

        setSortField(value)
        updateQuery("", "")
    }

    const queryChange = (event) => {
        const name = _.get(event, "target.name")
        const value = _.get(event, "target.value")
        updateQuery(name, value)
    }

    const updateQuery = (name, value) => {
        let updatedQuery = {...query}

        if(value !== ""){
            // Set value
            updatedQuery[name] = value
        } else {
            // remove
            if(updatedQuery[name]){
                delete updatedQuery[name]
            }
        }

        if(limit){
            updatedQuery["$limit"] = limit
        }

        /* Update Sorting on query */
        if(sortField && sorting){
            switch(sorting){
                default:
                    break
                case "":
                    delete updatedQuery["$sort"]
                    break
                case "down":
                    updatedQuery["$sort"] = {}
                    updatedQuery["$sort"][sortField] = -1;
                    break
                case "up":
                    updatedQuery["$sort"] = {}
                    updatedQuery["$sort"][sortField] = 1;
                    break
            }
        }

        // Update Query
        setQuery(updatedQuery)
        callback(updatedQuery)
    }


    /* Get Query Form */
    const getQueryBuilderForm = () => {
        if(service){
            const fields = _.get(queryConfig, `${service}.queryFields`)
            return fields && fields.map((field, index) => {
                return(getFormField(field.name, field.type, index, field.options))
            })
        }
    }

    /* Get Query Form Field */
    const getFormField = (name, type, index, options) => {
        switch(type){
            default:
                return ""
                break
            case "string":
                return (
                    <div key={index} className={styles.query}>
                        <TextField size="small" id="outlined-basic" onChange={queryChange} label={name} name={name} variant="outlined" fullWidth />
                    </div>
                )
                break
            case "select":
                return (
                    <FormControl size="small" key={index} className={`${styles.query} ${styles.querySelect}`} fullWidth>
                        <InputLabel id={`${name}-${type}-select`} className={styles.label}>{name}</InputLabel>
                        <Select
                            labelId={`${name}-${type}-select`}
                            id={`${name}-${type}-select`}
                            label={name}
                            name={name}
                            className={styles.select}
                            onChange={selectChange}
                        >
                            {options && options.map((option, i) => {
                                return <MenuItem key={i} value={option}>{option}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
                break
        }
    }

     /* Get Query Sorting */
     const getQuerySorting = () => {
        if(service){
            const fields = _.get(queryConfig, `${service}.sortFields`)

            return(
                <FormControl className={styles.sortSelect} fullWidth size="small">
                    <InputLabel id={`${service}-select`} className={styles.label}>Sort</InputLabel>
                    <Select
                        labelId={`${service}-select`}
                        id={`${service}-select`}
                        label={service}
                        name={service}
                        value={sortField}
                        className={styles.select}
                        onChange={sortChange}
                    >
                        <MenuItem key={666} value="none">none</MenuItem>
                        {fields && fields.map((field, i) => {
                            return <MenuItem key={i} value={field}>{field}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            )
        }
    }

    const toggleSort = () => {
        // up down none
        switch(sorting){
            default:
                break
            case "down":
                setSorting("up")
                break
            case "up":
                setSorting("down")
                break
        }
        updateQuery("", "")
    }
    
    return(
        <div className={styles.QuerySelector}>
            {/* Query Toggle icon */}
            <button className={`${styles.toggleButton}`} type="button" data-bs-toggle="collapse" data-bs-target="#queries" aria-expanded="false" aria-controls="queries">
                <FontAwesomeIcon icon={faCog} className={styles.toggleIcon} />
            </button>
            
            {/* Sorting toggle icon */}
            <button className={`${styles.sortButton}`} onClick={toggleSort}  type="button">
                {!sorting && 
                    <FontAwesomeIcon icon={faSort} className={`${styles.toggleIcon} ${styles.toggleIconThin}`} />
                }
                {sorting === "up" && 
                    <FontAwesomeIcon icon={faSortAlphaUp} className={styles.toggleIcon} />
                }

                {sorting === "down" && 
                    <FontAwesomeIcon icon={faSortAlphaDown} className={styles.toggleIcon} />
                }
            </button>

            {/* Sorting */}
            <div className={styles.sorting} id="sorting">
                {getQuerySorting()}
            </div>

            {/* Queries */}
            <div className={`collapse ${styles.queries}`} id="queries">
                {getQueryBuilderForm()}
            </div>

            
        </div>
    )
}

export default QuerySelector