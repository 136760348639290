/* Form Edit Dashboard */

/* Core */
import { useState } from "react"
import { observer } from "mobx-react"
import { useStores } from "../../context/RootStoreContext"
import { useNavigate } from "react-router-dom"

/* Data */
import chroma from "@chromaplatform/chromajs"
import ReactGA from 'react-ga4'

/* Components */
import Hero from "../media/Hero"
import Layout from "../layout/Layout"
import ProfileDetails from "../user/ProfileDetails"

/* Components */
import { Formik, Field, Form } from 'formik'

import { HexColorPicker } from "react-colorful"

/* Media */
import styles from "./FormEditDashboard.module.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons'
import SubNav from "components/nav/SubNav"

import headerImage from '../../media/images/screenshots/Screenshot_B19.jpg';
import analyticEvent from "CHROMA-React/code/_analytics"

const _ = require('lodash')
const axios = require('axios')

const API_ENDPOINT = 'https://api.chroma.vision'

function FormEditDashboard() {
    const rootStore = useStores()
    const history = useNavigate()

    const [avatarUrl, setAvatarUrl] = useState(_.get(rootStore, "AuthStore.user.meta.profile.avatarURL"))
    const [avatarButtonEnabled, setAvatarButtonEnabled] = useState(true)
    const [changingUsername, setChangingUsername] = useState(false)
    const [status, setStatus] = useState({redirect: false, message: ""})


    const initialValues = {
        bio: rootStore.AuthStore.user.meta.profile.bio || "",
        youtubeURL: rootStore.AuthStore.user.meta.profile.youtubeURL || "",
        twitchURL: rootStore.AuthStore.user.meta.profile.twitchURL || "",
        instagramURL: rootStore.AuthStore.user.meta.profile.instagramURL || "",
        facebookURL: rootStore.AuthStore.user.meta.profile.facebookURL || "",
        youtubePlaylistId: rootStore.AuthStore.user.meta.profile.youtubePlaylistId || "",
        primaryColor: rootStore.AuthStore.user.meta.profile.primaryColor || "",
        secondaryColor: rootStore.AuthStore.user.meta.style.colors.core.secondaryColor || ""
    }


    /* Form Validation */
    const validate = values => {
        const errors = {};

        // Bio
        if(values.bio.length > 127){
            errors.bio = "Bio must be 128 characters or less"
        }

        // Colors
        const colorRegex = /(#(?:[0-9a-f]{2}){2,4}$|(#[0-9a-f]{3}$)|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d\.]+%?\)$|black$|silver$|gray$|whitesmoke$|maroon$|red$|purple$|fuchsia$|green$|lime$|olivedrab$|yellow$|navy$|blue$|teal$|aquamarine$|orange$|aliceblue$|antiquewhite$|aqua$|azure$|beige$|bisque$|blanchedalmond$|blueviolet$|brown$|burlywood$|cadetblue$|chartreuse$|chocolate$|coral$|cornflowerblue$|cornsilk$|crimson$|currentcolor$|darkblue$|darkcyan$|darkgoldenrod$|darkgray$|darkgreen$|darkgrey$|darkkhaki$|darkmagenta$|darkolivegreen$|darkorange$|darkorchid$|darkred$|darksalmon$|darkseagreen$|darkslateblue$|darkslategray$|darkslategrey$|darkturquoise$|darkviolet$|deeppink$|deepskyblue$|dimgray$|dimgrey$|dodgerblue$|firebrick$|floralwhite$|forestgreen$|gainsboro$|ghostwhite$|goldenrod$|gold$|greenyellow$|grey$|honeydew$|hotpink$|indianred$|indigo$|ivory$|khaki$|lavenderblush$|lavender$|lawngreen$|lemonchiffon$|lightblue$|lightcoral$|lightcyan$|lightgoldenrodyellow$|lightgray$|lightgreen$|lightgrey$|lightpink$|lightsalmon$|lightseagreen$|lightskyblue$|lightslategray$|lightslategrey$|lightsteelblue$|lightyellow$|limegreen$|linen$|mediumaquamarine$|mediumblue$|mediumorchid$|mediumpurple$|mediumseagreen$|mediumslateblue$|mediumspringgreen$|mediumturquoise$|mediumvioletred$|midnightblue$|mintcream$|mistyrose$|moccasin$|navajowhite$|oldlace$|olive$|orangered$|orchid$|palegoldenrod$|palegreen$|paleturquoise$|palevioletred$|papayawhip$|peachpuff$|peru$|pink$|plum$|powderblue$|rosybrown$|royalblue$|saddlebrown$|salmon$|sandybrown$|seagreen$|seashell$|sienna$|skyblue$|slateblue$|slategray$|slategrey$|snow$|springgreen$|steelblue$|tan$|thistle$|tomato$|transparent$|turquoise$|violet$|wheat$|white$|yellowgreen$|rebeccapurple$)/i
        if (values.primaryColor && !colorRegex.test(values.primaryColor)) errors.primaryColor = "That is not a valid color"
        if (values.secondaryColor && !colorRegex.test(values.secondaryColor)) errors.secondaryColor = "That is not a valid color"

        const urlRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
        if (values.twitchURL && !urlRegex.test(values.twitchURL)) errors.twitchURL = "That is not a valid url"
        if (values.facebookURL && !urlRegex.test(values.facebookURL)) errors.facebookURL = "That is not a valid url"
        if (values.instagramURL && !urlRegex.test(values.instagramURL)) errors.instagramURL = "That is not a valid url"
        if (values.youtubeURL && !urlRegex.test(values.youtubeURL)) errors.youtubeURL = "That is not a valid url"
    
        return errors
    }

    /* Form Submission */
    const submit = async values => {
        let profileData = {...values}

        profileData['avatarURL'] = avatarUrl

        Object.keys(profileData).forEach((key) => (profileData[key] == null || profileData[key] == "") && delete profileData[key])

        const colorKeys = ['primaryColor', 'secondaryColor', 'neuturalColor', 'lightFgColor', 'lightBgColor', 'darkFgColor', 'darkBgColor']
        let colorData = {}

        Object.keys(profileData).forEach((key) => {
            if(colorKeys.includes(key)){
                // Is color val, remove & store in colorData
                colorData[key] = profileData[key]
                delete profileData[key]
            }
        })

        const patchData = {
            style: {
                colors: {
                    core: {
                        ...colorData
                    }
                }
            },
            profile: {
                ...profileData
            }
        }

        let headerConfig 
        if (typeof window !== "undefined") {
            headerConfig = {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${window.localStorage.chroma_token}`
            }
        }

        const chromaQL = {
            "id": rootStore.AuthStore.userId || "",
            "path": "users/meta",
            "value": patchData
        }

        // const chromaQuery = await chroma.set(chromaQL)
        const chromaQuery = await fetch(`${API_ENDPOINT}/query/set`, {
            method: 'POST', headers: headerConfig,
            body: JSON.stringify(chromaQL)
        })

        // console.log('chromaQuery', chromaQuery, chromaQL)
        
        // // Tell user model to update
        rootStore.AuthStore.user.hydrate(rootStore.AuthStore.userId, rootStore.AuthStore.token)

        // // redirect to profile
        setTimeout(() => {
            setStatus({redirect: true, message: "Profile successfully updated"})
        }, 1000)
    }


    /* Change Username */
    const changeUsername = async e => {
        const username = document.getElementById('username').value

        const usernameRegex = /^[a-zA-Z0-9_]+$/
        if (!usernameRegex.test(username)){
            // console.log("INVALID USERNAME")
            // // redirect to profile
            setTimeout(() => {
                setStatus({redirect: true, message: "Could not update username"})
            }, 500)
        } else {
            setChangingUsername(true)

            const usernameQuery = {
                "id": _.get(rootStore, "AuthStore.userId"),
                "path": "users/username",
                "value": username,
                "overwrite": true
            }
    
            const usernameUpdate = await chroma.set(usernameQuery)
            
            if(usernameUpdate.username === username){
                // // Tell user model to update
                rootStore.AuthStore.user.hydrate(rootStore.AuthStore.userId, rootStore.AuthStore.token)
                
                // // redirect to profile
                setTimeout(() => {
                    setStatus({redirect: true, message: "Username successfully updated"})
                }, 500)
            } else {
                // // redirect to profile
                setTimeout(() => {
                    setStatus({redirect: true, message: "Could not update username"})
                }, 500)
            }
            setChangingUsername(false)
        }
    }

    /* UpdateAvatar */
    const randomizeAvatar = async e => {
        setAvatarButtonEnabled(false)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + _.get(window, "localStorage.chroma_token")
        }

        const unlinkRequest = await axios.post("https://api.chroma.vision/users/avatar/randomize", {}, {headers: headers})
        const avatarUrl = _.get(unlinkRequest, "data", "")
        rootStore.AuthStore.user.meta.profile.setAvatar(avatarUrl)
        setAvatarUrl(avatarUrl)
        setAvatarButtonEnabled(true)


        analyticEvent("Randomize_Avatar", {})
        // ReactGA.event({
        //     category: 'Dashboard',
        //     action: 'Randomize Avatar'
        // })
    }

    /* Handle Redirect */
    if(status.redirect){
        history("/reload")

        setTimeout(() => {
            history({
                pathname:"/dashboard",
                state: { message: status.message || "" }
            })
        })
    }
  
    return(
        <div>
            {/* Avatar */}
            <div className="card">
                <div className={`${styles.CardBody} card-body`}>                
                    <div className="row">
                        <div className={`${styles.FormGroup} col-12`}>
                            <div className={styles.avatarContainer}>
                                <img src={avatarUrl} className={styles.avatar} />
                            </div>
                            <label htmlFor="bio" className={styles.FormLabel}>Avatar</label>
                            <br />
                            <button disabled={!avatarButtonEnabled} onClick={randomizeAvatar} className={`${styles.RandomizeButton} primary`}>Randomize</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validate={validate}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        {/* Bio */}
                        <div className="card">
                            <div className={`${styles.CardBody} card-body`}>                
                                <div className="row">
                                    <div className={`${styles.FormGroup} col-12`}>
                                        <label htmlFor="bio" className={styles.FormLabel}>Bio</label>
                                        <Field 
                                            id="bio" 
                                            name="bio" 
                                            placeholder="Once upon a time..." 
                                            as="textarea" 
                                            // maxLength={128}
                                            className={`${styles.FormField} ${styles.FormBio} form-control`}
                                        />
                                        {/* Bio Error Feedback */}
                                        {errors.bio && touched.bio ? (
                                            <div className={styles.FormError}>{errors.bio}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        {/* Personalization */}
                        <div className="card">
                            <div className={`${styles.CardBody} card-body`}>    
                                <div className="row">
                                    <div className={`${styles.FormGroup} col-12`}>
                                        <label htmlFor="primaryColor" className={styles.FormLabel} style={{marginBottom: 0}}>Personalization</label>
                                        <div className="row">
                                            {/* Primary Color */}
                                            <div className="col">
                                                {/* <label htmlFor="primaryColor" className={styles.FormLabel}>Primary Color</label> */}
                                                <p style={{fontSize: "14px", margin: "0 0 12px 0"}}>Primary Color</p>
                                                <HexColorPicker className={styles.ColorPicker} color={_.get(rootStore, "AuthStore.user.meta.style.colors.core.primaryColor")} onChange={(color) => {setFieldValue("primaryColor", color)}} />
                                                <Field 
                                                    id="primaryColor" 
                                                    name="primaryColor" 
                                                    placeholder="#333"
                                                    style={{display: "none"}}
                                                    className={`${styles.FormField} form-control`}
                                                />
                                                {/* Primary Color Error Feedback */}
                                                {errors.primaryColor && touched.primaryColor ? (
                                                    <div className={styles.FormError}>{errors.primaryColor}</div>
                                                ) : null}
                                            </div>

                                            {/* Secondary Color */}
                                            <div className="col">
                                                {/* <label htmlFor="secondaryColor" className={styles.FormLabel}>Secondary Color</label> */}
                                                <p style={{fontSize: "14px", margin: "0 0 12px 0"}}>Secondary Color</p>
                                                <HexColorPicker className={styles.ColorPicker} color={_.get(rootStore, "AuthStore.user.meta.style.colors.core.secondaryColor")} onChange={(color) => {setFieldValue("secondaryColor", color)}} />
                                                <Field
                                                    id="secondaryColor"
                                                    name="secondaryColor"
                                                    placeholder="#c4c4c4"
                                                    style={{display: "none"}}
                                                    className={`${styles.FormField} form-control`}
                                                />
                                                {/* Secondary Color Error Feedback */}
                                                {errors.secondaryColor && touched.secondaryColor ? (
                                                    <div className={styles.FormError}>{errors.secondaryColor}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Playlist ID */}
                        <div className="card">
                            <div className={`${styles.CardBody} card-body`}>    
                                <div className="row">
                                    <div className={`${styles.FormGroup} col-12`}>
                                        <label htmlFor="primaryColor" className={styles.FormLabel} style={{marginBottom: 0}}>Youtube Playlist ID</label>
                                        <p style={{fontSize: "14px", margin: "0 0 12px 0"}}>Set a playlist id to share your Microdose VR streams & recordings</p>
                                        <Field 
                                            id="youtubePlaylistId" 
                                            name="youtubePlaylistId" 
                                            placeholder="PLKc8FS909WjfCUv-pW8aIGNoVbqqcckF2"
                                            className={`${styles.FormField} form-control`}
                                        />
                                        {/* Primary Color Error Feedback */}
                                        {errors.youtubePlaylistId && touched.youtubePlaylistId ? (
                                            <div className={styles.FormError}>{errors.youtubePlaylistId}</div>
                                        ) : null}
                
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Social URLS */}
                        <div className="card">
                            <div className={`${styles.CardBody} card-body`}> 
                                <div className={`${styles.FormGroup} row`}>
                                    <label htmlFor="streamingPlatforms" className={styles.FormLabel}>Streaming Platforms</label>
                
                                    {/* Twitch URL */}
                                    <div className={`${styles.FormSubGroup} col-12`}>
                                        <label className="visually-hidden" htmlFor="twitchURL">Twitch URL</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faTwitch} />
                                            </div>
                                            <Field id="twitchURL" name="twitchURL" placeholder="https://twitch.tv/" className="form-control" />
                                        </div>
                                        {/* Twitch URL Error Feedback */}
                                        {errors.twitchURL && touched.twitchURL ? (
                                            <div className={styles.FormError}>{errors.twitchURL}</div>
                                        ) : null}
                                    </div>
                
                                    {/* Youtube URL */}
                                    <div className={`${styles.FormSubGroup} col-12`}>
                                        <label className="visually-hidden" htmlFor="youtubeURL">Youtube URL</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </div>
                                            <Field id="youtubeURL" name="youtubeURL" placeholder="https://youtube.com" className="form-control" />
                                        </div>
                                        {/* Youtube URL Error Feedback */}
                                        {errors.youtubeURL && touched.youtubeURL ? (
                                            <div className={styles.FormError}>{errors.youtubeURL}</div>
                                        ) : null}
                                    </div>
                
                                    {/* Instagram URL */}
                                    <div className={`${styles.FormSubGroup} col-12`}>
                                        <label className="visually-hidden" htmlFor="instagramURL">Instagram URL</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faInstagram} />
                                            </div>
                                            <Field id="instagramURL" name="instagramURL" placeholder="https://instagram.com" className="form-control" />
                                        </div>
                                        {/* Instagram URL Error Feedback */}
                                        {errors.instagramURL && touched.instagramURL ? (
                                            <div className={styles.FormError}>{errors.instagramURL}</div>
                                        ) : null}
                                    </div>
                
                                    {/* Facebook URL */}
                                    <div className={`${styles.FormSubGroup} col-12`}>
                                        <label className="visually-hidden" htmlFor="facebookURL">Facebook URL</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faFacebook} />
                                            </div>
                                            <Field id="facebookURL" name="facebookURL" placeholder="https://facebook.com" className="form-control" />
                                        </div>
                                        {/* Facebook URL Error Feedback */}
                                        {errors.facebookURL && touched.facebookURL ? (
                                            <div className={styles.FormError}>{errors.facebookURL}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className={`${styles.FormGroup} col-12`}>
                                <button type="submit" className={`${styles.SaveButton} primary`}>Save</button>
                            </div>
                        </div>
                    </Form>
                )}
        </Formik>

        {/* <hr /> */}

        {/* <h3 style={{margin: "72px 0 64px 0", color: "rgba(255,255,255,0.6)", textAlign:"center"}}>Advanced</h3> */}

        {/* Username */}
        {/* <div className="card"> */}
        <div className={styles.Username}>
            <div className="card-body">                
                <div className="row">
                    <div className={`${styles.FormGroup} col-12`}>
                        <label htmlFor="username" className={styles.FormLabel}>
                            Change Username <span style={{color:"rgba(255,255,255,0.2)"}}>coming soon</span>
                        </label>
                        
                        <div className="input-group" style={{pointerEvents:"none", cursor:"pointer", opacity:.6}}>
                            <div className="input-group-text">@</div>
                            <input 
                                id="username" 
                                name="username" 
                                placeholder={_.get(rootStore, "AuthStore.user.username")}
                                maxLength={32}
                                disabled
                                className={`${styles.FormField} form-control`}
                            />
                            <button onClick={changeUsername} className={`${styles.ChangeUsernameButton} primary`}>Change Username</button>
                        </div>
                        
                        {/* Username Error Feedback */}
                        {/* {errors.bio && touched.bio ? (
                            <div className={styles.FormError}>{errors.bio}</div>
                        ) : null} */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default observer(FormEditDashboard)