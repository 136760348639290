/* 
    const chromaQL = {
        service: "products",
        query: { _id: { $in: [productId1, productId2] } },
        fields: "slug copy meta{buildType}"
    }
    <ChromaList query={chromaQL} />

    - Determines template to use based on query service
    - Optional config object to modify behavior
        - override template
        - 

*/

/* Core */
import { useEffect, useRef, useState } from "react"

import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";

import chroma from '../code/chroma'
import templates from "CHROMA-React/config/templates"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Media */
import styles from './chroma-list.module.css'

import _ from 'lodash'
import { faSpinner, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";

function ChromaList(props) {
    const gridRef = useRef()
    const [listIndex, setListIndex] = useState(0)
    const [listData, setListData] = useState([])
    const [items, setItems] = useState([])
    const [loadingComplete, setLoadingComplete] = useState(false)
    const [noAssetsFound, setNoAssetsFound] = useState(false)
    
    // Get notify parent method
    const [countCallback, setCountCallback] = useState(_.get(props, "setCount"))
    
    
    // get column count, default to 1
    const columnCount = _.get(props, "columns", 1)
    const groupSize = _.get(props, "groupSize", 10) // defaults set here

    // Get limit
    const limit = _.get(props, "limit")
    
    // Setup query
    let query = _.get(props, "query")
    
    // Get Service
    const service = _.get(query, "service")

    const getItems = async (groupKey, amount) => {
        let nextItems = []
        const skipAmount = groupKey * amount
    
        // Query Chroma
        if(_.get(query, 'query')){
            // If limit was set, do not do paging
            // query['query']['$skip'] = limit > 0 ? limit : skipAmount
            query['query']['$skip'] = skipAmount
            query['query']['$limit'] = amount
            const chromaRequest = await chroma.find(query)
    
            return chromaRequest || []
        } else {
            return []
        }

        setLoadingComplete(true)
    }
    
    useEffect(() => {
        const init = async () => {
            setItems(await getItems(0, groupSize))
        }
        init()
    }, [setItems, query, limit])
    
    const getTemplate = (item, key) => {
        const type = _.get(item, "type")
        const groupKey = _.get(item, "groupKey")
        
        let Template

        if(type){
            Template = _.get(templates, `${service}.${type}-list`)
        } else {
            // no type present, use default
            Template = _.get(templates, `${service}.default-list`)
        }

        if(Template){
            return <Template data-grid-lazy="true" service={service} type={type} index={key} data-num={key} key={key} data-grid-groupkey={groupKey} item={item} />
            // return <Template key={key} data-grid-groupkey={key} item={item} />
        } else {
            Template = _.get(templates, `default`)
            return <Template data-grid-lazy="true" service={service} type={type} index={key} data-num={key} key={key} data-grid-groupkey={groupKey} item={item} />
            // return <Template service={service} type={type} key={key} data-grid-groupkey={key} item={item} />
        }

    }

    const getNextRequest = async (e) => {
        if(_.get(items, "length", 0) + 1 < limit){
            
            const currentGroupKeyElement = Array.from(
                document.querySelectorAll('.chroma-list-item')
                ).pop()
                
            const currentGroupKey = parseInt(_.get(currentGroupKeyElement, "dataset.gridGroupkey", 0))
            const currentIndex = parseInt(_.get(currentGroupKeyElement, "dataset.num", 0))
            
            const nextGroupKey = currentGroupKey + 1;
            
            // Set placeholder
            e.wait()
            // const placeholders = e.currentTarget.appendPlaceholders(groupSize)
            // console.log(placeholders)
            
            let newItems = await getItems(nextGroupKey, groupSize)

            for (let i = 0; i < _.get(newItems, 'length', 0); i++) {
                const item = newItems[i]

                item['groupKey'] = nextGroupKey
                item['key'] = currentIndex + i
            }

            // Dont add duplicates
            const updatedItems = newItems.filter(f => !items.some(s => s.key === f.key))

            e.ready()

            if(newItems){
                setItems([
                    ...items,
                    ...updatedItems
                ])

                _.get(gridRef, "current._grid") && _.get(gridRef, "current._grid").updateItems()
            }

            // e.currentTarget.removePlaceholders(groupSize, nextGroupKey)
            // setLoadingComplete(true)
        }
        
        setLoadingComplete(true)

    }

    const getNoneFound = () => {
        // Check for override component on empty
        if(_.get(props, "empty")){
            return _.get(props, "empty")
        }
        
        return(
            <div className={styles.loading}>
                <h2 className={styles.loadingTitle}>
                    <FontAwesomeIcon icon={faUserAstronaut} className={styles.noneFoundIcon} />
                    No {service} found
                </h2>
            </div>
        )
    }

    const getLoading = () => {
        return(
            <div className={styles.loading}>
                <h2 className={styles.loadingTitle}>
                    <FontAwesomeIcon icon={faSpinner} spin className={styles.loadingIcon} />
                </h2>
            </div>
        )
    }

    // Return if no service is set
    if(!service) {return("")}

    return <MasonryInfiniteGrid
        column={columnCount}
        gap={5}
        ref={gridRef}
        onRequestAppend={getNextRequest}
        useResizeObserver={true}
        observeChildren={true}
    >
        {/*<ScrollToTop />*/}
        {items && _.get(items, "length", 0) > 0 && items.map((item, index) => {
            if(index+1 <= _.get(props, "limit", 9999)){
                const listTemplate = getTemplate(item, index)
                return listTemplate
            }
            
        })}
        
        {loadingComplete && _.get(items, "length", 0) < 1 &&
            getNoneFound()
        }
        
        {!loadingComplete &&
            getLoading()
        }
    </MasonryInfiniteGrid>
}

export default ChromaList