/* Core */
import _ from 'lodash'
import { forwardRef } from 'react';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

/* Media */
import styles from './asset-poap.module.css'

const AssetPOAPListItem = (props) => {
    const { item } = props

    const name = _.get(item, "meta.name")
    const link = `/assets/${_.get(item, "_id")}`
    const eventLink = _.get(item, "meta.link")
    const image = _.get(item, "meta.image")
    const tokenId = _.get(item, "tokenId")
    const associatedProducts = _.get(item, "meta.associatedProducts")
    const subtype = _.get(item, "subtype")

    return (
        <a href={`${eventLink}`} target="_blank" className={styles.assetLink}>
            <div {...props} className={styles.listItemContainer}>
                {image &&
                    <img src={image} className={styles.thumbnail} />
                }

                <h2 className={styles.name}>{name}</h2>

                <span className={`${styles.type} badge rounded-pill text-bg-light`}>NFT / POAP</span>
                
                <h3 className={styles.associatedCount}>
                    <strong>{_.get(associatedProducts, "length", 0)}</strong> products associated
                </h3>

                <div style={{display: "block", clear:"both", margin: "0 !important"}} />
                
                {tokenId &&
                    <h3 className={styles.tokenId}>{tokenId}</h3>
                }
                
                {/*<a href={link} className={styles.button}>
                    <button className={`primary`}>
                        View
                    </button>
                </a>

                <a href={eventLink} target="_blank" className={styles.button}>
                    <button className={`primary`}>
                        Event
                    </button>
                </a>*/}
            </div>
        </a>
    )
}

export default AssetPOAPListItem