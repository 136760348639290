/* <Steps /> */

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import styles from './Steps.module.css'

const _ = require('lodash')

/* Reusable Step Component */

/*
    props.step = 1 // Currently selected
    props.steps = ["Step 1", "Step 2", "Step 3"] // Labels
    
*/

function Steps(props) {
    const currentStep = _.get(props, "step")
    const steps = _.get(props, "steps")
    
    if(!steps) {
        return null
    }

    const getStep = (stepLabel, index) => {
        return (
            <Step key={index} className={styles.Step}>
                <StepLabel className={styles.StepLabel}>{stepLabel}</StepLabel>
            </Step>
        )
    }
    return(
        <Stepper className={styles.Stepper} activeStep={currentStep}>
            {steps.map((step, index) => {
                return getStep(step, index)
            })}
        </Stepper>
    )
}

export default Steps
