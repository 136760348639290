/* Header */

/* Components */
import { useStores } from "context/RootStoreContext"

import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './wallet-status.module.css'

import metamaskImage from '../../media/images/misc/metamask-fox.svg';

import _ from 'lodash'

function WalletStatus(props) {
    const rootStore = useStores()

    const ethId = _.get(rootStore, 'AuthStore.user.ethId')

    return (
        <div className={`${styles.container} ${props.className}`}>
            {ethId && <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />}

            <Link 
                    to={`/nfts`}>
                <img src={metamaskImage} className={styles.metamaskLogo} alt="Metamask" />
            </Link>

            {ethId &&
                <Link 
                    to={`/nfts`}>
                        <button className={`${styles.linkWalletButton} primary`}>
                            View linked NFTs
                        </button>
                </Link>
            }

            {ethId && 

                
                <p className={styles.address}>
                    {ethId}
                </p>
            }

            {!ethId &&
                <Link 
                    to={`/nfts`}>
                        <button className={`${styles.linkWalletButton} primary`}>
                            Link your wallet
                        </button>
                </Link>
            }
        </div>
    )
}

export default WalletStatus