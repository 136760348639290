/* Dashboard */

/* Core */
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"

import { useToasts } from 'react-toast-notifications'

/* Media */
import styles from './Dashboard.module.css'

import headerImage from '../media/images/screenshots/Screenshot_B19.jpg';

import chroma from '@chromaplatform/chromajs'
import WalletStatus from "components/dashboard-2/wallet-status"
import UsersInfo from "components/dashboard-2/users-info"
import QueryBuilder from "components/query-builder/QueryBuilder"
import NoProducts from "components/dashboard-2/no-products"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDeskpro } from "@fortawesome/free-brands-svg-icons"
import { faDesktop, faWallet } from "@fortawesome/free-solid-svg-icons"


const _ = require('lodash')

function Dashboard(props) {
    const rootStore = useStores()
    const history = useNavigate()
    const location = useLocation()

    /* Setup State */
    const [assets, setAssets] = useState([])
    const [products, setProducts] = useState([])
    const [transactions, setTransactions] = useState([])

    chroma.cache.flushCache()

    /* Display Message on load (move to somewhere else somehow) */
    const { addToast } = useToasts()
    useEffect(() => {
        if(_.get(location, "state.message")) {
            const message = _.get(location, "state.message")
            const notificationConfig = { appearance: 'info' }
            addToast(message, notificationConfig)
    
            // Clear state (avoids loop)
            history("/dashboard", { replace: true })
        }
    }, [addToast, _, location, history])


    /* Get users data */
    useEffect(() => {
        const query = {
            service: "users",
            id: rootStore.AuthStore.userId,
            fields: "username meta{assets products}"
        }

        chroma.get(query).then(data => {
            // Store asset id's for chromaQL
            const incomingAssets = _.get(data, "meta.assets", [])
            let assets = []
            for (let i = 0; i < incomingAssets.length; i++) {
                const id = _.get(incomingAssets[i], "assetId")
                id && assets.push(id)
            }
            setAssets(assets)

            // Store product id's for chromaQL
            const incomingProducts = _.get(data, "meta.products", [])
            let products = []
            for (let j = 0; j < incomingProducts.length; j++) {
                const id = _.get(incomingProducts[j], "productId")
                id && products.push(id)
            }
            setProducts(products)
        })
    }, [chroma, rootStore, setAssets, setProducts])

    /* Get Transaction data */
	useEffect(() => {
		const getTransactions = async () => {
			const txQuery = {
				service: "transactions",
				fields: "_id amount medium inMeta{totalUSDRecieved}",
        		query: {
                    status:"complete",
                    inId: rootStore.AuthStore.userId
                }
			}

			const incomingTransactions = await chroma.find(txQuery)

            let transactions = []
            for (let j = 0; j < incomingTransactions.length; j++) {
                const id = _.get(incomingTransactions[j], "_id")
                id && transactions.push(id)
            }

            setTransactions(transactions)
		}

		getTransactions()
	}, [chroma, setTransactions])


    /* Create chromaQL requests */
    const chromaQLProducts = {
        service: "products",
        query: {
            "_id": {
                "$in": products
            },
			"$limit": 0
        },
        fields: "slug app copy meta{buildType}"
    }

    const chromaQLAssets = {
        service: "assets",
        query: {
            "_id": {
                "$in": assets
            },
            "$sort": {
				createdAt: -1
			},
			"$limit": 0
        },
        fields: "_id type subtype meta children tokenId"
    }

    const chromaQLTransactions = {
        service: "transactions",
        query: {
            "_id": {
                "$in": transactions
            },
            "$sort": {
				createdAt: -1
			},
			"$limit": 0
        },
        fields: "_id createdAt amountUsd outMetaType outMetaIds"
    }

    

    return (
        <div>
            <Layout noContainer={true}>
                <Helmet>
                    <title>CHROMA | Dashboard</title>
                </Helmet>

                {/* Header Image */}
                <Hero 
                    height = {111}
                    primaryImageSource = {headerImage}
                    backgroundPosition = "center center"
                    color1 = "rgba(66, 9, 126, .8)"
                    color2 = "rgba(66, 9, 126, 1)"
                    />
                
                <div className="container">
                    <div className={styles.Dashboard}>
                        <div className="row">
                            {/* Right Column */}
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <UsersInfo className={styles.usersInfo} />
                                <WalletStatus className={styles.walletStatus} />
                            </div>
                            <div className="col-lg-8">
                                <QueryBuilder 
                                    className={styles.products}
                                    header="📦 products"
                                    chromaQL={chromaQLProducts}
                                    empty={<NoProducts />}
                                />

                                {_.get(products, "length", 0) > 0 &&
                                    <p className={styles.infoSubtext}>
                                        <FontAwesomeIcon icon={faDesktop} className={styles.icon} />
                                        Download the <Link to="/download">CHROMA Hub</Link> desktop software to install products.
                                    </p>
                                }

                                <QueryBuilder 
                                    className={styles.assets}
                                    header="🖼️ assets"
                                    chromaQL={chromaQLAssets}
                                />

                                <p className={styles.infoSubtext}>
                                    <FontAwesomeIcon icon={faWallet} className={styles.icon} />
                                    Assets can be linked from upcoming NFTs and future product releases.
                                </p>

                                <QueryBuilder 
                                    className={styles.transactions}
                                    header="🎫 transactions"
                                    chromaQL={chromaQLTransactions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default observer(Dashboard)