/* Dashboard */

/* Core */
import {useCallback} from 'react'
import { Helmet } from 'react-helmet-async'

import Layout from "../components/layout/Layout"

/* Checkout */
import CreditCardForm from "../components/payment/CreditCardForm"

function CheckoutDev(props) {

    return (
        <Layout>
            <Helmet>
                <title>CHROMA | Checkout Dev</title>
            </Helmet>
            <div className="dashboard">
                <div className="container">
                    <h1>Checkout Dev</h1>

                    <CreditCardForm />

                    
                </div>
            </div>
        </Layout>
    )
}

export default CheckoutDev