/* Core */
import { useEffect, useState } from 'react'
import RoutesView from "./Routes"

/* Abilities */
import { Ability, AbilityBuilder } from '@casl/ability'
import { unpackRules } from '@casl/ability/extra'

/* Data */
import { observer } from 'mobx-react'
import { RootStoreProvider } from "./context/RootStoreContext"

/* Providers */
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ToastProvider } from 'react-toast-notifications'
import { AbilityContext } from './abilities/Can'

import TagManager from 'react-gtm-module';

/* Media */
import './App.scss'
import './theme/default.css'

/* Components */
import { ThemeProvider, createTheme } from '@mui/material/styles';
import URLParams from 'components/utilities/URLParams'

/* Utilities */


const axios = require('axios')
const _ = require('lodash')

// window.localStorage.chroma_endpoint = "http://localhost:3030"


/* Parameters */

// Create Empty helmetContext
const helmetContext = {}


/* Set initial theme on container div */
const getInitialTheme = (rootStore) => {
	return "dark-theme"
//   return (rootStore.SettingsStore.theme === "light") ? "dark-theme" : "light-theme"
}

/* Get initial abilities from chroma */
const userAbility = new AbilityBuilder(Ability)
userAbility.can('manage', 'all')

/* Create Dark Mode Theme for MUI */
const darkTheme = createTheme({
	palette: {
	  mode: 'dark',
	},
});

function App(props) {
	/* Local State */
	const [ability, setAbility] = useState(new Ability(userAbility.rules))

	useEffect(() => {
		TagManager.initialize({ gtmId: 'GTM-KT6MMTW'})
	})

	useEffect(() => {
		/* Get initial abilities from chroma */
		const getAbility = async () => {
			// const userAbility = new AbilityBuilder(Ability)

			/* Only do if they have a token */
			if(_.get(window, "localStorage.chroma_token")){
				let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"
				let chroma_ability_endpoint =  chroma_endpoint + "/users/roles"
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
				}
	
				const abilitiesQuery = await axios.post(chroma_ability_endpoint, {}, {headers: headers, timeout: 0})
				let abilityData = _.get(abilitiesQuery, "data", false)
				
				const ability = new Ability()
				ability.update(abilityData.ability)
				
				setAbility(ability)
			}
		}

		getAbility()
	}, [setAbility])

	// console.log("ability", ability)

	return (
		// mobx-state-tree Context
		<RootStoreProvider value={props.rootStore}>
			<URLParams />

			{/* CASL Ability Context */}
			<AbilityContext.Provider value={ability}>

				{/* Helmet Context */}
				<HelmetProvider context={helmetContext}>
					{/* Default Helmet config */}
					<Helmet>
						<title>CHROMA | Tap into your flowstate</title>
					</Helmet>

					{/* Theme Provider (MUI) */}
					<ThemeProvider theme={darkTheme}>

						{/* Toast Context */}
						<ToastProvider
							autoDismiss
							autoDsmissTimeout={6000}
							placement="bottom-center">

							{/* Actual frontend container */}
							<div id="chroma-frontend" className={getInitialTheme(props.rootStore)}>
								{<RoutesView />}
							</div>

						</ToastProvider>
					</ThemeProvider>

				</HelmetProvider>

			</AbilityContext.Provider>

		</RootStoreProvider>
	)
}

export default observer(App)
