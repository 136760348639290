/* Form Edit Dashboard */

/* Core */
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStores } from "../../context/RootStoreContext"

/* Data */
import chroma from "@chromaplatform/chromajs"

/* Components */
import TransactionListItem from "CHROMA-React/components/list-items/transaction"

/* Media */
import styles from "./AffiliateDash.module.css"
import CopyableText from "components/utilities/CopyableText"

const _ = require('lodash')

function AffiliateDash() {
    const rootStore = useStores()
    const [affiliateData, setAffiliateData] = useState([])
    const [affiliateId, setAffiliateId] = useState(rootStore.AuthStore.userId)
    const [affiliatePercentage, setAffiliatePercentage] = useState(0)
    const [affiliatePaid, setAffiliatePaid] = useState(0)
    const [affilaiteUrl, setAffiliateUrl] = useState(`https://chroma.vision/products?aid=${affiliateId}`)

    /* Get Affiliate data */
	useEffect(() => {
        const getAffiliateSales = async () => {
            const affiliateQuery = {
                service: "transactions",
                query: {
                    status: "complete",
                    'affiliate.affiliateId': rootStore.AuthStore.userId
                },
                fields: "_id createdAt amountUsd txId affiliate status outMetaIds inId"
            }
    
            const affiliateInfo = await chroma.find(affiliateQuery)

            setAffiliateData(affiliateInfo)
        }

        const getUserAffiliateStatus = async () => {
            const usersQuery = {
                service: "users",
                id: rootStore.AuthStore.userId,
                fields: "_id username meta{affiliate}"
            }
    
            const userAffiliateInfo = await chroma.get(usersQuery)

            setAffiliatePercentage(_.get(userAffiliateInfo, "meta.affiliate.percentage", 0))
            setAffiliatePaid(_.get(userAffiliateInfo, "meta.affiliate.totalPaid", 0))
        }

        getAffiliateSales()
        getUserAffiliateStatus()
    }, [chroma, setAffiliateData, setAffiliatePercentage, setAffiliatePaid])

    
  
    return(
        <div>
            {/* Metamask */}
            <div className={styles.Container}>
                <h2 className={styles.title}>Affiliate Sales</h2>
                <p>Affiliate ID: <strong>{affiliateId}</strong>.</p>
                <p>Percentage: <strong>{affiliatePercentage}%</strong></p>
                <p>Total Paid Out: <strong>${affiliatePaid}</strong></p>
                <CopyableText className={styles.CopyLink} text={affilaiteUrl} copytext={affilaiteUrl} tooltip="Click to copy your link" position="top" color="#333" background="#fff" />

                <hr />

                <div className={styles.SalesContainer}>
                    <h3>Unpaid Sales</h3>
                    <div className={styles.AffiliateList}>
                        {affiliateData && affiliateData.map((data, i) => {
                            if(!data.affiliatePaid){
                                return <TransactionListItem key={i} item={data} />
                            }
                        })}
                    </div>

                    <h3>Paid Sales</h3>
                    <div className={styles.AffiliateListPaid}>
                        {affiliateData && affiliateData.map((data, i) => {
                            if(data.affiliatePaid){
                                return <TransactionListItem key={i} item={data} />
                            }
                        })}
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default observer(AffiliateDash)