/* Dashboard */

/* Core */


/* Data */

/* Components */
import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"
import MetaTags from "../components/utilities/MetaTags"

/* Media */
import styles from './Download.module.css'

import logoDark from '../media/images/logo-tall-dark.png'
import logoLight from '../media/images/logo-tall-light.png'

function Lost(props) {


    return (
        <Layout noContainer="true">
            <MetaTags
                title="CHROMA | 404"
            />
            <div className={styles.Home}>
                

                {/* Lost? */}
                <Hero 
                    title = "Lost?"
                    description = "These are not the droids you are looking for"
                    button = "Return Home"
                    buttonPath = "/"
                    height = {666}
                    primaryImageSource = "https://chroma-media-platform.s3-us-west-1.amazonaws.com/images/platform/misc/Lost_Desert.jpg"
                    backgroundPosition = "right top"
                    // altImageSource = "https://manual.microdosevr.com/chroma-logo-dark.png"
                    // removeAltImageShadow = {true}
                    color1 = "rgba(66, 9, 126, .55)"
                    color2 = "rgba(66, 9, 126, .9)"
                    />

            </div>
        </Layout>
    )
}

export default Lost