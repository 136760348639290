/* Header */

/* Components */
import { Badge } from 'antd'


/* Media */
import styles from './VideoEmbed.module.css'

function VideoEmbed(props) {
    if(!props.playlistid){
        return null
    }
    return (
        
            <div className={styles.videoembed}>
                <div className={`${styles.videoembedcard} card`}>
                    <div className={styles.videoembedheader}>
                        {/* <span className={`${styles.live} badge bg-danger`}>LIVE</span> */}
                        {props.title || ""}
                    </div>
                    <div className={styles.videoembedbody}>
                        <div className={styles.videoembedstream}>
                            {/* <iframe src="https://www.youtube.com/embed/e0UDhXT5WxM?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen /> */}
                            <iframe width="560" height="315" src={`https://www.youtube.com/embed/videoseries?list=${props.playlistid}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default VideoEmbed