import { _ } from "lodash"
import { chromaEndpoint, chromaHeaders } from "./_headers"

const find = async (query) => {
    const endpoint = `${chromaEndpoint}/query/find`
    const findQuery = {
        method: 'POST', headers: chromaHeaders,
        body: JSON.stringify(query)
    }

    // This is a quick return if the query is not actually proper
    if(typeof query.service !== "string"){
        return []
    }
    
    const findRequest = await fetch(endpoint, findQuery)
    const response = findRequest.json()

    // console.log('> chroma.find:', findQuery, response)

    return response
}

export default find