/* Core */
import _ from 'lodash'

/* Media */
import styles from './asset.module.css'

const AssetListItem = (props) => {
    const { item } = props

    return (
        <div {...props} className={styles.listItemContainer}>
            <h2 className={styles.name}>{_.get(item, "copy.en.title", "N/A")}</h2>
            <p className={styles.description}>{_.get(item, "copy.en.description", "N/A")}</p>
        </div>
    )
}

export default AssetListItem