/* Framework */
import { types } from 'mobx-state-tree'

import ReactGA from 'react-ga4'

/* Models */
import * as BaseModel from '../structure/BaseModel'

import UserModel from '../models/User/UserModel'

import chroma from '@chromaplatform/chromajs'
import analyticEvent from 'CHROMA-React/code/_analytics'

/* AuthStore Properties */
const props = {
    address: types.optional(types.string, "/root/auth"),
    namespace: types.optional(types.string, "auth"),
    authenticated: types.optional(types.boolean, false),
    userId: types.optional(types.string, ""),
    token: types.optional(types.string, ""),
    user: types.optional(UserModel, {})
}

/* AuthStore Actions */
const actions = (self) => ({
    authenticate(userId, token) {
        if(token){
            self.token = token
            self.userId = userId
            self.authenticated = true
            
            // Set token in chromajs
            chroma.setToken(token)
    
            // Grab initial user data
            self.user.hydrate(self.userId, self.token)

            analyticEvent("User_Authenticated", {})
            // ReactGA.event({
            //     category: 'Dashboard',
            //     action: 'User Authenticated'
            // })
        }
    },
    logout() {
        self.reset()

        analyticEvent("User_Logged_Out", {})

        // ReactGA.event({
        //     category: 'Dashboard',
        //     action: 'User Logged Out'
        // })

        chroma.cache.flushCache()
    }
})

/* AuthStore Model */
const AuthStore = types
  .model('SettingsStore')
  .props(props)
  .actions(actions)
  /* BaseModel */
  .props(BaseModel.props)
  .views(BaseModel.views)
  .actions(BaseModel.actions)
  /* Store (must come at end otherwise may be overridden) */
  .props(props)
  .actions(actions)


export default AuthStore


