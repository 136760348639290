import { props } from 'data/structure/BaseModel'
import _ from 'lodash'
import { Area, AreaChart, LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

/* Media */
import styles from './SimpleLineChart.module.css'

const UserToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const amount = _.get(payload[0], "value", 0)
        const title = _.get(payload[0], "payload.title", "")
        return (
            <div className={styles.customToolTip}>
                <h1>{amount}</h1>
                <p className="label">{title}</p>
            </div>
        )
    }
  
    return null
}

export default function SimpleLineChart(props) {
    const data = _.get(props, "data", [])
    const height = _.get(props, "height", 60) // default to 60

    return (
        <ResponsiveContainer style={_.get(props, "style")} width="100%" height={height} className={`${props.className} ${styles.SimpleLineChartContainer}`} >
            <AreaChart data={data}>
                <defs>
                    <linearGradient id="amount" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgba(136, 132, 216, 1)" stopOpacity={.1}/>
                        <stop offset="100%" stopColor="rgba(136, 132, 216, 1)" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                {/*<Line
                    type="natural"
                    dataKey="amount"
                    // dot={false}
                    stroke="rgba(255,255,255,0.4)"
                    strokeWidth={3}
                    activeDot={{ stroke: 'rgba(255,255,255,0.2)', r: 5}}
                />*/}
                <Area 
                    type="monotone"
                    dataKey="amount"
                    fillOpacity={1}
                    fill="url(#amount)"
                    margin={{
                        top: 100,
                        right: 100,
                        left: 0,
                        bottom: 0,
                    }}
                    stroke="rgba(136, 132, 216, .6)"
                    strokeWidth={3}
                    />
                <Tooltip 
                    content={<UserToolTip />}
                    />
            </AreaChart>
        </ResponsiveContainer>
    )
}