/* 
    const chromaQL = {
        service: "products",
        query: { _id: { $in: [productId1, productId2] } },
        fields: "slug copy meta{buildType}"
    }
    <ChromaList query={chromaQL} />

    - Determines template to use based on query service
    - Optional config object to modify behavior
        - override template
        - 

*/

/* Core */
import { useEffect, useState } from "react"

import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";

import chroma from '../code/chroma'
import templates from "CHROMA-React/config/templates"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Media */
import styles from './chroma-list-static.module.css'

import _ from 'lodash'

function ChromaListStatic(props) {
    const [listData, setListData] = useState([])

    let limit = _.get(props, "limit", 0)
    let items = _.get(props, "items")
    let service = _.get(props, "service")
    let header = _.get(props, "header")

    const getTemplate = (item, i) => {
        
        const type = _.get(item, "type")
        
        // TEMP RETURN DEFAULT FOR ALL
        let Template

        if(type){
            Template = _.get(templates, `${service}.${type}-list`)
        } else {
            // no type present, use default
            Template = _.get(templates, `${service}.default-list`)
        }
        if(Template){
            return <Template key={i} item={item} />
        } else {
            Template = _.get(templates, `default`)
            return <Template key={i} item={item} />
        }

    }

    return <div className={styles.ChromaListContainer}>
        {header &&
            <h2 className={styles.header}>
                {header}
                {/*count &&
                    <span>({count})</span>
                */}
            </h2>
        }
        <div>
            {items && items.map((item, index) => {
                const listTemplate = getTemplate(item, index)
                return listTemplate
            })}
        </div>
    </div>
}

export default ChromaListStatic