/* Core */
import { Link, withRouter } from 'react-router-dom'

import _ from 'lodash'

/* Media */
import styles from './default.module.css'

const moment = require('moment')

const DefaultListItem = (props) => {
    const { item, service, type, num, groupKey } = props
    const url = `/${service}/${item._id}`
    let data = []

    const image = _.get(item, 'meta.files[0].path')

    for (const [key, value] of Object.entries(item)) {
        if(typeof value !== 'object') {
            // Key Value
            data.push({key: key, value: value})
        } else {
            // Object
            // TODO :: Explore Objects Also
            data.push({key: key, value: JSON.stringify(value)})
        }
    }

    return (
        <div {...props} key={num} className={styles.listItemContainer}>
            <span className={`${styles.type} badge rounded-pill text-bg-light`}>{type ? "" : service}{type}</span>

            {/* Try Images */}
            {image &&
                <img src={image} className={styles.thumbnail} />
            }
            {data.length > 0 && 
                data.map((item, index) => {
                    if(item.key === "createdAt" && _.get(item, "value")){
                        // return readable date
                        return(
                            <p key={index} className={styles.entry}>
                                <span>{item.key}:</span> {moment(_.get(item, "value") * 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                            </p>
                        )
                    } else {
                        // Return regular fields
                        return(
                            <p key={index} className={styles.entry}>
                                <span>{item.key}:</span> {item.value}
                            </p>
                        )
                    }
                })
            }
            {service !== "transactions" &&
                <Link className={`${styles.Button} btn btn-primary`} to={url}>View</Link>
            }
        </div>
    )
}

export default DefaultListItem