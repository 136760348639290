/* Dashboard */

import ReactGA from 'react-ga4'

/* Core */
import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import CopyableText from '../components/utilities/CopyableText'
import Layout from "../components/layout/Layout"
import MetaTags from "../components/utilities/MetaTags"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faShoppingCart, faDollarSign, faMoneyCheck, faCheckCircle, faCloudDownloadAlt, faCheckSquare, faDesktop, faVrCardboard } from '@fortawesome/free-solid-svg-icons'
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'

import { Icon } from '@iconify/react'
import btcIcon from '@iconify/icons-cryptocurrency/btc'
import ltcIcon from '@iconify/icons-cryptocurrency/ltc'
import ethIcon from '@iconify/icons-cryptocurrency/eth'
import usdtIcon from '@iconify/icons-cryptocurrency/usdt'
import trxIcon from '@iconify/icons-cryptocurrency/trx'
import xmrIcon from '@iconify/icons-cryptocurrency/xmr'

import Accordian from 'components/data/Accordian'

import analyticEvent from 'CHROMA-React/code/_analytics'

/* Media */
import styles from './Products.module.css'

import logomark from '../media/images/logomark-smooth.png';

import obtainCryptoImage from '../media/images/crypto/noun-ethereum-4618636.png';
import howCryptoImage from '../media/images/crypto/noun-sitting-fox-eth-4332366.png';
import ethAsset from '../media/images/crypto/ethasset.png';
import polygonAsset from '../media/images/crypto/polygonasset.png';
import ethCollection from '../media/images/crypto/eth-collection.jpg';
import polygonCollection from '../media/images/crypto/polygon-collection.jpg';



const _ = require('lodash')

analyticEvent("Product_Viewed", {})
// ReactGA.event({
//     category: 'Dashboard',
//     action: `Product Viewed`
// })


function Products(props) {
    const rootStore = useStores()
    const history = useNavigate()

    /* Setup */
    const useStyles = makeStyles((theme) => ({
        '@global': {
          ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
          },
        },
        appBar: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        toolbar: {
          flexWrap: 'wrap',
        },
        toolbarTitle: {
          flexGrow: 1,
        },
        link: {
          margin: theme.spacing(1, 1.5),
        },
        heroContent: {
          padding: theme.spacing(8, 0, 6),
        },
        cardHeader: {
          backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
        },
        cardPricing: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
          marginBottom: '18px',
          marginTop: '18px',
        },
        footer: {
          borderTop: `1px solid ${theme.palette.divider}`,
          marginTop: theme.spacing(8),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
          },
        },
    }))

    const gotoCheckout = (productId) => {
        history(`/products/checkout`)
    }

    const addProduct = (productId) => {
        if(!rootStore.Cart.hasProduct(productId)){
            // Add to cart
            rootStore.Cart.addProduct(productId)
        }

        // Wait for a moment and send to checkout
        setTimeout(() => {
            gotoCheckout()
        }, 200)
    }

    const product = {
        title: 'Microdose VR',
        subheader: 'Full Release',
        productId: '62cc47508b3d1d3f054f14c5',
        price: '50',
        description: [
          'Explore the many worlds of the Microverse',
          'All New Gamepad Mode (No VR required)',
          'Regular Updates with new worlds & brushes',
          '360º output for domes & planetariums',
          'Access to experimental Beta build',
          'Includes future updates',
          'Much more...',
        ],
        buttonText: 'Add To Cart',
        buttonVariant: 'contained'
    }

    const classes = useStyles()

    return (
        <Layout noContainer="true">
            <MetaTags
                title="CHROMA | Products"
            />
            <div className={styles.ProductsContainer} dataproductscount={rootStore.Cart.products.length}>
                
                {/* Hero unit */}
                <div className={`${styles.ProductsHero} container`} >
                    <h2 className={styles.ProductsHeroHeader}>
                        Products
                    </h2>
                    <p className={styles.ProductsHeroText}>
                        Microdose now available without VR.
                    </p>
                </div>


                {/* Products Content */}
                <div className={`${styles.ProductsContent} container`}>
                    {/* <div className={styles.AlphaAlert}>
                        <FontAwesomeIcon className={styles.WarningIcon} icon={faUserAstronaut} />
                        Purchasing products is temporarily restricted to our Alpha test group members.
                    </div> */}
            
                    {/* Product */}
                    <div className={`row ${styles.Product}`} >
                        <Card className={styles.PriceCard}>                            
                            <div className={`${styles.ProductColumnContainer} row`}>
                                <div className={`${styles.LeftColumn} col-12 col-md-7`}>
                                    <h2 className={styles.cardTitle}>Microdose VR</h2>
                                    <h3 className={styles.cardSubheader}>Full Release</h3>
                                    
                                    <h1 className={styles.ProductPrice}>$50</h1>
                                    <p className={styles.Intro}>
                                        Explore the worlds of the Microverse with this download for Windows 10.
                                    </p>
                                    <div className={styles.Description}>
                                        <p>
                                            <FontAwesomeIcon className={styles.DescriptionIcon} icon={faCloudDownloadAlt} />
                                            All new Gamepad Mode (No VR Required)
                                        </p>

                                        <p>
                                            <FontAwesomeIcon className={styles.DescriptionIcon} icon={faCheckSquare} />
                                            Includes all future updates
                                        </p>
                                        
                                        <p>
                                            <FontAwesomeIcon className={styles.DescriptionIcon} icon={faDesktop} />
                                            For Gaming* PCs
                                        </p>

                                        <p>
                                            <FontAwesomeIcon className={styles.DescriptionIcon} icon={faVrCardboard} />
                                            Optional VR* Mode Support
                                        </p>

                                    </div>
                                    <p style={{fontSize:"12px"}}>*Requirements below</p>
                                </div>

                                <div className={`${styles.CenterColumn} col-1 col-md-1 d-md-none d-lg-block`}></div>

                                <div className={`${styles.RightColumn} col-12 col-md-5 col-lg-4`}>
                                    <img src={logomark} className={styles.LogoMark} />

                                    {/* Does not have product, show button */}
                                    {!rootStore.AuthStore.user.hasProduct(product.productId) &&
                                        <a onClick={() => {addProduct(product.productId)}} className={`${styles.BuyButton}`} variant={product.buttonVariant} color="primary">
                                            <FontAwesomeIcon className={styles.CheckoutIcon} icon={faShoppingCart} />
                                            Buy With Credit Card
                                        </a>
                                    }

                                    {/* Has product, show in library button */}
                                    {rootStore.AuthStore.user.hasProduct(product.productId) &&
                                        <div>
                                            <Button onClick={() => {addProduct(product.productId)}} className={`${styles.ButtonInLibrary}`} color="primary">
                                                <FontAwesomeIcon className={styles.CheckoutIcon} icon={faCheckCircle} /> In Library
                                            </Button>
                                            <br />
                                            <Link to="/download" className={styles.DownloadLink}>
                                                <FontAwesomeIcon className={styles.CheckoutIcon} icon={faCloudDownloadAlt} />
                                                Download
                                            </Link> 
                                        </div>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>

                    {/* Obtain w/ Crypto */}
                    <div className={`row ${styles.CryptoContainer}`}>
                        <div className={`col-12 col-md-3 col-lg-2 ${styles.CryptoImageContainer}`}>
                            <img src={obtainCryptoImage} className={styles.ObtainCryptoImage} />
                        </div>
                        <div className={`col-12 col-md-9 col-lg-10 ${styles.ObtainCrypto}`}>
                            <h2>Obtain with Crypto</h2>
                            <p>
                                Now you can obtain Microdose VR as an NFT. Every Microdose NFT is a transferable license to download and own the Full Release.
                            </p>
                        </div>
                    </div>

                    {/* Polygon Collection */}
                    <div className={`row ${styles.CryptoCollectionContainer}`}>
                        <div className={`col-12 col-md-6`}>
                            <h2>Polygon Collection</h2>
                            <p>
                                Sets of renders from Microdose VR are available on OpenSea in the Microdose VR Polygon Collection.
                            </p>

                            <p>
                                Created by Microdose VR founders and stored on the Polygon (MATIC) blockchain.
                            </p>

                            <p>
                                The Polygon Collection gives you a full <strong>Microdose VR Transferable License</strong> and an <strong>Image License</strong> to use your NFT for personal or commercial purposes.
                            </p>
                        </div>
                        <div className={`${styles.CenterColumn} col-12 col-md-1`}></div>
                        <div className={`col-12 col-md-5`}>
                            <img src={polygonCollection} className={styles.CryptoCollection} />

                            
                            <div style={{textAlign:"center", marginTop: "24px"}}>
                                <a href="https://opensea.io/collection/microdose-vr-polygon" target="_blank" className={`${styles.ObtainButton}`}>
                                    <FontAwesomeIcon className={styles.CheckoutIcon} icon={faShoppingCart} />
                                    Obtain Polygon NFT
                                </a>
                            </div>

                            {/*<div style={{textAlign:"center", marginTop: "24px"}}>
                                <img src={polygonAsset} className={styles.CryptoAssetIcon} />
                            </div>*/}
                        </div>
                    </div>

                    {/* Unlock w/ Crypto */}
                    <div className={`row ${styles.CryptoContainer}`}>
                        <div className={`col-12 col-md-3 col-lg-2 ${styles.CryptoImageContainer}`}>
                            <img src={howCryptoImage} className={styles.HowCryptoImage} />
                        </div>
                        <div className={`col-12 col-md-9 col-lg-10 ${styles.ObtainCryptoHow}`}>
                            <h2>How to unlock Microdose VR with NFTs?</h2>
                            <ol>
                                <li>Buy an <a href="https://opensea.io/microdose" target="_blank">Official Microdose VR NFT</a></li>
                                <li>Login and connect your Metamask to your <a href="https://chroma.vision/dashboard">CHROMA account</a></li>
                                <li>Download will be unlocked in <a href="https://chroma.vision/download"></a>CHROMA Hub</li>
                            </ol>

                            {/* Link for unauthed visitors */}
                            {!rootStore.AuthStore.authenticated &&
                                <Link to="/login" className={`${styles.LoginButton}`}>
                                    Login To Get Started
                                </Link>
                            }

                            {/* Link for authed users */}
                            {rootStore.AuthStore.authenticated &&
                                <Link to="/settings" className={`${styles.LoginButton}`}>
                                    Link Metamask To Get Started
                                </Link>
                            }
                        </div>
                    </div>

                    {/* Info */}
                    <div className={styles.InfoContainer}>
                        <Accordian />
                    </div>
                    
                </div>

            </div>
        </Layout>
    )
}

export default observer(Products)