/* <ProductsList /> */

import { useEffect, useState } from "react"

import { useStores } from "../../context/RootStoreContext"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */

import CircularProgress from '@material-ui/core/CircularProgress'

import { Icon } from '@iconify/react'
import btcIcon from '@iconify/icons-cryptocurrency/btc'
import ethIcon from '@iconify/icons-cryptocurrency/eth'
import ltcIcon from '@iconify/icons-cryptocurrency/ltc'

import styles from './TransactionList.module.css'
import { Link } from "react-router-dom"

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

const chroma_endpoint = "https://api.chroma.vision"
const chroma_tx_endpoint = chroma_endpoint + "/transactions"
const chroma_products_endpoint = chroma_endpoint + "/products"


function TransactionList(props) {
    const rootStore = useStores()

    /* Local State */
    const [transactions, setTransactions] = useState([])

    /* Get Transaction data */
    // TODO :: Connect this to actual transaction data
    useEffect(() => {
        const getUserTransactions = async () => {
            const query = {
                inId: rootStore.AuthStore.userId
            }
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
            }
    
            const transactionQuery = await axios.get(chroma_tx_endpoint,{params: query, headers: headers, timeout: 0})
            let transactions = _.get(transactionQuery, "data.data", [])

            // Get products for each transaction
            for (let i = 0; i < transactions.length; i++) {
                const transaction = transactions[i]
                transactions[i].products = []

                for (let j = 0; j < transaction.outMetaIds.length; j++) {
                    const productEntryId = transaction.outMetaIds[j]
                    
                    const productsQuery = await axios.get(`${chroma_products_endpoint}/${productEntryId}`,{headers: headers, timeout: 0})
                    const product = _.get(productsQuery, "data", [])
                    
                    transactions[i].products.push(product)
                }

                
            }
            
            setTransactions(transactions)
        }

        getUserTransactions()
    }, [chroma, rootStore, setTransactions])

    const getCurrencyIcon = (currency) => {
        let icon
        switch(currency){
            case "ltct":
                return <Icon icon={ltcIcon} alt={currency} className={`${styles.CurrencyIcon} ${styles.CurrencyIconLtc}`} />
            case "btc":
                return <Icon icon={btcIcon} alt={currency} className={`${styles.CurrencyIcon} ${styles.CurrencyIconBtc}`} />
            case "eth":
                return <Icon icon={ethIcon} alt={currency} className={`${styles.CurrencyIcon} ${styles.CurrencyIconEth}`} />
        }
        
    }

    const getTransactionUI = (transaction, i) => {
        return (
            <div key={i} className={`${styles.Transaction} container`}>
                <div className="row">
                    <div className={`${styles.Title} col-4`}>
                        {/* <strong>{transaction.outMetaType}</strong> */}
                        <Link className={styles.TxLink}
                            to={`/tx/${transaction._id}`}>
                                {transaction.products.map((el) => _.get(el, "copy.en.title")).join(', ')}
                            </Link>
                        {/* {transaction.products.map((product, i) => {
                            return(_.get(product, 'copy.en.title') + " - ")
                        })} */}
                    </div>
                    <div className={`${styles.Currency} col-4`}>
                        {parseFloat(transaction.amount).toFixed(6)} {transaction.medium.toUpperCase()}
                        {getCurrencyIcon(transaction.medium)}
                    </div>
                    <div className={`${styles.Tx} col-3`}>
                        {transaction.inMeta.gatewayTxId ? <span className={styles.TxLabel}>tx</span> : null }
                        <a href={`tx/${transaction._id}`}>
                            {transaction.inMeta.gatewayTxId || "Pending"}
                        </a>
                    </div>
                    <div className={`${styles.Date} col-3`}>{moment(transaction.createdAt * 1000).format("MM/DD/YYYY")}</div>
                </div>
            </div>
        )
    }
    
    // if(_.get(transactions, "length") < 1)
    //     return(<div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>)

    return(
        <div className={styles.Transactions}>
            {transactions.map((item, i) => {
                return getTransactionUI(item, i)
            })}
            {transactions.length < 1 ? <p className={styles.Empty}>You do not have any transactions</p> : ""}
        </div>
    )
}

export default TransactionList
