/* Footer */

/* Router */
import { Link } from "react-router-dom"

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Media */
import styles from './Footer.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookDead, faBookmark, faBookOpen, faEnvelope, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'

import vaLogoLight from '../../media/images/vision-agency-logo-dark.png'
import EmailLink from '../../components/utilities/EmailLink'

function Footer(props) {
    const rootStore = useStores()

    return (
        <footer>

            <div className="container">
                <div className="row align-items-start">

                    {/* Socials */}
                    <div className="col-sm">
                        <div className={styles.Icons}>
                            <a href="https://youtube.com/microdosevr" style={{marginLeft: "0px"}}>
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href="https://facebook.com/microdosevr">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://instagram.com/microdosevr">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <EmailLink address="admin@microdosevr.com">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </EmailLink>
                            <a href="https://manual.microdosevr.com">
                                <FontAwesomeIcon icon={faBookOpen} />
                            </a>
                            <a href="https://microdosevr.com">
                                <FontAwesomeIcon icon={faUserAstronaut} />
                            </a>
                        </div>
                    </div>

                    {/* Microdose Logo */}
                    <div className="col-sm">
                        {/* <img src={mdvrLogoLight} alt="Microdose VR Logo" className="footer-logo-mdvr" /> */}
                    </div>

                    {/* Vision Agency Logo */}
                    <div className={`${styles.FooterLogo} col-sm`}>
                        <a href="https://visionagency.net" style={{ border: 'none' }}>
                            <img src={vaLogoLight} alt="Vision Agency Logo" />
                        </a>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer