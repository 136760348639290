/* Core */
import { faFlask, faHammer, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

/* Media */
import styles from './product.module.css'

const ProductListItem = (props) => {
    const { item } = props
    // console.log(item)

    const getProductIcon = (type) => {
        // eslint-disable-next-line default-case
        switch(type){
            case "alpha":
                return <FontAwesomeIcon icon={faHammer} className={styles.productIcon} />
            case "beta":
                return <FontAwesomeIcon icon={faFlask} className={styles.productIcon} />
            case "omega":
                return <FontAwesomeIcon icon={faUserAstronaut} className={styles.productIcon} />
        }
    }

    return (
        <div {...props} className={styles.listItemContainer}>
            {getProductIcon(_.get(item, "meta.buildType[0]"))}
            <div className={styles.infoContainer}>
                <h2 className={styles.name}>{_.get(item, "copy.en.title", "N/A")}</h2>
                <p className={styles.description}>{_.get(item, "copy.en.description", "N/A")}</p>
            </div>
            
        </div>
    )
}

export default ProductListItem