/* Form Edit Dashboard */

/* Core */
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStores } from "../../context/RootStoreContext"

/* Data */
import chroma from "@chromaplatform/chromajs"

/* Components */
import LinkMetamask from "../nft/LinkMetamask"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen, faRedo } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from "./CryptoDash.module.css"
import UnlinkWallet from "components/nft/UnlinkWallet"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebook, faInstagram, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons'

import metamaskImage from '../../media/images/misc/metamask-fox.svg';
import { chromaEndpoint } from "CHROMA-React/code/_headers"
import QueryBuilder from "components/query-builder/QueryBuilder"

const axios = require('axios')
const _ = require('lodash')

function CryptoDash() {
    const rootStore = useStores()
    const [ethAddress, setEthAddress] = useState()
    const [walletLinked, setWalletLinked] = useState(false)
    const [assets, setAssets] = useState([])
    const [associatedAssets, setAssociatedAssets] = useState([])
    const [disabledRefreshButton, setDisabledRefreshButton] = useState(false)

    useEffect(() => {
        refreshAssets("")
    }, [])




    /* Components */
    const getLinkUI = () => {
        if(walletLinked){
            return getLinkedUI()
        } else {
            return getUnlinkedUI()
        }
    }
    const getLinkedUI = () => {
        return(
            <div className={`${styles.linkContainer} ${styles.unlinkContainer}`}>
                <UnlinkWallet callback={unlinkCallback} className={styles.unlinkButton}/>
                <h3>Wallet Connected ✔️</h3>
                <p className={styles.ethAddress}>{ethAddress}</p>
            </div>
        )
    }

    const getUnlinkedUI = () => {
        return(
            <div className={styles.linkContainer}>
                <img src={metamaskImage} className={styles.metamaskLogo} alt="Metamask" />
                <h3>Link your Ethereum wallet</h3>
                <p>Own Beta? <a href="https://metamask.io/download/" target="_blank">Link your wallet</a>, and we'll convert your purchase to a Microdose NFT* with transferable access to Microdose.</p>
                <p className={styles.linkFinePrint}>* Airdrop of Microdose NFTs will happen at an upcoming date</p>
                <LinkMetamask callback={linkCallback} />
            </div>
        )
    }

    // When metamask is linked, update UI
    const linkCallback = (linked) => {
        setWalletLinked(linked)
        refreshAssets()
    }
    const unlinkCallback = () => {
        setWalletLinked(false)
        setEthAddress("")
    }

    const refreshWallet = async () => {
        let chroma_endpoint = chromaEndpoint || window.localStorage.chroma_endpoint || "https://api.chroma.vision"
        let chroma_refresh_endpoint =  chroma_endpoint + "/metamask/refresh"
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
        }

        const refreshQuery = await axios.post(chroma_refresh_endpoint, {}, {headers: headers, timeout: 0})
        let refreshData = _.get(refreshQuery, "data", false)
    }

    // Returns an asset from chroma
    const getAsset = async (assetId) => {
        let chroma_endpoint = window.localStorage.chroma_endpoint || "https://api.chroma.vision"

        const query = {
            service: "assets",
            id: assetId,
            fields: "_id meta"
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + _.get(window, "localStorage.chroma_token")
        }

        const chroma_query_endpoint = chroma_endpoint + '/query/get'
        const assetRequest = await axios.post(chroma_query_endpoint, query, {headers: headers, timeout: 0})
        // console.log('asset', assetRequest)
        return _.get(assetRequest, "data")
    }

    // Refresh Assets Button
    const refreshAssets = async (e) => {
        setDisabledRefreshButton(true)
        await refreshWallet()

        const query = {
            service: "users",
            id: rootStore.AuthStore.userId,
            fields: "username ethId meta{assets}"
        }

        const data = await chroma.get(query);

        const newEthId = _.get(data, "ethId")
        const assets = _.get(data, "meta.assets")

        // console.log('data', data)

        if(newEthId){
            setEthAddress(newEthId)
            setWalletLinked(true)
        }

        if(assets){
            // console.log('assets', assets)
            // Filter only nft assets
            const nftAssets = assets.filter((asset) => {
                return asset.type === "nft" || asset.type === "poap"
            })

            let assetObjects = []
            for (let i = 0; i < nftAssets.length; i++) {
                const asset = nftAssets[i];
                const assetQuery = await getAsset(_.get(asset, "assetId"))
                assetObjects.push(assetQuery)
            }
            
            setAssets(nftAssets)

            const allAssociatedAssets = assets.filter((assetz) => {
                return assetz.type !== "nft"
            })
            setAssociatedAssets(allAssociatedAssets)
        }

        setDisabledRefreshButton(false)

    }

    const getAssetsUiLegacy = () => {
        if(walletLinked){
            return(
                <div className={styles.chromaNfts}>
                    {/* NFT Assets */}
                    <div style={{marginBottom: 24}}>
                        <h3 style={{marginBottom: "48px"}}>
                            🖌️ CHROMA NFTs <span style={{opacity: ".4"}}>({assets.length})</span>
                            {/* Refresh */}
                            <div style={{float: "right"}}>
                                <button disabled={disabledRefreshButton} onClick={refreshAssets} className={`${styles.refreshWallet} primary`}>
                                    <FontAwesomeIcon icon={faRedo} className={styles.refreshIcon} /> 
                                    Refresh
                                </button>
                            </div>
                        </h3>
                        <Container className={styles.container}>
                            <Row>
                                {_.get(assets, "length", 0) > 0 ? "" : <h3 className={styles.emptyHeader}>No CHROMA linked NFTs found in your wallet.</h3>}
                                {assets.map((asset, i) => {
                                    // Return POAP if used
                                    if(_.get(asset, "type") === "poap") {
                                        return(
                                            <Col key={i} xs={12} sm={6} md={4} style={{marginBottom: "12px"}}>
                                                <Card key={i} className={styles.Card}>
                                                    <div className={styles.poapThumbContainer}>
                                                        <img src={_.get(asset, "thumbnail")} />
                                                    </div>
                                                    <Card.Body className={styles.body} style={{position:"relative"}}>
                                                        <h1 className={styles.name}>{_.get(asset, "name")}</h1>
                                                        <p className={styles.linkedProduct}>
                                                            <FontAwesomeIcon icon={faLockOpen} className={styles.linkedIcon} /> Microdose Beta
                                                        </p>
                                                        {/*<Card.Text className={styles.description}>{_.get(asset, "description")}</Card.Text>*/}
                                                        <a href={_.get(asset, "permalink")} target="_blank">
                                                            <Button variant="primary" className={styles.button}>View NFT</Button>
                                                        </a>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    }

                                    // Return normal nft otherwise
                                    return(
                                        <Col key={i} xs={12} sm={6} md={4} style={{marginBottom: "12px"}}>
                                            <Card key={i} className={styles.Card}>
                                                <div className={styles.thumbContainer}>
                                                    <img src={_.get(asset, "thumbnail")} />
                                                </div>
                                                <Card.Body className={styles.body} style={{position:"relative"}}>
                                                    <h1 className={styles.name}>{_.get(asset, "name")}</h1>
                                                    <p className={styles.linkedProduct}>
                                                        <FontAwesomeIcon icon={faLockOpen} className={styles.linkedIcon} /> Microdose Beta
                                                    </p>
                                                    {/*<Card.Text className={styles.description}>{_.get(asset, "description")}</Card.Text>*/}
                                                    <a href={_.get(asset, "permalink")} target="_blank">
                                                        <Button variant="primary" className={styles.button}>View NFT</Button>
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Container>
                    </div>

                    {/* Associated Assets */}
                    <div style={{marginBottom: 24, display: "none"}}>
                        <h3>Associated assets</h3>
                        {associatedAssets.map((asset, i) => {
                            return(
                                <div key={i}>
                                    {_.get(asset, "assetId")} - {_.get(asset, "type")}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    const getAssetsUi = () => {
        if(walletLinked){
            let assetIds = []
            for (let i = 0; i < assets.length; i++) {
                const asset = assets[i];
                assetIds.push(asset.assetId)
                
            }
            // console.log(assets)
            const chromaQLAssets = {
                service: "assets",
                query: {
                    "_id": {
                        "$in": assetIds
                    }
                },
                fields: "_id type subtype meta children tokenId"
            }

            return(
                <div className={styles.chromaNfts}>
                    {/* NFT Assets */}
                    <div style={{marginBottom: 24}}>
                        <h3 style={{marginBottom: "48px"}}>
                            🖌️ CHROMA NFTs <span style={{opacity: ".4"}}>({assets.length})</span>
                            {/* Refresh */}
                            <div style={{float: "right"}}>
                                <button disabled={disabledRefreshButton} onClick={refreshAssets} className={`${styles.refreshWallet} primary`}>
                                    <FontAwesomeIcon icon={faRedo} className={styles.refreshIcon} /> 
                                    Refresh
                                </button>
                            </div>
                        </h3>
                        <Container className={styles.container}>
                            <Row>
                                {_.get(assets, "length", 0) > 0 ? "" : <h3 className={styles.emptyHeader}>No CHROMA linked NFTs found in your wallet.</h3>}
                                <QueryBuilder chromaQL={chromaQLAssets} />
                            </Row>
                        </Container>
                    </div>

                    {/* Associated Assets */}
                    <div style={{marginBottom: 24, display: "none"}}>
                        <h3>Associated assets</h3>
                        {associatedAssets.map((asset, i) => {
                            return(
                                <div key={i}>
                                    {_.get(asset, "assetId")} - {_.get(asset, "type")}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    
  
    return(
        <div className={styles.nftContainer}>
            {/* Metamask */}
            <div className={styles.linkContainer} style={{padding: "24px"}}>                
                {getLinkUI()}
            </div>

            {/* CHROMA NFT's */}
            {getAssetsUi()}
            
        </div>
    )
}

export default observer(CryptoDash)