/* Credit Card Form Basic */

/* Core */


/* Checkout */
import CardMinimal from "./CardMinimal"
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

/* Media */
import styles from './CreditCardForm.module.css'

function CreditCardForm(props) {
    require('dotenv').config()
    // for (const key in process.env) {

    //     console.log(`${key}`);
    // }
    const stripekey = "pk_live_JA6EKPTXNnHU6Gk7xuj8NQjU00jEv1kTxJ"    
    const stripePromise = loadStripe(stripekey);

    return (
        <Elements stripe={stripePromise}>
            <CardMinimal clientSecret={props.clientSecret} error={props.error} success={props.success} />
        </Elements>
    )
}

export default CreditCardForm