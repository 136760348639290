/* Creator Dashboard */

/* Core */
import { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate, useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Components */
import Layout from "./Layout"



/* Media */
import styles from './General.module.css'

const _ = require('lodash')

function CreatorLayout(props) {
    const rootStore = useStores()

    return (
        <div>
            <Layout noContainer={true}>
                <div className="container">
                    <div className={styles.Container}>
                        <div className="row">
                            {/* Left Column */}
                            {/*<div className="col-lg-4 col-md-12 col-sm-12">
                                <UsersInfo className={styles.usersInfo} />
                                <WalletStatus className={styles.walletStatus}  />
                            </div>*/}

                            {/* Right Column */}
                            <div className="col-lg-8">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default observer(CreatorLayout)