/* Header */

/* Core */

/* Router */
import { Link } from "react-router-dom"

/* Data */


/* Components */
import Navigation from "../nav/Navigation"
import UserMenu from "components/auth/UserMenu";

/* Media */
import styles from './Header.module.css'

function Header(props) {
    return (
        <header className="default">
            <Navigation />
        </header>
    )
}

export default Header