import find from "./_find"
import get from "./_get"

const chroma = {
    version: "1.0.0",

    /* Exposed Commands */
    'find': find,
    'get': get,
    'set': {},
    'query': {},
}

export default chroma