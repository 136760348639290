/* Router */
import {
    Route,
    Navigate,
  } from "react-router-dom"

/* Contexts */
import { useStores } from "../../context/RootStoreContext"
import { observer } from 'mobx-react'
import { props } from "data/structure/BaseModel"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    const rootStore = useStores()
    
    // console.log('children',children)

    if(rootStore.AuthStore.authenticated){
      // Return route
      return (
        children
        // <Route
        //   {...rest}
        //   render={children}
        // />
      )
    } else {
      return(
        <Navigate
          to={{
            pathname: "/login",
            state: { from: rest.path || '/' }
            // state: { from: location || '/' }
          }}
        />
      )
    }

    // return (
    //   <Route
    //     {...rest}
    //     render={({ location }) =>
    //       rootStore.AuthStore.authenticated ? (
    //         children
    //       ) : (
    //         <Redirect
    //           to={{
    //             pathname: "/login",
    //             state: { from: location || '/' }
    //           }}
    //         />
    //       )
    //     }
    //   />
    // )
}

export default observer(PrivateRoute)